<h2 mat-dialog-title>{{data.title}}</h2>
<mat-dialog-content>
  <div class="container">
    <div>
      <mat-form-field appearance="standard" class="wide-input">
        <mat-label>所属部課または工場</mat-label>
        <!-- 登録/修正 -->
        <input matInput [formControl]="departmentOrFactoryNameCheck" name="departmentOrFactoryName" [(ngModel)]="departmentOrFactoryName" *ngIf="inputMode" required>
        <mat-error *ngIf="departmentOrFactoryNameCheck.hasError('required')">必須項目です。</mat-error>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field appearance="standard" class="narrow-input" >
        <mat-label>郵便番号</mat-label>
        <input matInput type="tel" maxlength="8" matInput [formControl]="postalCodeCheck" name="postalCode" [(ngModel)]="postalCode" *ngIf="inputMode">
        <mat-error *ngIf="postalCodeCheck.hasError('pattern')">郵便番号を入力してください。</mat-error>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field appearance="standard" class="wide-input">
        <mat-label>住所</mat-label>
        <textarea matInput name="address" [(ngModel)]="address" *ngIf="inputMode" ></textarea>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field appearance="standard" class="narrow-input" >
        <mat-label>電話番号</mat-label>
        <input type="tel" matInput [formControl]="telNumberCheck" name="telNumber" [(ngModel)]="telNumber" *ngIf="inputMode">
        <mat-error *ngIf="telNumberCheck.hasError('pattern')">電話番号を入力してください。</mat-error>
      </mat-form-field>
      <span id="representative-position">代表</span><mat-checkbox [(ngModel)]="representativeCheck" (change)="setRepresentativeCheck($event)" [checked]="representativeCheck"></mat-checkbox>
    </div>
    <mat-dialog-actions>
      <button class="dialog-button" mat-raised-button color="primary" mat-dialog-close="cancel">キャンセル</button>
      <button class="dialog-button" mat-raised-button color="primary" (click)="onClick()">
        <span class="btn-label">{{buttonName}}</span>
      </button>
    </mat-dialog-actions>
  </div>
</mat-dialog-content>
