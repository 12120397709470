import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

/**
 * ヘッダータイトルサービス
 */
@Injectable({
  providedIn: 'root'
})
export class HeaderTitleService {

  /**
   * ヘッダータイトルサービスのコンストラクター
   * @constructor
   */
  constructor() { }

  /** ヘッダータイトル */
  headerTitle = new BehaviorSubject('');

  /**
   * ヘッダータイトルを設定する
   * @param title ヘッダータイトル
   */
  setTitle(title: string) {
    this.headerTitle.next(title);
  }
}
