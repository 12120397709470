<h2 mat-dialog-title>{{data.title}}</h2>
<mat-dialog-content>
  <div class="dialog-container">
    <div fxLayout="column">
      <div fxFlex>
        <!-- 添付ファイル一覧 -->
        <table mat-table [dataSource]="dataSource">

          <!-- ファイル選択チェックボックス -->
          <ng-container matColumnDef="file_select_check">
            <th mat-header-cell *matHeaderCellDef class="column-width-20"></th>
            <td mat-cell *matCellDef="let file" class="td-center">
              <mat-checkbox (click)="$event.stopPropagation()"
                (change)="selectedFile($event, file)">
              </mat-checkbox>
            </td>
          </ng-container>

          <!-- 添付ファイル連番 -->
          <ng-container matColumnDef="order_no_seq">
            <th mat-header-cell *matHeaderCellDef class="column-width-20" [style.display]="'none'">連番</th>
            <td mat-cell *matCellDef="let file" [style.display]="'none'">
              {{file.order_no_seq}}
            </td>
          </ng-container>

          <!-- 添付ファイル名 -->
          <ng-container matColumnDef="attachment_file_name">
            <th mat-header-cell *matHeaderCellDef class="column-width-200">添付ファイル名</th>
            <td mat-cell *matCellDef="let file">
              {{file.attachment_file_name}}
            </td>
          </ng-container>

          <!-- コメント -->
          <ng-container matColumnDef="comment">
            <th mat-header-cell *matHeaderCellDef class="column-width-200">コメント</th>
            <td mat-cell *matCellDef="let file">
              {{file.comment}}
            </td>
          </ng-container>

          <!-- 添付更新 -->
          <ng-container matColumnDef="attachment_update">
            <th mat-header-cell *matHeaderCellDef class="column-width-40">添付更新</th>
            <td mat-cell *matCellDef="let file" class="td-center">
              <input #fileInputUpdate type="file" [style.display]="'none'" accept="*" (change)="onChangeFileInput($event, Update)" />
              <button mat-icon-button (click)="onAttachmentUpdate(file)">
                  <mat-icon>upgrade</mat-icon>
              </button>
            </td>
          </ng-container>

          <!-- 添付削除 -->
          <ng-container matColumnDef="attachment_delete">
            <th mat-header-cell *matHeaderCellDef class="column-width-40">添付削除</th>
            <td mat-cell *matCellDef="let file" class="td-center">
              <button mat-icon-button (click)="onAttachmentDelete(file)">
                <mat-icon>delete_forever</mat-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let file; columns: displayedColumns;"
            (mouseover)="hovered = file"
            (mouseout)="hovered = null"
            [ngClass]="{
              'hovered': (hovered) ? hovered.order_no_seq == file.order_no_seq : false
            }">
          </tr>
        </table>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div fxLayout="row wrap" fxLayoutAlign="center" class="button-container">
    <!-- キャンセルボタン -->
    <button mat-raised-button color="primary" mat-dialog-close>キャンセル</button>
    <!-- 添付追加ボタン -->
    <input #fileInputRegist type="file" [style.display]="'none'" accept="*" (change)="onChangeFileInput($event, Regist)" />
    <button mat-raised-button color="primary" (click)="onAttachmentAdd()">
      <mat-icon>post_add</mat-icon>
      <span class="btn-label">添付追加</span>
    </button>
    <!-- 添付送信ボタン -->
    <button mat-raised-button color="primary" (click)="onAttachmentSend()" [disabled]="isDisabledSend">
      <mat-icon>mail_outline</mat-icon>
      <span class="btn-label">添付送信</span>
    </button>
  </div>
</mat-dialog-actions>
