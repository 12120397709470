<div class="container" fxLayout="column">
  <div id="order-container" fxFlex="grow">
    <!-- 絞り込み条件 -->
    <div id="conditions-container">
      <mat-expansion-panel #accordion>
        <mat-expansion-panel-header>
          <mat-panel-title>
            絞り込み条件
          </mat-panel-title>
        </mat-expansion-panel-header>

        <div fxLayout="row" fxLayout.xs="column">
          <div fxFlex="50">
            <!-- フリーワード -->
            <mat-form-field appearance="standard" class="form-free-word">
              <mat-label>フリーワード</mat-label>
              <input matInput name="free-word" [(ngModel)]="freeWord" (change)="onSearch()">
              <mat-hint>案件名、依頼内容、備考が対象</mat-hint>
            </mat-form-field>
          </div>
          <div fxFlex="50"></div>
        </div>

        <div fxLayout="row" class="target-year-container">
          <!-- 対象年 -->
          <mat-form-field class="target-year-width">
            <mat-label>対象年</mat-label>
            <input matInput
              class="select-date-label"
              [matDatepicker]="dp"
              [(ngModel)]="targetYear"
              disabled>
            <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
            <mat-datepicker #dp
              startView="multi-year"
              disabled="false"
              (yearSelected)="selectedYear($event, dp); onSearch();">
            </mat-datepicker>
          </mat-form-field>

          <!-- 採用 -->
          <span class="checkbox-label">採用</span>
          <mat-checkbox [(ngModel)]="isDone" (change)="onSearch()"></mat-checkbox>

          <!-- 不採用 -->
          <span class="checkbox-label">不採用</span>
          <mat-checkbox [(ngModel)]="isCancel" (change)="onSearch()"></mat-checkbox>
        </div>

        <div fxLayout="row" fxLayout.xs="column">
          <div fxFlex="50">
            <!-- 案件番号 -->
            <mat-form-field appearance="standard">
              <mat-label>案件番号</mat-label>
              <input matInput name="order-no" [(ngModel)]="orderNo" (change)="onSearch()">
            </mat-form-field>
          </div>
          <div fxFlex="50">
            <!-- 試作番号 -->
            <mat-form-field appearance="standard">
              <mat-label>試作番号</mat-label>
              <input matInput name="prototype-no" [(ngModel)]="prototypeNo" (change)="onSearch()">
            </mat-form-field>
          </div>
        </div>

        <div fxLayout="row" fxLayout.xs="column">
          <div fxFlex="50">
            <!-- 案件名 -->
            <mat-form-field appearance="standard" class="form-order-name">
              <mat-label>案件名</mat-label>
              <input matInput name="order-name" [(ngModel)]="orderName" (change)="onSearch()">
            </mat-form-field>
          </div>
          <div fxFlex="50"></div>
        </div>

        <div fxLayout="row" fxLayout.xs="column">
          <div fxFlex="50">
            <!-- 試作区分 -->
            <mat-form-field appearance="standard" class="form-prototype-kind">
              <mat-label>試作区分</mat-label>
              <mat-select [(value)]="prototypeKind" (selectionChange)="onSearch()">
                <mat-option *ngFor="let prototypeKind of prototypeKindList" [value]="prototypeKind.code">
                  {{prototypeKind.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div fxFlex="50">
            <!-- 試作ステータス -->
            <mat-form-field appearance="standard">
              <mat-label>試作ステータス</mat-label>
              <mat-select [(value)]="prototypeStatus" (selectionChange)="onSearch()">
                <mat-option *ngFor="let prototypeStatus of prototypeStatusList" [value]="prototypeStatus.code">
                  {{prototypeStatus.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div fxLayout="row" fxLayout.xs="column">
          <div fxFlex="34">
            <!-- 費用 -->
            <mat-form-field appearance="standard">
              <mat-label>費用</mat-label>
              <mat-select [(value)]="cost" (selectionChange)="onSearch()">
                <mat-option *ngFor="let cost of costList" [value]="cost.code">
                  {{cost.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div fxFlex="33">
            <!-- サイズ -->
            <mat-form-field appearance="standard">
              <mat-label>サイズ</mat-label>
              <input matInput name="size" [(ngModel)]="size" (change)="onSearch()">
            </mat-form-field>
          </div>
          <div fxFlex="33">
            <!-- 重量 -->
            <mat-form-field appearance="standard">
              <mat-label>重量</mat-label>
              <input matInput name="weight" [(ngModel)]="weight" (change)="onSearch()">
            </mat-form-field>
          </div>
        </div>

        <div fxLayout="row" fxLayout.xs="column">
          <div fxFlex="50">
            <!-- 依頼内容 -->
            <mat-form-field appearance="standard">
              <mat-label>依頼内容</mat-label>
              <input matInput name="content" [(ngModel)]="requestContent" (change)="onSearch()">
            </mat-form-field>
          </div>
          <div fxFlex="50">
            <!-- 備考 -->
            <mat-form-field appearance="standard">
              <mat-label>備考</mat-label>
              <input matInput name="notes" [(ngModel)]="notes" (change)="onSearch()">
            </mat-form-field>
          </div>
        </div>

        <div fxLayout="row" fxLayout.xs="column">
          <div fxFlex="50">
            <!-- 依頼元会社名 -->
            <mat-form-field appearance="standard">
              <mat-label>依頼元会社名</mat-label>
              <input matInput name="requester-company" [(ngModel)]="requesterCompany" (change)="onSearch()">
            </mat-form-field>
          </div>
          <div fxFlex="50">
            <!-- 依頼元所属部課または工場 -->
            <mat-form-field appearance="standard">
              <mat-label>依頼元所属部課または工場</mat-label>
              <input matInput name="requester-department" [(ngModel)]="requesterDepartment" (change)="onSearch()">
            </mat-form-field>
          </div>
        </div>

        <div fxLayout="row" fxLayout.xs="column">
          <div fxFlex="50">
            <!-- 依頼元担当者名 -->
            <mat-form-field appearance="standard">
              <mat-label>依頼元担当者名</mat-label>
              <input matInput name="requester-staff" [(ngModel)]="requesterStaff" (change)="onSearch()">
            </mat-form-field>
          </div>
          <div fxFlex="50">
            <!-- 担当営業 -->
            <mat-form-field appearance="standard">
              <mat-label>担当営業</mat-label>
              <mat-select [(value)]="salesStaff" (selectionChange)="onSearch()">
                <mat-option *ngFor="let employee of employeeList" [value]="employee.employee_no">
                  {{employee.employee_name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div fxLayout="row wrap" fxLayoutAlign="center" class="button-container">
          <!-- クリアボタン -->
          <button mat-raised-button color="primary" id="clear-button" (click)="onClear()">
            <span class="btn-label">クリア</span>
          </button>

          <!-- 検索ボタン -->
          <button mat-raised-button color="primary" id="search-button" (click)="onSearch();">
            <span class="btn-label">検索</span>
          </button>
        </div>
      </mat-expansion-panel>
    </div>

    <table mat-table [dataSource]="dataSource" class="order-list" matSort matSortDisableClear>
      <!-- 案件番号 -->
      <ng-container matColumnDef="order_no">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="column-width-1">案件番号</th>
        <td mat-cell *matCellDef="let order">
          {{order.order_no}}
        </td>
      </ng-container>

      <!-- 案件名 -->
      <ng-container matColumnDef="order_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="column-width-5">案件名</th>
        <td mat-cell *matCellDef="let order">
          {{order.order_name}}
        </td>
      </ng-container>

      <!-- 案件ステータス -->
      <ng-container matColumnDef="order_status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="column-width-3">案件ステータス</th>
        <td mat-cell *matCellDef="let order">
          {{order.order_status_name}}
        </td>
      </ng-container>

      <!-- 試作ステータス -->
      <ng-container matColumnDef="prototype_status_names">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="column-width-3">試作ステータス</th>
        <td mat-cell *matCellDef="let order">
          <span *ngFor="let prototype_status_name of order.prototype_status_names" class="span-height">
            {{prototype_status_name}}<br>
          </span>
        </td>
      </ng-container>

      <!-- 依頼日 -->
      <ng-container matColumnDef="request_dates">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="column-width-1">依頼日</th>
        <td mat-cell *matCellDef="let order">
          <span *ngFor="let request_date of order.request_dates" class="span-height">
            {{request_date | date: 'y/M/d'}}<br>
          </span>
        </td>
      </ng-container>

      <!-- 納期 -->
      <ng-container matColumnDef="deadlines">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="column-width-1">納期</th>
        <td mat-cell *matCellDef="let order">
          <span *ngFor="let deadline of order.deadlines" class="span-height">
            {{deadline | date: 'y/M/d'}}<br>
          </span>
        </td>
      </ng-container>

      <!-- 依頼元 -->
      <ng-container matColumnDef="requester_company">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="column-width-5">依頼元</th>
        <td mat-cell *matCellDef="let order">
          {{order.requester_company}}
        </td>
      </ng-container>

      <!-- 担当営業 -->
      <ng-container matColumnDef="sales_staff_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="column-width-2">担当営業</th>
        <td mat-cell *matCellDef="let order">
          {{order.sales_staff_name}}
        </td>
      </ng-container>

      <!-- 更新者 -->
      <ng-container matColumnDef="updated_employee_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="column-width-2">更新者</th>
        <td mat-cell *matCellDef="let order">
          {{order.updated_employee_name}}
        </td>
      </ng-container>

      <!-- 更新日 -->
      <ng-container matColumnDef="update_datetime">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="column-width-1">更新日</th>
        <td mat-cell *matCellDef="let order">
          {{order.update_datetime | date: 'y/M/d'}}
        </td>
      </ng-container>

      <!-- 添付ファイル名 -->
      <ng-container matColumnDef="attachment_file_names">
        <th mat-header-cell *matHeaderCellDef class="column-width-5">添付ファイル名</th>
        <td mat-cell *matCellDef="let order">
          <span *ngFor="let attachment_file_name of order.attachment_file_names" class="span-attachment-file">
            <a [routerLink]=""
              [download]="attachment_file_name"
              queryParamsHandling="preserve"
              (click)="attachmentFileDownload(order, attachment_file_name)">
              {{attachment_file_name}}
            </a>
            <a download id="downloadLink" #downloadLink [style.display]="'none'"></a>
            <br>
          </span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let order; columns: displayedColumns;"
        (click)="selectedRow(order)"
        (mouseover)="hovered = order"
        (mouseout)="hovered = null"
        [ngClass]="{
          'selected': (selected) ? selected.order_no == order.order_no : false,
          'hovered': (hovered) ? hovered.order_no == order.order_no : false
        }">
      </tr>
    </table>
  </div>

  <div id="footer-container">
    <mat-paginator #paginator
      [pageSize]="50"
      [pageSizeOptions]="[50, 100, 150]"
      showFirstLastButtons>
    </mat-paginator>

    <div fxLayout="row" fxLayoutAlign="center" class="button-container">
      <!-- 新規登録ボタン -->
      <button mat-raised-button color="primary" (click)="onEntry()">
        <mat-icon>add_box</mat-icon>
        <span class="btn-label">新規登録</span>
      </button>
      <!-- 修正・追加ボタン -->
      <button mat-raised-button color="primary" (click)="onModify()" [disabled]="isDisabledModify">
        <mat-icon>edit</mat-icon>
        <span class="btn-label">{{modifyButtonName}}</span>
      </button>
      <!-- コピーボタン -->
      <button mat-raised-button color="primary" (click)="onCopy()" [disabled]="isDisabledCopy">
        <mat-icon>content_copy</mat-icon>
        <span class="btn-label">コピー</span>
      </button>
    </div>
    <div fxLayout="row" fxLayoutAlign="center" class="button-container">
      <!-- 添付管理ボタン -->
      <button mat-raised-button color="primary" (click)="onAttached()" [disabled]="isDisabledAttachment">
        <mat-icon>attachment</mat-icon>
        <span class="btn-label">添付管理</span>
      </button>
    </div>
  </div>
</div>
