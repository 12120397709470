import { NgModule, ErrorHandler } from '@angular/core';

// コンポーネント
import { OrderListComponent } from './management/list/order-list.component';
import { OrderEntryModifyComponent } from './management/entry-modify/order-entry-modify.component';
import { OrderEntryModifyDialogComponent } from './management/entry-modify-dialog/order-entry-modify-dialog.component';
import { AttachmentManagementDialogComponent } from './management/attachment-management-dialog/attachment-management-dialog.component';
import { AttachmentSendDialogComponent } from './management/attachment-send-dialog/attachment-send-dialog.component';

// オプションモジュール
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { GlobalErrorService } from '../shared/service/globalError.service';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MaterialModule } from '../shared/class/material.module';
import { OrderRoutingModule } from './order-routing.module';

@NgModule({
  declarations: [
    OrderListComponent,
    OrderEntryModifyComponent,
    OrderEntryModifyDialogComponent,
    AttachmentManagementDialogComponent,
    AttachmentSendDialogComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    DragDropModule,
    MaterialModule,
    OrderRoutingModule
  ],
  exports: [
    OrderListComponent,
    OrderEntryModifyComponent,
    OrderEntryModifyDialogComponent,
    AttachmentManagementDialogComponent,
    AttachmentSendDialogComponent
  ],
  providers: [
    { provide: ErrorHandler, useClass: GlobalErrorService }
  ]
})
export class OrderModule { }
