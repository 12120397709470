/** 名前空間：案件ステータス */
export namespace OrderStatus {
  /** サンプル準備中 */
  export const Preparing = 0;
  /** サンプルワーク中 */
  export const Working = 1;
  /** ペンディング */
  export const Pending = 2;
  /** 不採用 */
  export const Cancel = 3;
  /** 採用 */
  export const Done = 4;

  /**
   * 名称を取得します。
   * @param value 値
   */
  export function getName(value) {
    let result = "";
    switch (value) {
      case Preparing:
        result = "サンプル準備中";
        break;
      case Working:
        result = "サンプルワーク中";
        break;
      case Pending:
        result = "ペンディング";
        break;
      case Cancel:
        result = "不採用";
        break;
      case Done:
        result = "採用";
        break;
      default: return '';
    }
    return result;
  }
}
