import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { HttpReqOptions } from '../interface/httpOption.interface';
import 'rxjs/add/operator/retry';
import 'rxjs/add/operator/toPromise';

/**
 * HTTP通信サービス
 * URL末尾にtimestamp文字を付与してブラウザキャッシュ防止
 * デフォルトでCredential有効設定
 */
@Injectable()
export class HttpService {

  /**
   * HTTP通信サービスのコンストラクター
   * @constructor
   * @param http Httpクライアント
   */
  constructor(private http: HttpClient) {
  }

  /**
   * HTTPリクエスト送信
   * @param method HTTPメソッド名
   * @param config オプション設定
   */
  async send(method: string, config: HttpReqOptions): Promise<any> {

    // ネットワークの利用可否を確認
    if (!window.navigator.onLine) {
      console.log('@@@ ネットワークに接続できません');
      return Promise.reject('OFFLINE');
    }

    /* 引数configの値を加工 */
    // url設定
    if (config.url.indexOf('http') === -1) {
      config.url = location.protocol + '//' + location.host + config.url;
    }

    /* 既定の設定オブジェクトを作成 */
    // キャッシュ防止のためのダミー文字列をurlに追加
    const noCacheStr = Date.now().toString();
    const param = new HttpParams().set('dummy', noCacheStr);
    // ヘッダー設定
    const header = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');

    const reqOptions = {
      body: config.body,
      headers: header,
      params: param,
      withCredentials: true
    };

    // 通信開始
    try {
      const res = await this.http.request<any>(method.toUpperCase(), config.url, reqOptions)
        .toPromise(); // ObservableをPromiseへ変換

      // 通信成功時の処理
      return await this.httpSucess(res);
    } catch (error) {
      // 通信失敗時の処理
      return this.httpError(error);
    }
  }

  /**
   * HTTPリクエスト送信
   * @param method HTTPメソッド名
   * @param config オプション設定
   */
  async requestPdfSend(method: string, config: any): Promise<any> {

    // ネットワークの利用可否を確認
    if (!window.navigator.onLine) {
      console.log('@@@ ネットワークに接続できません');
      return Promise.reject('OFFLINE');
    }

    /* 引数configの値を加工 */
    // url設定
    if (config.url.indexOf('http') === -1) {
      config.url = location.protocol + '//' + location.host + config.url;
    }

    /* 既定の設定オブジェクトを作成 */
    // キャッシュ防止のためのダミー文字列をurlに追加
    const noCacheStr = Date.now().toString();
    const param = new HttpParams().set('dummy', noCacheStr);
    // ヘッダー設定
    const header = new HttpHeaders().set('Content-Type', 'application/pdf');

    const reqOptions = {
      headers: header,
      params: param,
      withCredentials: true
    };

    // 通信開始
    try {
      const res = await this.http.request<any>(method.toUpperCase(), config.url, reqOptions)
        .toPromise(); // ObservableをPromiseへ変換

      // 通信成功時の処理
      return await this.httpSucess(res);
    } catch (error) {
      // 通信失敗時の処理
      return this.httpError(error);
    }
  }

  /**
   * 通信成功時の処理
   * @param res Responseオブジェクト
   */
  private httpSucess(res: Response): Promise<any> {
    const body: any = res || {message: '応答データが空白'};
    if (body) {
      return Promise.resolve(body);
    } else {
      throw new Error(body.message);
    }
  }

  /**
   * 通信失敗時の処理
   * @param error エラーオブジェクト
   */
  private httpError(error: any) {
    alert('通信エラー\n' + JSON.stringify(error));
    throw new Error(error);
  }
}
