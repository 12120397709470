import { Location } from '@angular/common';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { DialogComponent } from '../../../common/dialog/dialog.component';
import { NewAdoptService } from '../../../information/new-adopt/new-adopt.service';
import { MaintenanceCustomerService } from '../../../maintenance/customer/maintenance-customer.service';
import { MaintenanceDepartmentService } from '../../../maintenance/department/maintenance-department.service';
import { MaintenanceEmployeeService } from '../../../maintenance/employee/maintenance-employee.service';
import { AppConfig } from '../../../shared/class/appconfig.class';
import { OrderStatus } from '../../../shared/namespace/order-status';
import { ExclusiveControlService } from '../../../shared/service/exclusive.service';
import { HeaderTitleService } from '../../../shared/service/headerTitle.service';
import { MenuService } from '../../../shared/service/menu.service';
import { NavigationService } from '../../../shared/service/navigation.service';
import { PdfService } from '../../../shared/service/pdf.service';
import { OrderEntryModifyDialogComponent, PrototypeStatus } from '../entry-modify-dialog/order-entry-modify-dialog.component';
import { OrderManagementService } from '../order-management.service';

/** Enum：モード(0:登録、1:コピー、2:修正、3:参照) */
export enum Mode {
  Entry, Copy, Modify, Reference
}

/**
 * 案件登録・修正コンポーネント
 */
@Component({
  selector: 'app-order-entry-modify',
  templateUrl: './order-entry-modify.component.html',
  styleUrls: ['./order-entry-modify.component.css']
})
export class OrderEntryModifyComponent implements OnInit, OnDestroy {

  // #region フィールド

  /** モード(0:登録、1:コピー、2:修正、3:参照) */
  public mode: number;
  /** 社員番号 */
  public employeeNo = '';
  /** 案件番号 */
  public orderNo = '';
  /** 案件ステータス */
  public orderStatus = OrderStatus.Preparing;
  /** 入力日 */
  public inputDate = new Date();
  /** 案件名 */
  public orderName = '';
  /** 依頼元会社名 */
  public requesterCompany = '';
  /** 依頼元所属部課または工場 */
  public requesterDepartment = '';
  /** 依頼元担当者 */
  public requesterStaff = '';
  /** 担当営業 */
  public salesStaff = '';
  /** 補足 */
  public supplementary = '';

  /** 取得した試作依頼一覧 */
  public dataSource = new MatTableDataSource();
  /** 取得した試作依頼一覧 */
  public dataRequestList: any = [];
  /** 削除対象の試作依頼一覧 */
  public deleteRequestList: any = [];
  /** 選択した試作依頼情報 */
  public selected: any;
  /** 取得した案件ステータス一覧 */
  public orderStatusList: any = [];
  /** 取得した営業担当者一覧 */
  public employeeList: any = [];
  /** 取得した部署一覧 */
  public departmentList: any = [];
  /** 取得した得意先親マスタ一覧 */
  public customerList: any = [];
  /** 取得した得意先子マスタ一覧 */
  public customerChildList: any = [];
  /** 取得した得意先子マスタ一覧 */
  public customerChildListTemp: any = [];
  /** フィルタリングされる得意先親マスタ一覧 */
  public filteredCustomerList: Observable<any>;
  /** フィルタリングされる得意先子マスタ一覧 */
  public filteredCustomerChildList: Observable<any>;
  /** 通知メールの送信対象となる試作番号 */
  private sendPrototypeNoList = [];

  /** 追加ボタンの状態 */
  public isDisabledAdd = true;
  /** 修正ボタンの状態 */
  public isDisabledModify = true;
  /** コピーボタンの状態 */
  public isDisabledCopy = true;
  /** 削除ボタンの状態 */
  public isDisabledDelete = true;
  /** 印刷ボタンの状態 */
  public isDisabledPrint = true;
  /** 修正ボタンのボタン名称 */
  public modifyButtonName = '修正';

  /** 参照モードかどうか */
  public isReference = false;
  /** 補足の状態 */
  public isDisabledSupplementary = true;

  /** 機能名 */
  public static FUNCTION_NAME_MODIFY = '案件修正';
  /** 試作ステータス(4:完了) */
  public static PROTOTYPE_STATUS_DONE = 4;

  /** 画面表示項目 */
  public displayedColumns: string[] = [
    'prototype_no',         // 試作番号
    'prototype_kind',       // 試作区分
    'prototype_status',     // 試作ステータス
    'deadline',             // 納期
    'shipping_quantity',    // 発送数量
    'size',                 // サイズ
    'weight',               // 重量
    'production_lot',       // LOT(賞味期限)
    'shipping_date',        // 発送日
    'invoice',              // 送り状
    'destination',          // 宛て名
    'request_content',      // 依頼内容
    'notes'                 // 備考
  ];

  /** フォームグループ：基本情報 */
  public orderFormGroup : FormGroup;
  /** チェック：試作ステータス */
  public orderStatusCheck = new FormControl('', [Validators.required]);
  /** チェック：入力日 */
  public inputDateCheck = new FormControl('', [Validators.required]);
  /** チェック：案件名 */
  public orderNameCheck = new FormControl('', [Validators.required]);
  /** チェック：依頼元会社 */
  public requesterCompanyCheck = new FormControl('', [Validators.required]);
  /** チェック：依頼元所属部課または工場 */
  public requesterDepartmentCheck = new FormControl();
  /** チェック：営業担当 */
  public salesStaffCheck = new FormControl('', [Validators.required]);

  // #endregion

  // #region コンストラクター

  /**
   * 案件登録・修正コンポーネントのコンストラクター
   * @constructor
   */
  constructor(private title: Title,
              private headerTitle: HeaderTitleService,
              private transitionService: NavigationService,
              private router: Router,
              private route: ActivatedRoute,
              private location: Location,
              private matDialog: MatDialog,
              private matSnackBar: MatSnackBar,
              private changeDetectorRef: ChangeDetectorRef,
              private service: OrderManagementService,
              private adoptService: NewAdoptService,
              private empService: MaintenanceEmployeeService,
              private depService: MaintenanceDepartmentService,
              private custService: MaintenanceCustomerService,
              private menuService: MenuService,
              private excService: ExclusiveControlService,
              private pdfService: PdfService) {
    // ルーティングで設定したデータを取得する
    this.route.data.subscribe(data => this.mode = data.mode);

    switch (this.mode) {
      case Mode.Entry:
      case Mode.Copy:
        // ページタイトルの設定
        this.title.setTitle('案件登録 | ' + AppConfig.SYSTEM_NANE);
        // ヘッダータイトルの設定
        this.headerTitle.setTitle('案件登録');
        break;
      case Mode.Modify:
        // ページタイトルの設定
        this.title.setTitle('案件修正 | ' + AppConfig.SYSTEM_NANE);
        // ヘッダータイトルの設定
        this.headerTitle.setTitle('案件修正');
        break;
      case Mode.Reference:
        // ページタイトルの設定
        this.title.setTitle('案件参照 | ' + AppConfig.SYSTEM_NANE);
        // ヘッダータイトルの設定
        this.headerTitle.setTitle('案件参照');
        break;
    }

    // フォームグループの作成
    this.createForm();
  }

  // #endregion

  // #region 初期表示時の処理

  /**
   * 初期表示時の処理
   */
  async ngOnInit(): Promise<void> {
    // 案件ステータスの一覧取得
    await this.getOrderStatusList();
    // 営業担当者の一覧取得
    await this.getSalesEmployeeList();
    // 部署の一覧取得
    await this.getDepartmentList();
    // 得意先の一覧取得
    await this.getCustomerList();
    // 社員番号の取得
    this.employeeNo = this.menuService.getEmployeeNo();

    if (this.mode === Mode.Copy || this.mode === Mode.Modify || this.mode === Mode.Reference) {
      this.service.orderNo.subscribe(orderNo => {
        // サービスから取得した案件番号を設定
        this.orderNo = orderNo;
      });

      // 案件詳細の取得
      const rec: any = await this.service.getOrderDetail(this.orderNo);

      // 取得した結果を各項目にセットする
      this.orderStatus = rec.order_status;
      this.orderName = rec.order_name;
      this.requesterCompany = rec.requester_company_name;
      this.requesterDepartment = rec.requester_department_or_factory_name;
      this.requesterStaff = rec.requester_staff;
      this.salesStaff = rec.sales_staff;
      this.supplementary = rec.supplementary;

      if (this.mode === Mode.Copy) {
        this.inputDate = new Date();
        this.supplementary = '';
      } else {
        this.inputDate = rec.input_date;

        // 取得した結果をテーブルにセットする
        this.dataSource = rec.request;
        this.dataRequestList = rec.request;
      }

      // メール通知先のチェックを設定する
      if (rec.department_no) {
        const arrayDepartmentNo = rec.department_no.split(',');
        this.departmentList.forEach(department => {
          arrayDepartmentNo.forEach(value => {
            if (department.department_no == value) {
              department.selected = true;
            }
          });
        });
      }

      // ExpressionChangedAfterItHasBeenCheckedError対策
      this.changeDetectorRef.detectChanges();
    }

    if (this.mode === Mode.Modify) {
      // 排他チェック
      const check: any = await this.excService.checkExclusive(OrderEntryModifyComponent.FUNCTION_NAME_MODIFY, this.orderNo, this.employeeNo);
      if (!check) {
        // 他ユーザーが排他ロックをしているため、参照モードで表示する
        this.mode = Mode.Reference;
      }
    }

    // フォームグループを作成
    this.createForm();

    // Operatorsを設定
    this.setOperators();

    // ボタンの活性/非活性を設定
    this.setConditionForButton();
  }

  /**
   * フォームグループを作成する。
   */
  private createForm() {
    if (this.mode === Mode.Reference) {
      this.isReference = true;
      this.orderStatusCheck = new FormControl({ value: this.orderStatus, disabled: this.isReference });
      this.inputDateCheck = new FormControl({ value: this.inputDate, disabled: this.isReference });
      this.orderNameCheck = new FormControl({ value: this.orderName, disabled: this.isReference });
      this.requesterCompanyCheck = new FormControl({ value: this.requesterCompany, disabled: this.isReference });
      this.requesterDepartmentCheck = new FormControl({ value: this.requesterDepartment, disabled: this.isReference });
      this.salesStaffCheck = new FormControl({ value: this.salesStaff, disabled: this.isReference });
    } else {
      this.isReference = false;
      this.orderStatusCheck = new FormControl(this.orderStatus, [Validators.required]);
      this.inputDateCheck = new FormControl(this.inputDate, [Validators.required]);
      this.orderNameCheck = new FormControl(this.orderName, [Validators.required]);
      this.requesterCompanyCheck = new FormControl(this.requesterCompany, [Validators.required]);
      this.requesterDepartmentCheck = new FormControl(this.requesterDepartment);
      this.salesStaffCheck = new FormControl(this.salesStaff, [Validators.required]);
    }

    this.orderFormGroup = new FormGroup({
      orderStatus: this.orderStatusCheck,
      inputDate: this.inputDateCheck,
      orderName: this.orderNameCheck,
      requesterCompany: this.requesterCompanyCheck,
      salesStaff: this.salesStaffCheck
    });
  }

  /**
   * RxJSのOperatorsを設定する。
   */
  private setOperators() {
    // 依頼元会社
    this.filteredCustomerList = this.requesterCompanyCheck.valueChanges.pipe(
      startWith(this.requesterCompany),
      map(value => {
        const rec = this.customerList.filter(v => v.company_name === value);
        const customerNo = (rec && rec.length) ? rec[0].customer_no : '';
        this.customerChildListTemp = this.customerChildList.filter(v => v.customer_no === customerNo);
        this.requesterDepartmentCheck.setValue(this.requesterDepartment);
        return (value) ? this.customerList.filter(v => v.company_name.includes(value)) : this.customerList;
      })
    );
    // 依頼元所属部課または工場
    this.filteredCustomerChildList = this.requesterDepartmentCheck.valueChanges.pipe(
      startWith(this.requesterDepartment),
      map(value => (value) ? this.customerChildListTemp.filter(v => v.department_or_factory_name.includes(value)) : this.customerChildListTemp)
    );
  }

  /**
   * ボタンの活性/非活性を設定する。
   */
  private setConditionForButton() {
    // 参照モードの場合
    if (this.isReference) {
      // 追加・修正・コピー・削除ボタンは押下不可
      this.isDisabledAdd = true;
      this.isDisabledModify = true;
      this.isDisabledCopy = true;
      this.isDisabledDelete = true;
      this.modifyButtonName= '参照';

      // 明細が選択されている場合
      if (this.selected != null) {
        // 修正ボタンは押下可
        this.isDisabledModify = false;
      }
      return;
    }

    // 追加ボタンは押下可
    this.isDisabledAdd = false;

    // 明細が選択されている場合
    if (this.selected != null) {
      // 修正・コピー・削除・印刷ボタンは押下可
      this.isDisabledModify = false;
      this.isDisabledCopy = false;
      this.isDisabledDelete = false;
      this.isDisabledPrint = false;
    } else {
      // 修正・コピー・削除・印刷ボタンは押下不可
      this.isDisabledModify = true;
      this.isDisabledCopy = true;
      this.isDisabledDelete = true;
      this.isDisabledPrint = true;
    }
  }

  // #endregion

  // #region 終了時の処理

  /**
   * 終了時の処理
   */
  async ngOnDestroy() {
    if (this.mode === Mode.Modify) {
      // 排他ロックを解除
      console.log('@@@@ 排他ロック削除');
      const del: any = await this.excService.deleteRecord(OrderEntryModifyComponent.FUNCTION_NAME_MODIFY, this.orderNo);
      if (!del.success) alert(del.message);
    }
  }

  // #endregion

  // #region データ取得時の処理

  /**
   * 試作ステータスの一覧を取得する。
   */
  private async getOrderStatusList() {
    // 案件ステータスの一覧取得
    const rec: any = await this.service.getCodeNameList('order_status');
    // 取得した結果をセットする
    this.orderStatusList = rec;
  }

  /**
   * 営業担当者の一覧を取得する。
   */
  private async getSalesEmployeeList() {
    // 営業担当者の一覧取得
    const rec: any = await this.empService.getSalesEmployeeList();
    // 先頭に空行を追加する
    rec.unshift({ employee_no: '', employee_name: '' });
    // 取得した結果をセットする
    this.employeeList = rec;
  }

  /**
   * 部署の一覧を取得する。
   */
  private async getDepartmentList() {
    // 部署一覧を取得する
    const rec: any = await this.depService.getDepartmentList(0);
    // 削除フラグが false のみを抽出する
    const result: any = rec.filter(v => v.delete_flag === false);
    // 取得した結果をセットする
    this.departmentList = result;
  }

  /**
   * 得意先の一覧を取得する。
   */
  private async getCustomerList() {
    // 得意先親マスタの一覧取得
    const rec1: any = await this.custService.getCustomerParentList();
    // 得意先子マスタの一覧取得
    const rec2: any = await this.custService.getCustomerChildList();
    // 取得した結果をセットする
    this.customerList = rec1;
    this.customerChildList = rec2;
  }

  // #endregion

  // #region 項目選択時の処理

  /**
   * 案件ステータス選択時の処理
   * @param event 選択時のイベント
   */
  public setOrderStatus(event) {
    // 選択値をセット
    this.orderStatus = event.value;

    // 案件ステータスが「採用」の場合、補足は入力可
    this.isDisabledSupplementary = (this.orderStatus == OrderStatus.Done) ? false : true;
  }

  /**
   * 営業担当選択時の処理
   * @param event 選択時のイベント
   */
  public setSalesStaff(event) {
    // 選択値をセット
    this.salesStaff = event.value;
  }

  /**
   * 入力日選択時の処理
   * @param event 選択時のイベント
   */
  public setInputDate(event) {
    // 選択された値が正当かどうかチェック
    if (!moment(event.value).isValid()) {
      // 正当な値でない場合は後続処理を行わない
      return;
    }
    // 選択値をセット
    this.inputDate = event.value;
  }

  /**
   * 行選択時の処理
   * @param order 行選択した明細
   */
  public selectedRow(order) {
    // 選択した明細をセットする
    this.selected = order;

    // ボタンの活性/非活性を設定
    this.setConditionForButton();
  }

  // #endregion

  // #region ボタン押下時の処理

  /**
   * 戻るボタン押下時の処理
   */
  onCancel() {
    // 画面遷移の許可
    this.transitionService.canTransition = true;
    // 案件リスト画面に戻る
    this.location.back();
  }

  /**
   * 戻るボタン押下時の処理
   */
  async onBack() {
    console.log('@@@@ 戻るボタン押下');

    if (this.mode === Mode.Modify) {
      // 排他ロックを更新
      const modify: any = await this.excService.updateRecord(OrderEntryModifyComponent.FUNCTION_NAME_MODIFY, this.orderNo, this.employeeNo);
    }
  }

  /**
   * 次へボタン押下時の処理
   */
  async onNext() {
    console.log('@@@@ 次へボタン押下');

    if (this.mode === Mode.Modify) {
      // 排他ロックを更新
      const modify: any = await this.excService.updateRecord(OrderEntryModifyComponent.FUNCTION_NAME_MODIFY, this.orderNo, this.employeeNo);
    }
  }

  /**
   * 追加ボタン押下時の処理
   */
  onAdd() {
    const request = {
      requester_company_name: this.requesterCompany,
      requester_department_or_factory_name: this.requesterDepartment,
      requester_staff: this.requesterStaff
    };

    const dialogRef =  this.matDialog.open(OrderEntryModifyDialogComponent, {
      width: '80%',
      data: {
        title: '試作依頼',
        mode: Mode.Entry,
        request: request
      },
      // 画面外のクリックを禁止する設定を追加
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // 試作依頼の新規行を追加
        this.dataRequestList.push(result);
        this.dataSource = new MatTableDataSource(this.dataRequestList);

        // 選択した明細をリセットする
        this.selected = null;

        // 案件ステータスを変更
        this.changeOrderStatus();

        // ボタンの活性/非活性を設定
        this.setConditionForButton();
      } else {
        // キャンセル押下時は何もしない
        console.log('ダイアログでキャンセルされました。');
        return;
      }
    });
  }

  /**
   * 修正ボタン押下時の処理
   */
  onModify() {
    const request = this.selected;
    request.requester_company_name = this.requesterCompany;
    request.requester_department_or_factory_name = this.requesterDepartment;
    request.requester_staff = this.requesterStaff;

    const dialogRef =  this.matDialog.open(OrderEntryModifyDialogComponent, {
      width: '80%',
      data: {
        title: '試作依頼',
        mode: this.isReference ? Mode.Reference : Mode.Modify,
        request: request
      },
      // 画面外のクリックを禁止する設定を追加
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // 各項目にセットする
        this.selected.prototype_kind = result.prototype_kind;
        this.selected.prototype_kind_name = result.prototype_kind_name;
        this.selected.prototype_status = result.prototype_status;
        this.selected.prototype_status_name = result.prototype_status_name;
        this.selected.cost = result.cost;
        this.selected.size = result.size;
        this.selected.weight = result.weight;
        this.selected.deadline = result.deadline;
        this.selected.deadline_adjustment = result.deadline_adjustment;
        this.selected.delivery_method = result.delivery_method;
        this.selected.shipping_quantity = result.shipping_quantity;
        this.selected.shipping_adjustment = result.shipping_adjustment;
        this.selected.request_date = result.request_date;
        this.selected.production_lot = result.production_lot;
        this.selected.request_content = result.request_content;
        this.selected.notes = result.notes;
        this.selected.shippings = result.shippings;
        this.selected.is_changed = result.is_changed;

        // データ修正を行った試作番号を追加する
        this.addSendPrototypeNo(this.selected.prototype_no);

        // 選択した明細をリセットする
        this.selected = null;

        // 案件ステータスを変更
        this.changeOrderStatus();

        // ボタンの活性/非活性を設定
        this.setConditionForButton();
      } else {
        // キャンセル押下時は何もしない
        console.log('ダイアログでキャンセルされました。');
        return;
      }
    });
  }

  /**
   * コピーボタン押下時の処理
   */
  onCopy() {
    const request = this.selected;
    request.requester_company_name = this.requesterCompany;
    request.requester_department_or_factory_name = this.requesterDepartment;
    request.requester_staff = this.requesterStaff;

    const dialogRef =  this.matDialog.open(OrderEntryModifyDialogComponent, {
      width: '80%',
      data: {
        title: '試作依頼',
        mode: Mode.Copy,
        request: request
      },
      // 画面外のクリックを禁止する設定を追加
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // 試作依頼の新規行を追加
        this.dataRequestList.push(result);
        this.dataSource = new MatTableDataSource(this.dataRequestList);

        // 選択した明細をリセットする
        this.selected = null;

        // 案件ステータスを変更
        this.changeOrderStatus();

        // ボタンの活性/非活性を設定
        this.setConditionForButton();
      } else {
        // キャンセル押下時は何もしない
        console.log('ダイアログでキャンセルされました。');
        return;
      }
    });
  }

  /**
   * 削除ボタン押下時の処理
   */
  onDelete() {
    console.log('@@@@ 削除ボタン押下');

    if (this.selected == null) {
      alert('試作依頼が選択されていないため、削除できません。');
      return;
    }

    // 処理開始前に確認ダイアログを表示
    const dialogRef =  this.matDialog.open(DialogComponent, {
      width: '300px',
      height: '300px',
      data: {
        title: '削除確認',
        message: '現在選択されている試作依頼を削除してよろしいですか？',
      },
      // 画面外のクリックを禁止する設定を追加
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(async(result) => {
      if (!result) {
        // 選択した試作依頼を明細から削除する
        if (this.selected.prototype_no) this.deleteRequestList.push(this.selected);
        this.dataRequestList = this.dataRequestList.filter(v => v != this.selected);
        this.dataSource = new MatTableDataSource(this.dataRequestList);

        // 選択した明細をリセットする
        this.selected = null;

        // 案件ステータスを変更
        this.changeOrderStatus();

        // ボタンの活性/非活性を設定
        this.setConditionForButton();
      } else {
        // キャンセル押下時は何もしない
        return;
      }
    });
  }

  /**
   * 完了ボタン押下時の処理
   */
  async onRegist(departmentNos: any) {
    console.log('@@@@ 完了ボタン押下');

    // メール通知先を配列に変換
    // [ "0001", "0002", "0003", ... ]
    const mappingDepartmentNo = departmentNos.map(x => x.value);

    // メール通知先が未選択の場合、確認ダイアログを表示
    if (this.dataRequestList.length > 0 && mappingDepartmentNo.length == 0) {
      if (!confirm('メール通知先が選択されていません。' + '\n' + '試作依頼の通知メールは送信されませんがよろしいですか？')) return;
    }

    // 依頼元
    // 得意先親マスタの存在チェック
    let customerNo = await this.custService.getCustomerNo(this.requesterCompany);
    let childNo: number = null;
    if (this.requesterDepartment) {
      // 得意先子マスタの存在チェック
      const childList = await this.custService.getCustomerChild(customerNo);
      const rec = childList.filter(v => v.department_or_factory_name === this.requesterDepartment);
      childNo = (rec && rec.length) ? rec[0].child_no : null;
    }
    if ((!customerNo) || (this.requesterDepartment && !childNo)) {
      // 確認ダイアログを表示
      if (confirm('依頼元の得意先情報が未登録です。' + '\n' + '登録しますか。')) {
        // OKボタン押下時
        // 得意先親マスタの新規登録
        if (!customerNo) {
          customerNo = await this.registCustomerParent(this.requesterCompany);
          if (!customerNo) return;
        }
        // 得意先子マスタの新規登録
        if (this.requesterDepartment && !childNo) {
          childNo = await this.registCustomerChild(customerNo, this.requesterDepartment);
          if (!childNo) return;
        }
      }
    }

    // 宛て名のチェック
    let checkDepartment = false;
    if (this.dataRequestList.length > 0) {
      for (const request of this.dataRequestList) {
        if (request.shippings.length > 0) {
          for (const shipping of request.shippings) {
            // 得意先親マスタの存在チェック
            if (shipping.destination_company_name) {
              shipping.destination_company_no = await this.custService.getCustomerNo(shipping.destination_company_name);
              if (!shipping.destination_company_no) {
                checkDepartment = true;
                continue;
              }
            }
            // 得意先子マスタの存在チェック
            if (shipping.destination_department_or_factory_name) {
              const childList = await this.custService.getCustomerChild(shipping.destination_company_no);
              const rec = childList.filter(v => v.department_or_factory_name === shipping.destination_department_or_factory_name);
              shipping.destination_department_or_factory_no = (rec && rec.length) ? rec[0].child_no : null;
              if (!shipping.destination_department_or_factory_no) {
                checkDepartment = true;
              }
            }
          }
        }
      }
    }
    if (checkDepartment) {
      // 確認ダイアログを表示
      if (confirm('宛て名の得意先情報が未登録です。' + '\n' + '登録しますか。')) {
        // OKボタン押下時
        for (const request of this.dataRequestList) {
          if (request.shippings.length > 0) {
            for (const shipping of request.shippings) {
              // 得意先親マスタの新規登録
              if (!shipping.destination_company_no && shipping.destination_company_name) {
                const customerNo = await this.registCustomerParent(shipping.destination_company_name);
                shipping.destination_company_no = customerNo;
                if (!customerNo) return;
              }
              // 得意先子マスタの新規登録
              if (!shipping.destination_department_or_factory_no && shipping.destination_department_or_factory_name) {
                const childNo = await this.registCustomerChild(shipping.destination_company_no, shipping.destination_department_or_factory_name);
                shipping.destination_department_or_factory_no = childNo;
                if (!childNo) return;
              }
            }
          }
        }
      }
    }

    // モード毎に登録・修正の処理を実行する
    if (this.mode === Mode.Entry || this.mode === Mode.Copy) {
      // サーバに渡すJSON
      const bodyData = {
        order_name: this.orderName,
        order_status: this.orderStatus,
        input_date: new Date(this.inputDate).toLocaleDateString(),
        requester_company_no: customerNo,
        requester_company_name: this.requesterCompany,
        requester_department_or_factory_no: childNo,
        requester_department_or_factory_name: this.requesterDepartment,
        requester_staff: this.requesterStaff,
        sales_staff: this.salesStaff,
        supplementary: this.supplementary,
        requests: this.dataRequestList,
        mapping_department_no: mappingDepartmentNo
      };

      // 登録処理
      const entry: any = await this.service.insertOrderDetail(bodyData);
      // 登録処理に失敗した場合、サーバから返ってきたメッセージを表示する
      if (!entry.success) {
        alert(entry.message);
        return;
      }

      // 新規登録された案件番号と試作番号を取得
      this.orderNo = entry.data.order_no;
      const prototypeNoArray = entry.data.prototype_no;
      if (prototypeNoArray && prototypeNoArray.length) prototypeNoArray.forEach(v => this.addSendPrototypeNo(v));

      // 登録後処理
      this.matSnackBar.open('登録しました。', '', {
        duration: 1000
      });
      console.log('@@@@ 登録処理が完了しました。');
    } else if (this.mode === Mode.Modify) {
      // 試作依頼をInsert、Update、Delete別に選別する
      const insertRequests = this.dataRequestList.filter(data => !data.prototype_no);
      const updateRequests = this.dataRequestList.filter(data => data.prototype_no);
      const deleteRequests = this.deleteRequestList;

      // サーバに渡すJSON
      const bodyData = {
        order_no: this.orderNo,
        order_name: this.orderName,
        order_status: this.orderStatus,
        input_date: new Date(this.inputDate).toLocaleDateString(),
        requester_company_no: customerNo,
        requester_company_name: this.requesterCompany,
        requester_department_or_factory_no: childNo,
        requester_department_or_factory_name: this.requesterDepartment,
        requester_staff: this.requesterStaff,
        sales_staff: this.salesStaff,
        supplementary: this.supplementary,
        requests: this.dataRequestList,
        mapping_department_no: mappingDepartmentNo,
        insertRequests: insertRequests,
        updateRequests: updateRequests,
        deleteRequests: deleteRequests
      };

      // 更新処理
      const modify: any = await this.service.updateOrderDetail(bodyData);
      // 更新処理に失敗した場合、サーバから返ってきたメッセージを表示する
      if (!modify.success) {
        alert(modify.message);
        return;
      }

      // 新規登録された試作番号を取得
      const prototypeNoArray = modify.data.prototype_no;
      if (prototypeNoArray && prototypeNoArray.length) prototypeNoArray.forEach(v => this.addSendPrototypeNo(v));

      // 更新後処理
      this.matSnackBar.open('修正しました。', '', {
        duration: 1000
      });
      console.log('@@@@ 修正処理が完了しました。');
    }

    // 案件詳細の取得
    const rec: any = await this.service.getOrderDetail(this.orderNo);
    // 部署一覧の取得
    const departmentList: any = await this.depService.getDepartmentList(0);

    // 案件ステータスが「採用」以外、かつメール通知先が指定されている場合
    if (this.orderStatus != OrderStatus.Done && mappingDepartmentNo.length) {
      // 新規登録、またはデータ修正を行った試作番号が存在する場合
      if (this.sendPrototypeNoList.length) {
        // メールアドレスを取得
        const mailAddressArray: any = [];
        mappingDepartmentNo.forEach(value => {
          const rec = departmentList.filter(v => v.department_no === value);
          if (rec && rec.length) mailAddressArray.push(rec[0].mail_address);
        });
        const sendMailAddress = mailAddressArray.join(',');

        // 試作番号(新規追加・修正)
        const prototypeNoArray: any = [];
        // 試作番号(完了)
        const prototypeNoArrayForDone: any = [];
        // 添付ファイル(新規追加・修正)
        const attachmentArray: any = [];
        // 添付ファイル(完了)
        const attachmentArrayForDone: any = [];

        for (const prototypeNo of this.sendPrototypeNoList) {
          if (!prototypeNo) continue;

          // 試作ステータスが「完了」の場合、完了メールの送信対象とする
          const requests = rec.request.filter(v => v.prototype_no === prototypeNo);
          if (!requests.length) continue;

          // PDFの取得(Base64形式のPDFデータ)
          // data:application/pdf;base64,...
          const pdfData = await this.service.getPrototypeRequestPdf(prototypeNo);

          const attachment = {
            filename: prototypeNo + '_試作依頼書_' + this.orderName + '.pdf',
            path: pdfData,
            contentType: 'application/pdf'
          };

          if (requests[0].prototype_status === PrototypeStatus.Done) {
            prototypeNoArrayForDone.push(prototypeNo);
            attachmentArrayForDone.push(attachment);
          } else {
            prototypeNoArray.push(prototypeNo);
            attachmentArray.push(attachment);
          }
        }

        if (prototypeNoArrayForDone && prototypeNoArrayForDone.length) {
          console.log('@@@@ 試作依頼の完了メールを送信');

          // サーバに渡すJSON
          const bodyData = {
            send_mail_address: sendMailAddress,
            prototype_no: prototypeNoArrayForDone.join(', '),
            order_no: this.orderNo,
            order_name: this.orderName,
            attachments: attachmentArrayForDone
          };

          // メール送信
          const mail = await this.service.sendCompletionMailForRequest(bodyData);
          // メール送信に失敗した場合、サーバから返ってきたメッセージを表示する
          if (!mail.success) {
            alert(mail.message);
            return;
          }
        }
        if (prototypeNoArray && prototypeNoArray.length) {
          console.log('@@@@ 試作依頼の通知メールを送信');

          // サーバに渡すJSON
          const bodyData = {
            send_mail_address: sendMailAddress,
            prototype_no: prototypeNoArray.join(', '),
            order_no: this.orderNo,
            order_name: this.orderName,
            attachments: attachmentArray
          };

          // メール送信
          const mail = await this.service.sendMailForRequest(bodyData);
          // メール送信に失敗した場合、サーバから返ってきたメッセージを表示する
          if (!mail.success) {
            alert(mail.message);
            return;
          }
        }
      }
    }

    // 画面遷移の許可
    this.transitionService.canTransition = true;

    // 案件ステータスが「採用」の場合
    if (this.orderStatus == OrderStatus.Done) {
      console.log('@@@@ 案件採用のメールを送信');

      // 全部署宛のメールアドレスを取得
      const department = departmentList.find(v => v.department_no === '0001');
      const sendMailAddress = (department) ? department.mail_address : '';

      // サーバに渡すJSON
      const bodyData = {
        send_mail_address: sendMailAddress,
        order_no: this.orderNo,
        order_name: this.orderName,
        supplementary: this.supplementary
      };

      // メール送信
      const mail = await this.service.sendMailForDone(bodyData);
      // メール送信に失敗した場合、サーバから返ってきたメッセージを表示する
      if (!mail.success) {
        alert(mail.message);
        return;
      }

      // 確認ダイアログを表示
      if (confirm('案件ステータスが「採用」となりました。' + '\n' + '新規採用情報を登録しますか。')) {
        // 新規採用情報サービスに案件番号を保存する
        this.adoptService.sendBasicInformation(this.orderNo);
        // 新規採用情報登録画面に遷移する
        this.router.navigate(['/information/new-adopt/entry']);
      } else {
        // 案件リスト画面に遷移する
        this.location.back();
      }
    } else {
      // 案件リスト画面に遷移する
      this.location.back();
    }
  }

  /**
   * 得意先親マスタの登録処理
   * @param companyName 会社名
   */
  private async registCustomerParent(companyName: string) {
    console.log('得意先親マスタの新規登録');

    // 得意先親マスタの新規番号取得
    const rec: any = await this.custService.getNewCustomerNo();
    const customerNo = rec.customer_no;

    const custData = {
      customer_no: customerNo,
      company_name: companyName,
      postal_code: '',
      address: '',
      customer_child: []
    };

    // 登録処理
    const entry = await this.custService.insertCustomerDetail(custData);
    // 登録に失敗した場合、サーバから返ってきたメッセージを表示する
    if (!entry.success) {
      alert(entry.message);
      return;
    }
    return customerNo;
  }

  /**
   * 得意先子マスタの登録処理
   * @param customerNo 得意先番号
   * @param departmentName 所属部課または工場名
   */
  private async registCustomerChild(customerNo: string, departmentName: string): Promise<number> {
    console.log('得意先子マスタの新規登録');

    // 得意先子マスタの新規番号取得
    const dataChildList: any = await this.custService.getCustomerChild(customerNo);
    const newChildNo: number =
      (dataChildList && dataChildList.length) ?
        Math.max(...dataChildList.map((p) => p.child_no)) + 1 :
        1;

    const childData = {
      customer_no: customerNo,
      child_no: newChildNo,
      department_or_factory_name: departmentName,
      postal_code: '',
      address: '',
      tel_no: '',
      representative: false
    };

    // 登録処理
    const entry = await this.custService.insertCustomerChild(childData);
    // 登録に失敗した場合、サーバから返ってきたメッセージを表示する
    if (!entry.success) {
      alert(entry.message);
      return null;
    }
    return newChildNo;
  }

  /**
   * 印刷ボタン押下時の処理
   *
   * 選択した試作番号の試作依頼書のPDFを別タブで表示する。
   */
  async onPrint() {
    console.log('@@@@ 印刷ボタン押下');

    // 試作番号が採番されていない場合、処理を中断します。
    if (!this.selected.prototype_no) {
      alert('試作番号が採番されていないため、印刷できません。');
      return;
    }

    // PDFの取得(Base64形式のPDFデータ)
    const pdfData = await this.service.getPrototypeRequestPdf(this.selected.prototype_no);

    // PDFのブラウザ表示
    this.pdfService.makePdf(pdfData);
  }

  // #endregion

  // #region 共通処理

  /**
   * 案件ステータスを変更する共通処理
   */
  private changeOrderStatus() {
    // 「完了」以外の試作ステータスが1件でも存在する場合は「サンプル準備中」
    // それ以外の場合は「サンプルワーク中」
    const rec: any = this.dataRequestList.filter(v => v.prototype_status != PrototypeStatus.Done);
    this.orderStatus = (rec && rec.length) ? OrderStatus.Preparing : OrderStatus.Working;
    this.orderStatusCheck.setValue(this.orderStatus);
  }

  /**
   * 通知メールの送信対象となる試作番号を追加する
   * @param prototypeNo 試作番号
   */
  private addSendPrototypeNo(prototypeNo: string) {
    if (!prototypeNo) return;

    // 同一の試作番号がすでに存在する場合はスキップする
    const rec: any = this.sendPrototypeNoList.filter(v => v == prototypeNo);
    if (rec && rec.length) return;

    // 試作番号を配列に追加する
    this.sendPrototypeNoList.push(prototypeNo);

    // 試作番号を昇順でソートする
    this.sendPrototypeNoList.sort();
  }

  // #endregion
}
