import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTable } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { HeaderTitleService } from 'src/app/shared/service/headerTitle.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { DialogComponent } from 'src/app/common/dialog/dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { DepartmentEntryModifyDialogComponent } from '../entry-modify-dialog/department-entry-modify-dialog.component';
import { MaintenanceDepartmentService } from 'src/app/maintenance/department/maintenance-department.service';
import { AppConfig } from 'src/app/shared/class/appconfig.class';
import { MatSnackBar } from '@angular/material/snack-bar';

/**
 * 部署マスタ一覧画面
 */
@Component({
  selector: 'app-maintenance-department',
  templateUrl: './department-list.component.html',
  styleUrls: ['./department-list.component.css']
})
export class DepartmentListComponent implements OnInit {

  /**
   * 部署マスタリストコンポーネントのコンストラクター
   * @constructor
   */
  constructor(
    private title: Title,
    private headerTitle: HeaderTitleService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private depService: MaintenanceDepartmentService) {
    // ページタイトルの設定
    this.title.setTitle('部署マスタリスト | ' + AppConfig.SYSTEM_NANE);
    // ヘッダータイトルの設定
    this.headerTitle.setTitle('部署マスタリスト');
  }

  /** 取得した部署一覧を格納 */
  public dataSource;
  /** 選択した部署情報 */
  public selected: any;
  /** 修正する部署番号 */
  public departmentNo: number;
  /** 修正する部署名 */
  public departmentName: string;
  /** 修正対象の削除フラグ */
  public deleteFlag: boolean;
  /** 登録する部署マスタ */
  public departmentData: any;
  /** チェックボックスの状態 */
  public deleteCheckStatus = false;
  /** サービスに渡す引数 */
  public deleteCheck = 0;
  /** 修正ボタンの状態 */
  public isDisabledModify = true;
  /** 削除ボタンの状態 */
  public isDisabledDelete = true;
  /** 復元ボタンの状態 */
  public isDisabledRestore = true;
  /** ViewChildの定義 */
  @ViewChild('table') table: MatTable<any>;

  /** 画面表示項目 */
  public displayedColumns: string[] = [
    'department_no',        // 部署番号
    'department_name',      // 部署名
    'mail_address',         // メールアドレス
    'delete_flag',          // 削除フラグ
  ];
  /** 削除フラグの画面表示(falseの場合は空白、trueの場合は○を表示) */
  public isDeleted = { '=false': '', '=true': '○' };

  /**
   * 初期処理の実施
   *
   * 部署マスタを取得する。
   */
  ngOnInit(): void {
    // 部署マスタの取得
    this.getDepartmentList();
  }

  /**
   * テーブル行を選択
   *
   * 必要な情報を保存し、部署マスタ修正ダイアログを表示する。
   * @param department 行選択した部署情報
   */
  public selectedRow(department) {
    console.log('@@@@ テーブル行選択');

    // 選択した社員情報をセットする
    this.selected = department;

    if (department.delete_flag) {
      // 修正ボタン、削除ボタンを活性、復元ボタンを非活性
      this.isDisabledModify = true;
      this.isDisabledDelete = true;
      this.isDisabledRestore = false;
    } else {
      // 修正ボタン、削除ボタンを非活性、復元ボタンを活性
      this.isDisabledModify = false;
      this.isDisabledDelete = false;
      this.isDisabledRestore = true;
    }
  }

  /**
   * 登録ボタン押下
   *
   * 部署マスタ登録ダイアログを表示する。
   */
  public onEntry() {
    console.log('@@@@ 登録ダイアログに遷移');

    const dialogRef =  this.dialog.open(DepartmentEntryModifyDialogComponent, {
      data: {
        title: '部署マスタ登録',
      },
      // 画面外のクリックを禁止する設定を追加
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(async(result) => {
      if (!result) {
        // 部署マスタの再取得
        this.getDepartmentList();
      } else {
        // キャンセル押下時は何もしない
        console.log('ダイアログでキャンセルされました。');
        return;
      }
    });
  }

  /**
   * 修正ボタン押下時の処理
   */
  public onModify() {
    const dialogRef =  this.dialog.open(DepartmentEntryModifyDialogComponent, {
      data: {
        title: '部署マスタ修正',
        departmentno: this.selected.department_no,
        departmentname: this.selected.department_name,
        mailaddress: this.selected.mail_address,
        deleteflag: this.selected.delete_flag,
      },
      // 画面外のクリックを禁止する設定を追加
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(async(result) => {
      if (!result) {
        // 部署マスタの再取得
        this.getDepartmentList();
      } else {
        // キャンセル押下時は何もしない
        console.log('ダイアログでキャンセルされました。');
        return;
      }
    });
  }

  /**
   * 削除ボタン押下
   *
   * 削除ダイアログを表示し、指定された行の部署データを論理削除する。
   * @param departmentno 削除を選択した行の部署番号
   */
  public onDelete(departmentno) {
    console.log('@@@@ 削除ダイアログに遷移');

    // 取消処理開始前に確認ダイアログを表示
    const dialogRef =  this.dialog.open(DialogComponent, {
      width: '300px',
      height: '300px',
      data: {
        title: '削除確認',
        message: '選択した部署を削除してよろしいでしょうか？',
      },
      // 画面外のクリックを禁止する設定を追加
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(async(result) => {
      if (!result) {
        // 社員を削除する
        const rec: any = await this.depService.deleteDepartment(this.selected.department_no);

        // 削除に失敗した場合、サーバから返ってきたメッセージを表示する
        if (!rec.success) {
          alert(rec.message);
          return;
        }
        // 部署マスタの再取得
        this.getDepartmentList();
        console.log('@@@@ 削除処理が完了しました。');
      } else {
        // キャンセル押下時は何もしない
        console.log('削除をキャンセルしました。');
        return;
      }
    });
  }

  /**
   * 部署一覧の取得
   *
   * 部署一覧を取得する。
   */
  private async getDepartmentList() {
    // 部署一覧を取得する
    const rec: any = await this.depService.getDepartmentList(this.deleteCheck);
    // 取得した結果をテーブルにセットする
    this.dataSource = rec;
  }

  /**
   * テーブル行の入れ替え
   *
   * 行のドラッグ&ドロップ時、行位置を入れ替え表示された順にテーブルを更新する。
   * @param event ドラッグ&ドロップした行のイベント情報
   */
  onListDrop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.dataSource, event.previousIndex, event.currentIndex);
    this.table.renderRows();
    console.log(this.dataSource);

    // 部署番号を配列に変換
    this.updateDepartmentSort();
  }

  /**
   * 入れ替えテーブル行の登録
   *
   * 入れ替えた行位置の順にテーブルを更新する。
   */
  async updateDepartmentSort() {
    // 部署番号を配列に変換
    // [ "0001", "0002", "0003", ... ]
    const mappingDepartmentNo = this.dataSource.map(x => x.department_no);

    // 部署名を配列に変換
    // [ "営業部", "商品開発部", "生産部", ... ]
    const mappingDepartmentName = this.dataSource.map(x => x.department_name);

    // メールアドレスを配列に変換
    // [ "eigyo@mitsuwa-shoji.co.jp", "kaihatsu@mitsuwa-shoji.co.jp", "seisan@mitsuwa-shoji.co.jp", ... ]
    const mappingMailAddress = this.dataSource.map(x => x.mail_address);

    // 並び順を配列に変換
    // [ "1", "2", "3", ... ]
    const mappingDepartmentSort = Object.keys(this.dataSource);

    // サーバに渡すJSON
    this.departmentData = {
      department_no: mappingDepartmentNo,
      department_name: mappingDepartmentName,
      mail_address: mappingMailAddress,
      sort_order: mappingDepartmentSort,
    };

    // 部署マスタを登録
    const entry = await this.depService.updateDepartmentSort(this.departmentData);
    // 登録に失敗した場合、サーバから返ってきたメッセージを表示する
    if (!entry.success) {
      alert(entry.message);
      return;
    }

    console.log('@@@@ 並び替え結果の登録が完了しました。');
  }
  /**
   * 復元ボタン押下時の処理
   *
   * 削除された社員の削除済みフラグをtrueに更新する。
   */
  public onRestore() {
    // 処理開始前に確認ダイアログを表示
    const dialogRef =  this.dialog.open(DialogComponent, {
      width: '300px',
      height: '300px',
      data: {
        title: '復元確認',
        message: '現在選択されている部署を復元してよろしいですか？',
      },
      // 画面外のクリックを禁止する設定を追加
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(async(result) => {
      if (!result) {
        // 社員を復元する
        const rec: any = await this.depService.restoreDepartment(this.selected.department_no);

        // 復元に失敗した場合、サーバから返ってきたメッセージを表示する
        if (!rec.success) {
          alert(rec.message);
          return;
        }
        // 復元後処理
        this.snackBar.open('復元しました。', '', {
          duration: 1000
        });
        console.log('@@@@ 復元処理が完了しました。');

        // 部署一覧を再取得する
        this.ngOnInit();
      } else {
        // キャンセル押下時は何もしない
        return;
      }
    });
  }

  /**
   * チェックボックスの値変更時の処理
   *
   * チェックの有無で値を更新する。
   * チェック状態を変更後に再検索を行い、社員一覧を再取得する。
   * @param event チェックボックス
   */
  setDeleteCheck(event) {
    if (event.checked) {
      this.deleteCheck = 1;
    } else {
      this.deleteCheck = 0;
    }

    // 社員一覧を再取得する
    this.ngOnInit();
  }

}
