import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

/**
 * ダイアログコンポーネント
 */
@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.css']
})
export class DialogComponent implements OnInit {

  /**
   * ダイアログコンポーネントのコンストラクター
   * @constructor
   * @param data 画面から渡されたデータ
   * @param dialogRef MatDialogRef
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DialogComponent>) { }

  /**
   * 初期処理
   */
  ngOnInit(): void {
  }

  /**
   * OKボタン押下時の処理
   */
  okClick() {
    // ダイアログを閉じる
    this.dialogRef.close();
  }
}
