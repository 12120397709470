import { NgModule } from '@angular/core';

// 共通利用コンポーネント
import { DialogComponent } from './dialog/dialog.component';
import { HeaderComponent } from './header/header.component';
import { MenuComponent } from './menu/menu.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { PasswordChangeComponent } from './password-change/password-change.component';

// オプションモジュール
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { MenuService } from '../shared/service/menu.service';
import { MaterialModule } from '../shared/class/material.module';
import { CommonUseRoutingModule } from './common-use-routing.module';

@NgModule({
  declarations: [
    DialogComponent,
    HeaderComponent,
    MenuComponent,
    LoginComponent,
    HomeComponent,
    PasswordChangeComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    StoreModule,
    MaterialModule,
    CommonUseRoutingModule
  ],
  exports: [
    DialogComponent,
    HeaderComponent,
    MenuComponent,
    LoginComponent,
    HomeComponent,
    PasswordChangeComponent
  ],
  providers: [
    MenuService
  ]
})
export class CommonUseModule { }
