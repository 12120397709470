import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { DialogComponent } from '../../../common/dialog/dialog.component';
import { AppConfig } from '../../../shared/class/appconfig.class';
import { HeaderTitleService } from '../../../shared/service/headerTitle.service';
import { NavigationService } from '../../../shared/service/navigation.service';
import { MaintenanceCustomerService } from '../maintenance-customer.service';

/**
 * 得意先マスタリストコンポーネント
 */
@Component({
  selector: 'app-maintenance-customer',
  templateUrl: './customer-list.component.html',
  styleUrls: ['./customer-list.component.css']
})
export class CustomerListComponent implements OnInit {
  /**
   * 得意先マスタリストコンポーネントのコンストラクター
   * @constructor
   */
  constructor(private title: Title,
              private headerTitle: HeaderTitleService,
              private transitionService: NavigationService,
              private router: Router,
              private matDialog: MatDialog,
              private matSnackBar: MatSnackBar,
              private custService: MaintenanceCustomerService) {
    // ページタイトルの設定
    this.title.setTitle('得意先マスタリスト | ' + AppConfig.SYSTEM_NANE);
    // ヘッダータイトルの設定
    this.headerTitle.setTitle('得意先マスタリスト');
  }

  /** 取得した得意先一覧を格納 */
  public dataSource = new MatTableDataSource();
  /** 選択した得意先情報 */
  public selected: any;
  /** マウスオーバーした得意先情報 */
  public hovered: any;
  /** チェックボックスの状態 */
  public deleteCheckStatus = false;
  /** サービスに渡す引数 */
  public deleteCheck = 0;
  /** 修正ボタンの状態 */
  public isDisabledModify = true;
  /** 削除ボタンの状態 */
  public isDisabledDelete = true;
  /** 復元ボタンの状態 */
  public isDisabledRestore = true;

  /** 画面表示項目 */
  public displayedColumns: string[] = [
    'customer_no',                // 得意先番号
    'company_name',               // 得意先会社名
    'postal_code',                // 得意先会社の郵便番号
    'address',                    // 得意先会社の住所
    'department_or_factory_name', // 得意先会社に紐付く所属部課または工場
    'tel_no',                     // 電話番号
    'representative',             // 代表
    'delete_flag',                // 削除フラグ
  ];
  /** 削除フラグの画面表示(falseの場合は空白、trueの場合は○を表示) */
  public isDeleted = { '=false': '', '=true': '○' };
  /** 代表の画面表示(falseの場合は空白、trueの場合は○を表示) */
  public isRepresentative = { '=false': '', '=true': '○' };

  /**
   * 初期表示時の処理
   */
  async ngOnInit(): Promise<void> {
    // 修正ボタン、削除ボタン、復元ボタンを非活性
    this.isDisabledModify = true;
    this.isDisabledDelete = true;
    this.isDisabledRestore = true;

    // 選択した得意先情報をクリア
    this.selected = null;

    // 得意先一覧を取得
    await this.getCustomerList();
  }

  /**
   * 行選択時の処理
   * @param customer 行選択した得意先情報
   */
  public selectedRow(customer) {
    // 選択した得意先情報をセットする
    this.selected = customer;

    if (customer.delete_flag) {
      // 修正ボタン、削除ボタンを活性、復元ボタンを非活性
      this.isDisabledModify = true;
      this.isDisabledDelete = true;
      this.isDisabledRestore = false;
    } else {
      // 修正ボタン、削除ボタンを非活性、復元ボタンを活性
      this.isDisabledModify = false;
      this.isDisabledDelete = false;
      this.isDisabledRestore = true;
    }
  }

  /**
   * 新規登録ボタン押下時の処理
   */
  public onEntry() {
    // 画面遷移の許可
    this.transitionService.canTransition = true;
    // 得意先マスタ登録画面に遷移
    this.router.navigate(['/maintenance/customer/entry']);
  }

  /**
   * 修正ボタン押下時の処理
   */
  public onModify() {
    // 得意先番号をサービスに保存
    this.custService.sendDetailInfomation(this.selected.customer_no);
    // 画面遷移の許可
    this.transitionService.canTransition = true;
    // 得意先マスタ修正画面に遷移
    this.router.navigate(['/maintenance/customer/modify']);
  }

  /**
   * 削除ボタン押下時の処理
   *
   * 選択された得意先情報を論理削除する。
   */
  public onDelete() {
    // 処理開始前に確認ダイアログを表示
    const dialogRef =  this.matDialog.open(DialogComponent, {
      width: '300px',
      height: '300px',
      data: {
        title: '削除確認',
        message: '現在選択されている得意先を削除してよろしいですか？',
      },
      // 画面外のクリックを禁止する設定を追加
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(async(result) => {
      if (!result) {
        // 得意先を削除する
        const rec: any = await this.custService.deleteCustomerParent(this.selected.customer_no);

        // 削除に失敗した場合、サーバから返ってきたメッセージを表示する
        if (!rec.success) {
          alert(rec.message);
          return;
        }
        // 削除後処理
        this.matSnackBar.open('削除しました。', '', {
          duration: 1000
        });
        console.log('@@@@ 削除処理が完了しました。');

        // 得意先一覧を再取得する
        this.ngOnInit();
      } else {
        // キャンセル押下時は何もしない
        return;
      }
    });
  }

  /**
   * 復元ボタン押下時の処理
   *
   * 削除された得意先の削除済みフラグをtrueに更新する。
   */
  public onRestore() {
    // 処理開始前に確認ダイアログを表示
    const dialogRef =  this.matDialog.open(DialogComponent, {
      width: '300px',
      height: '300px',
      data: {
        title: '復元確認',
        message: '現在選択されている得意先を復元してよろしいですか？',
      },
      // 画面外のクリックを禁止する設定を追加
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(async(result) => {
      if (!result) {
        // 得意先を復元する
        const rec: any = await this.custService.restoreCustomerDetail(this.selected.customer_no);

        // 復元に失敗した場合、サーバから返ってきたメッセージを表示する
        if (!rec.success) {
          alert(rec.message);
          return;
        }
        // 復元後処理
        this.matSnackBar.open('復元しました。', '', {
          duration: 1000
        });
        console.log('@@@@ 復元処理が完了しました。');

        // 得意先一覧を再取得する
        this.ngOnInit();
      } else {
        // キャンセル押下時は何もしない
        return;
      }
    });
  }

  /**
   * チェックボックスの値変更時の処理
   *
   * チェックの有無で値を更新する。
   * チェック状態を変更後に再検索を行い、得意先一覧を再取得する。
   * @param event チェックボックス
   */
  setDeleteCheck(event) {
    if (event.checked) {
      this.deleteCheck = 1;
    } else {
      this.deleteCheck = 0;
    }

    // 得意先一覧を再取得する
    this.ngOnInit();
  }

  /**
   * 得意先一覧を取得する。
   */
  private async getCustomerList() {
    // 得意先一覧を取得する
    const rec: any = await this.custService.getCustomerList(this.deleteCheck);

    // 取得した結果をテーブルにセットする
    console.log(rec);
    this.dataSource = rec;
  }
}
