import { NgModule } from '@angular/core';

// コンポーネント
import { AppComponent } from './app.component';
import { CommonUseModule } from './common/common-use.module';
import { MaintenanceModule } from './maintenance/maintenance.module';
import { OrderModule } from './order/order.module';
import { InformationModule } from './information/information.module';

// オプションモジュール
import { AppRoutingModule } from './app-routing.module';
import { StoreModule } from '@ngrx/store';
import { StoreRouterConnectingModule, routerReducer } from '@ngrx/router-store';

// サービス
import { Actions } from './shared/class/actions';
import { CryptoService } from './shared/service/crypto.service';
import { CryptoStorageService } from './shared/service/cryptoStorage.service';
import { ExclusiveControlService } from './shared/service/exclusive.service';
import { GlobalEventService } from './shared/service/globalEvent.service';
import { HttpService } from './shared/service/http.service';
import { LogoutService } from './shared/service/logout.service';
import { NavigationService } from './shared/service/navigation.service';
import { StartupService } from './shared/service/startup.service';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    AppRoutingModule,
    CommonUseModule,
    MaintenanceModule,
    OrderModule,
    InformationModule,
    StoreModule.forRoot({
      router: routerReducer,
    })
  ],
  providers: [
    Actions,
    CryptoService,
    CryptoStorageService,
    ExclusiveControlService,
    GlobalEventService,
    HttpService,
    LogoutService,
    NavigationService,
    StartupService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
