<form #formCheck="ngForm">
  <div class="entry-container">
    <div fxLayout="row" fxLayout.xs="column">
      <div fxFlex="10"></div>
      <div fxFlex="80">
        <div fxLayout="row" fxLayout.xs="column">
          <div fxFlex="50">
            <div fxLayout="row" fxLayout.xs="column">
              <div fxFlex class="input-area">
                <!-- 社員番号 -->
                <mat-form-field appearance="standard">
                  <mat-label>社員番号</mat-label>
                  <input matInput type="tel" maxlength="4" name="employeeNo" [(ngModel)]="employeeNo" *ngIf="inputMode"
                    (change)="$event.target.value = onChange($event.target.value)"
                    oninput="value = value.replace(/\D/g, '')"
                    required>
                </mat-form-field>
              </div>
              <div fxFlex="50" class="input-area">
                <div>
                  <!-- パスワード -->
                  <mat-form-field appearance="standard">
                    <mat-label>パスワード</mat-label>
                    <input matInput type="password" name="password" [(ngModel)]="password" [formControl]="checkPass" autocomplete="off">
                    <div class="check" *ngIf="checkPass.invalid && (checkPass.dirty || checkPass.touched)">
                      <mat-error *ngIf="checkPass.hasError('pattern')">大小英数字記号を使った8桁以上のパスワードにしてください。</mat-error>
                    </div>
                  </mat-form-field>
                </div>
              </div>
              </div>
            <div fxLayout="row" fxLayout.xs="column">
              <div fxFlex="50" class="input-area">
                <!-- 社員名 -->
                <mat-form-field appearance="standard" class="form-name">
                  <mat-label>社員名</mat-label>
                  <input matInput name="employeeName" [(ngModel)]="employeeName" *ngIf="inputMode" [formControl]="employeenameCheck" required>
                  <mat-error *ngIf="employeenameCheck.hasError('required')">必須項目です。</mat-error>
                </mat-form-field>
              </div>
              <div fxFlex="50"></div>
            </div>
            <div fxLayout="row" fxLayout.xs="column">
              <div fxFlex class="select-area">
                <!-- 所属部署 -->
                <mat-label>所属部署</mat-label>
                <mat-selection-list #mappingDepartment [disabled]="itemControl">
                  <mat-list-option class="select-area" [checkboxPosition]="before" *ngFor="let department of departmentNameList" [value]="department.department_no" [selected]="department.selected" >
                    {{department.department_name}}
                  </mat-list-option>
                </mat-selection-list>
              </div>
            </div>
            <div fxLayout="row" fxLayout.xs="column">
              <div fxFlex="50" class="input-area">
                <!-- メールアドレス -->
                <mat-form-field appearance="standard" class="form-email">
                  <mat-label>メールアドレス</mat-label>
                  <input type="email" matInput [formControl]="mailAddressCheck" name="mailAddress" [(ngModel)]="mailAddress" *ngIf="inputMode" autocomplete="off" required>
                  <mat-error *ngIf="mailAddressCheck.hasError('required')">必須項目です。</mat-error>
                  <mat-error *ngIf="mailAddressCheck.hasError('email')">メールアドレス形式で入力してください。</mat-error>
                </mat-form-field>
              </div>
              <div fxFlex="50" class="input-area">
                <!-- 携帯電話 -->
                <mat-form-field appearance="standard">
                  <mat-label>携帯電話</mat-label>
                  <input matInput name="mobilePhoneNumber" [(ngModel)]="mobilePhoneNumber" *ngIf="inputMode" [formControl]="mobilePhoneNumberCheck">
                  <mat-error *ngIf="mobilePhoneNumberCheck.hasError('pattern')">携帯電話番号を入力してください。</mat-error>
                </mat-form-field>
              </div>
            </div>
            <div fxLayout="row" fxLayout.xs="column">
              <div fxFlex="50" class="input-area">
                <!-- 入社日 -->
                <mat-form-field appearance="standard">
                  <mat-label>入社日</mat-label>
                  <input matInput
                    name="joinDate"
                    class="select-date-label"
                    [formControl]="joinDateCheck"
                    [matDatepicker]="joinpicker"
                    [(ngModel)]="joinDate"
                    (dateChange)="setJoinDate($event)"
                    required
                    disabled>
                  <mat-datepicker-toggle matSuffix [for]="joinpicker"></mat-datepicker-toggle>
                  <mat-datepicker #joinpicker [disabled]="itemControl"></mat-datepicker>
                  <mat-error *ngIf="joinDateCheck.hasError('required')">日付を選択してください。</mat-error>
                </mat-form-field>
              </div>
              <div fxFlex="50" class="input-area">
                <!-- 退社日 -->
                <mat-form-field appearance="standard">
                  <mat-label>退社日</mat-label>
                  <input matInput
                    name="leaveDate"
                    class="select-date-label"
                    [matDatepicker]="leavepicker"
                    [(ngModel)]="leaveDate"
                    (dateChange)="setLeaveDate($event)"
                    disabled>
                  <mat-datepicker-toggle matSuffix [for]="leavepicker"></mat-datepicker-toggle>
                  <mat-datepicker #leavepicker [disabled]="itemControl"></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div fxFlex="50">
            <!-- 権限管理 -->
            <div class="authority-container">
              <div class="authority-title">権限管理</div>
              <div fxLayout="row" fxLayout.xs="column">
                <!-- 権限あり -->
                <div class="authority-sub-container">
                  <div class="authority-sub-title">権限あり</div>
                  <div cdkDropList
                    class="authority-list"
                    #hasAuthorityList="cdkDropList"
                    [cdkDropListData]="hasAuthority"
                    [cdkDropListConnectedTo]="[notHasAuthorityList]"
                    (cdkDropListDropped)="drop($event)">
                    <div cdkDrag class="authority-item" [cdkDragDisabled]="itemControl"
                      *ngFor="let item of hasAuthority">
                      {{item.function_name}}
                    </div>
                  </div>
                </div>

                <div class="authority-icon-container">
                  <mat-icon fxShow fxHide.xs>swap_horiz</mat-icon>
                  <mat-icon fxShow.xs fxHide>swap_vert</mat-icon>
                </div>

                <!-- 権限なし -->
                <div class="authority-sub-container">
                  <div class="authority-sub-title">権限なし</div>
                  <div cdkDropList
                    class="authority-list"
                    #notHasAuthorityList="cdkDropList"
                    [cdkDropListData]="notHasAuthority"
                    [cdkDropListConnectedTo]="[hasAuthorityList]"
                    (cdkDropListDropped)="drop($event)">
                    <div cdkDrag class="authority-item" [cdkDragDisabled]="itemControl"
                      *ngFor="let item of notHasAuthority">
                      {{item.function_name}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="center" class="button-container">
          <!-- 戻る -->
          <button mat-raised-button color="primary" (click)="onCancel()">
            <mat-icon>keyboard_backspace</mat-icon>
            <span class="btn-label">戻る</span>
          </button>

          <!-- 登録 -->
          <button mat-raised-button color="primary" (click)="onRegist(mappingDepartment.selectedOptions.selected)">
            <mat-icon>check</mat-icon>
            <span class="btn-label">{{registButtonName}}</span>
          </button>
        </div>
      </div>
      <div fxFlex="10"></div>
    </div>
  </div>
</form>
