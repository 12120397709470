import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MaintenanceCustomerService } from '../maintenance-customer.service';

/**
 * 得意先行追加ダイアログ
 */
@Component({
  selector: 'app-customer-line-add-modify-dialog',
  templateUrl: './customer-line-add-modify-dialog.component.html',
  styleUrls: ['./customer-line-add-modify-dialog.component.css']
})
export class CustomerLineAddDialogComponent implements OnInit {

  /** 所属部課または工場 */
  public departmentOrFactoryName = '';
  /** 所属部課または工場の郵便番号 */
  public postalCode: string;
  /** 所属部課または工場の住所 */
  public address = '';
  /** 所属部課または工場の電話電話 */
  public telNumber: string;
  /** 代表チェック */
  public representativeCheck = false;
  /** 入力でFormControlを使用する項目の表示切替 */
  public inputMode = true;
  /** 項目制御(参照モード用) */
  public itemControl = true;
  /** ボタン名称 */
  public buttonName: string;
  /** 登録する得意先マスタ */
  public customerData: any;
  /** 得意先子番号 */
  public childNo: number;

  /**
   * コンストラクタ
   * @constructor
   * @param data 一覧画面から取得したデータ
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<CustomerLineAddDialogComponent>,
    private custService: MaintenanceCustomerService,
    private changeDetectorRef: ChangeDetectorRef) { }

  /**
   * 初期処理
   * ボタン名を設定、一覧画面から受け取った値をセットする。
   */
  ngOnInit(): void {
    if (this.data.departmentorfactoryname) {
      // 修正の場合、画面から渡された値に入力項目を更新する
      this.childNo = this.data.childno;
      this.departmentOrFactoryName = this.data.departmentorfactoryname;
      this.postalCode = this.data.postalcode;
      this.address = this.data.address;
      this.telNumber = this.data.telnumber;
      this.representativeCheck = this.data.representative;
      this.buttonName = '修正';
      // ExpressionChangedAfterItHasBeenCheckedError対策
      this.changeDetectorRef.detectChanges();

    } else {
      // 行追加の場合、childnoだけ更新
      this.buttonName = '追加';
      this.childNo = this.data.childno;
    }
  }

  /** チェック：所属部課または工場 */
  public departmentOrFactoryNameCheck = new FormControl('', [Validators.required]);
  /** チェック：郵便番号 */
  public postalCodeCheck = new FormControl('', Validators.pattern(/^\d{3}-\d{4}$/));
  /** チェック：電話番号 */
  public telNumberCheck = new FormControl('', Validators.pattern(/^\d{2,5}-\d{1,4}-\d{4}$/));

  /**
   * チェックボックスの値変更時の処理
   *
   * チェックの有無で値を更新する。
   * @param event チェックボックス
   */
  setRepresentativeCheck(event) {
    if (event.checked) {
      this.representativeCheck = true;
    } else {
      this.representativeCheck = false;
    }
  }

  /**
   * 追加/修正ボタンクリック
   * 入力した情報を得意先マスタ登録/修正画面に反映する
   */
  async onClick() {
    console.log('@@@@ 追加/修正処理');

    if (!this.departmentOrFactoryName) {
      console.log('入力必須項目が　未入力のため処理中断');
      alert('入力必須項目が未入力です。');
      return;
    }

    // 郵便番号・電話番号が未入力の場合は空白をセット
    if (!this.postalCode) {
      this.postalCode = '';
    }
    if (!this.telNumber) {
      this.telNumber = '';
    }

    // 得意先マスタ登録/修正に渡すJSON
    this.customerData = [{
      child_no: this.childNo,
      department_or_factory_name: this.departmentOrFactoryName,
      postal_code: this.postalCode,
      address: this.address,
      tel_no: this.telNumber,
      representative: this.representativeCheck
    }];

    // JSONデータをサービスに保存
    this.custService.sendCustomerChildInfomation(this.customerData);

    // 得意先マスタ登録/修正画面に戻る
    this.dialogRef.close(false);
  }

}
