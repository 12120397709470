import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { HeaderTitleService } from '../../shared/service/headerTitle.service';
import { AppConfig } from '../../shared/class/appconfig.class';
import { HttpClient } from '@angular/common/http';
import { HttpReqOptions } from '../../shared/interface/httpOption.interface';
import { NavigationService } from '../../shared/service/navigation.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

/**
 * パスワード変更コンポーネント
 */
@Component({
  selector: 'app-password-change',
  templateUrl: './password-change.component.html',
  styleUrls: ['./password-change.component.css']
})
export class PasswordChangeComponent implements OnInit {

  /**
   * パスワード変更コンポーネントのコンストラクター
   * @constructor
   */
  constructor(private title: Title,
              private headerTitle: HeaderTitleService,
              private http: HttpClient,
              private transitionService: NavigationService,
              private router: Router,
              private snackBar: MatSnackBar) {
    // ページタイトル設定
    this.title.setTitle('パスワード変更 | ' + AppConfig.SYSTEM_NANE);
  }

  /** 旧パスワード */
  public oldPassword: string; //
  /** 新パスワード */
  public newPassword: string; //
  /** パスワード確認 */
  public passwordConfirm: string; //
  /** エラーメッセージ */
  public errMessage = '';

  /** 必須チェック：社員番号 */
  public checkEmp = new FormControl('', [
    Validators.required
  ]);

  /** 必須チェック：旧パスワード */
  public checkOldPass = new FormControl('', [
    Validators.required
  ]);

  /**
   * 必須チェック：新パスワード
   *
   * パスワードチェック：8桁以上かつ大文字小文字記号を最低１文字
   */
  public checkNewPass = new FormControl('', [
    Validators.required,
    Validators.pattern(/^(?=.*[!-/:-@[-`{-~])(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])[!-~]{8,}$/)
  ]);

  /** 必須チェック：パスワード確認 */
  public checkPassConfirm = new FormControl('', [
    Validators.required
  ]);

  /**
   * 初期処理の実施
   */
  ngOnInit(): void {
    // ヘッダータイトル設定
    this.headerTitle.setTitle('パスワード変更');
  }

  /**
   * バックエンドへパスワード変更リクエスト
   */
  async submit(): Promise<void> {
    console.log('@@@@ パスワード変更処理の開始');
    // 未入力の項目がある場合エラーを表示
    if (this.oldPassword == null ||
        this.newPassword == null ||
        this.passwordConfirm == null) {
      this.errMessage = '未入力項目があります。入力してください。';
      return;
    }

    // 新パスワードと新パスワード確認の値が合致しない場合エラーを表示
    if (this.newPassword !== this.passwordConfirm) {
      this.errMessage = '新パスワードと新パスワード確認が合致していません。';
      return;
    }

    // 新パスワードがパスワード規則に沿っていない場合エラーを表示
    const pettern = '^(?=.*[!-/:-@[-`{-~])(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])[!-~]{8,}$';
    if (!this.newPassword.match(pettern)) {
      this.errMessage = '新パスワードには8桁以上の大小英字と数字記号を使用してください';
      return;
    }

    const config: HttpReqOptions = {
      url: AppConfig.passUrl,
      body: {
        oldPassword: this.oldPassword,
        newPassword: this.newPassword
      }
    };

    // パスワード変更情報をPOST
    const res: any = await this.http
      .post(config.url, config.body)
      .toPromise();

    // パスワード変更に失敗した場合、画面上にメッセージを表示
    if (!res.success) {
      this.errMessage = res.message;
      return;
    }

    this.snackBar.open('パスワード変更が完了しました。', '閉じる', {
      duration: 2000
    });
    console.log('@@@@ パスワード変更処理が完了しました。');

    // 画面遷移の許可
    this.transitionService.canTransition = true;

    // ホーム画面に遷移
    this.router.navigate(['/home']);
  }

  /**
   * エラーメッセージのクリア
   *
   * エラーメッセージが表示されている場合は何かしらの入力をした直後にメッセージをクリアする
   */
  messageClear() {
    if (this.errMessage !== '') {
      this.errMessage = '';
    }
  }
}
