import { MatSelectModule } from '@angular/material/select';
import { CustomerLineAddDialogComponent } from './../line-add-modify-dialog/customer-line-add-modify-dialog.component';
import { Location } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { AppConfig } from '../../../shared/class/appconfig.class';
import { HeaderTitleService } from '../../../shared/service/headerTitle.service';
import { NavigationService } from '../../../shared/service/navigation.service';
import { MaintenanceCustomerService } from '../maintenance-customer.service';

/**
 * 得意先マスタ登録・修正コンポーネント
 */
@Component({
  selector: 'app-customer-entry-modify',
  templateUrl: './customer-entry-modify.component.html',
  styleUrls: ['./customer-entry-modify.component.css']
})
export class CustomerEntryModifyComponent implements OnInit {

  // 初期値
  /** モード(1:登録、2:修正) */
  public mode: string;
  /** 得意先番号 */
  public customerNo: string;
  /** 元得意先番号 */
  public beforeCustomerNo: string;
  /** 得意先会社名 */
  public customerCompanyName: string;
  /** 郵便番号 */
  public postalCode: string;
  /** 住所 */
  public address: string;
  /** 電話電話 */
  public telNumber: string;
  /** 取得した得意先一覧を格納 */
  public dataSource = new MatTableDataSource();
  /** ダイアログで入力した得意先子情報を一時保存 */
  public dataChildTemp: any;
  /** 得意先子の一覧を格納(行追加/削除/修正用) */
  public dataChildList: any = [];
  /** 得意先子番号の最大値 */
  public maxChildNo = 0;
  /** 登録する得意先明細 */
  public customerDetail: any;
  /** 修正ボタンの状態 */
  public isDisabledModify = true;
  /** 削除ボタンの状態 */
  public isDisabledDelete = true;
  /** 選択した得意先子情報 */
  public selected: any;
  /** 登録ボタンの名称 */
  public registButtonName = '';
  /** 入力でFormControlを使用する項目の表示切替 */
  public inputMode = true;
  /** チェックボックス位置(手前) */
  public before = 'before';

  /** 代表の画面表示(falseの場合は空白、trueの場合は○を表示) */
  public isRepresentative = { '=false': '', '=true': '○' };

  /**
   * 得意先マスタ登録・修正コンポーネントのコンストラクター
   * @constructor
   */
  constructor(private title: Title,
              private headerTitle: HeaderTitleService,
              private transitionService: NavigationService,
              private route: ActivatedRoute,
              private location: Location,
              private matDialog: MatDialog,
              private matSnackBar: MatSnackBar,
              private changeDetectorRef: ChangeDetectorRef,
              private custService: MaintenanceCustomerService) {
    // モードによって表示する文言を変更する
    this.route.data.subscribe(data => this.mode = data.mode);
    if (this.mode === '1') {
      // ページタイトルの設定
      this.title.setTitle('得意先マスタ登録 | ' + AppConfig.SYSTEM_NANE);
      // ヘッダータイトルの設定
      this.headerTitle.setTitle('得意先マスタ登録');
      // ボタン名称の設定
      this.registButtonName = '登録';
    } else if (this.mode === '2') {
      // ページタイトルの設定
      this.title.setTitle('得意先マスタ修正 | ' + AppConfig.SYSTEM_NANE);
      // ヘッダータイトルの設定
      this.headerTitle.setTitle('得意先マスタ修正');
      // ボタン名称の設定
      this.registButtonName = '修正';
    }
  }

  /** 一覧表示項目 */
  public displayedColumns: string[] = [
    'child_no',                   // 得意先子番号
    'department_or_factory_name', // 所属部課または工場
    'postal_code',                // 郵便番号
    'address',                    // 住所
    'tel_no',                     // 電話番号
    'representative'              // 代表
  ];

  /** チェック：会社名 */
  public companynameCheck = new FormControl('', [Validators.required]);
  /** チェック：郵便番号 */
  public postalCodeCheck = new FormControl('', Validators.pattern(/^\d{3}-\d{4}$/));
  /** チェック：電話番号 */
  public telNumberCheck = new FormControl('', Validators.pattern(/^\d{2,5}-\d{1,4}-\d{4}$/));

  /**
   * 初期処理
   *
   * 登録の場合、新規の得意先番号を取得し設定する。
   * 修正の場合、得意先情報を取得し設定する。
   */
  async ngOnInit(): Promise<void> {

    // 画面の初期化
    this.initializeWindow();

    if (this.mode === '1') {
      // 登録モードであれば、新規番号を採番
      await this.getNewCustomerNo();
    } else if (this.mode === '2') {
      // 修正モードであれば、サービスから得意先番号を取得
      this.custService.customerNo.subscribe(async customerNo => {
        this.customerNo = customerNo;
        this.beforeCustomerNo = customerNo;
      });
      // 該当得意先検索
      await this.getCustomerDetail();
    }
  }

  /**
   * 行選択時の処理
   * @param customer 行選択した得意先情報
   */
  public selectedRow(customer) {
    // 選択した得意先情報をセットする
    this.selected = customer;

    if (customer.delete_flag) {
      // 修正ボタン、削除ボタンを非活性
      this.isDisabledModify = true;
      this.isDisabledDelete = true;
    } else {
      // 修正ボタン、削除ボタンを活性
      this.isDisabledModify = false;
      this.isDisabledDelete = false;
    }
  }

  /**
   * 行追加ボタン押下時の処理
   *
   * ダイアログで入力した値で得意先子一覧の末尾に1行追加する。
   */
  public async onAdd() {
    console.log('行追加ボタン押下');
    // 得意先子番号に入れる値を更新する
    this.maxChildNo += 1;

    const dialogRef = this.matDialog.open(CustomerLineAddDialogComponent, {
      data: {
        title: '行追加',
        childno: this.maxChildNo
      },
      // 画面外のクリックを禁止する設定を追加
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(async(result) => {
      if (!result) {
        // サービスから取得した得意先子情報を一覧にセットする
        this.custService.customerChild.subscribe(async customerChild => {
          this.dataChildTemp = customerChild;
        });
      } else {
        // キャンセル押下時は何もしない
        console.log('ダイアログでキャンセルされました。');
        return;
      }
      await this.setCustomerChildList(this.dataChildTemp);
    });
  }

  /**
   * 修正ボタン押下時の処理
   */
  public async onModify() {
    console.log('修正ボタン押下');
    const dialogRef =  this.matDialog.open(CustomerLineAddDialogComponent, {
      data: {
        title: '修正',
        childno: this.selected.child_no,
        departmentorfactoryname: this.selected.department_or_factory_name,
        postalcode: this.selected.postal_code,
        address: this.selected.address,
        telnumber: this.selected.tel_no,
        representative: this.selected.representative,
      },
      // 画面外のクリックを禁止する設定を追加
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(async(result) => {
      if (!result) {
        // サービスから取得した得意先子情報を一覧にセットする
        this.custService.customerChild.subscribe(async customerChild => {
          this.dataChildTemp = customerChild[0];
        });
        await this.dataChildList.find(list => {
          if (list.child_no == this.dataChildTemp.child_no) {
            // 選択行の得意先子情報を更新する
            list.department_or_factory_name = this.dataChildTemp.department_or_factory_name;
            list.postal_code = this.dataChildTemp.postal_code;
            list.address = this.dataChildTemp.address;
            list.tel_no = this.dataChildTemp.tel_no;
            list.representative = this.dataChildTemp.representative;
          }
        });
      } else {
        // キャンセル押下時は何もしない
        console.log('ダイアログでキャンセルされました。');
        return;
      }
    });
    // 画面に反映する
    this.dataSource = new MatTableDataSource(this.dataChildList);
  }

  /**
   * 削除ボタン押下時の処理
   *
   * 選択した得意先子情報を画面から削除する。
   */
  public onDelete() {

    console.log('選択行を削除');
    // 選択した行を保持している一覧から削除し、再度画面にセットする
    this.dataChildList = this.dataChildList.filter(list => list !== this.selected);
    this.dataSource = new MatTableDataSource(this.dataChildList);

    // 修正ボタン、削除ボタンを非活性
    this.isDisabledModify = true;
    this.isDisabledDelete = true;

    if (this.dataChildList.length == 0) {
      // 全ての行を削除した場合、得意先子番号の最大値を初期化する
      this.maxChildNo = 0;
    }
  }

  /**
   * 戻るボタン押下時の処理
   */
  async onCancel() {
    // 画面遷移の許可
    this.transitionService.canTransition = true;
    this.location.back();
  }

  /**
   * 登録ボタン押下時の処理
   *
   * 入力チェックを行い問題なければ、
   * 得意先親マスタへ登録、更新を行う。
   * 修正時、得意先番号が変更されていた場合、
   * 変更しても問題ないかを確認してから修正処理を行う。
   */
  async onRegist(): Promise<void> {

    // 登録処理開始前に入力内容をチェックし該当する場合はアラートを表示
    if (!this.customerCompanyName) {
      console.log('入力必須項目が未入力のため処理中断');
      alert('入力必須項目が未入力です。');
      return;
    }
    // 郵便番号が空白の場合は空白に更新
    if (!this.postalCode) {
      this.postalCode = '';
    }

    // モード毎に登録・修正の処理を実行する
    if (this.mode === '1') {
      console.log('@@@@ 登録ボタン押下');

      // 番号チェック
      const rec: any = await this.custService.checkCustomerNo(this.customerNo);

      if (rec) {
        console.log('得意先番号重複のため処理中断');
        alert('得意先番号が重複しています、異なる番号に変更してください。');
        return;
      }

      // サーバに渡すJSON
      this.customerDetail = {
        customer_no: this.customerNo,
        company_name: this.customerCompanyName,
        postal_code: this.postalCode,
        address: this.address,
        customer_child: this.dataChildList
      };

      // 得意先明細を登録
      const entry = await this.custService.insertCustomerDetail(this.customerDetail);
      // 登録に失敗した場合、サーバから返ってきたメッセージを表示する
      if (!entry.success) {
        alert(entry.message);
        return;
      }
      // 登録後処理
      this.matSnackBar.open('登録しました。', '', {
        duration: 1000
      });
      console.log('@@@@ 登録処理が完了しました。');
    } else if (this.mode === '2') {
      console.log('@@@@ 修正ボタン押下');

      // 得意先番号修正チェック
      if (this.customerNo != this.beforeCustomerNo) {
        // 修正後の得意先番号と重複している得意先番号が存在するか確認する
        const rec: any = await this.custService.checkCustomerNo(this.customerNo);

        if (rec) {
          console.log('得意先番号重複のため処理中断');
          alert('修正後の得意先番号が存在しています、異なる番号に変更してください。');
          return;
        }
      }

      this.customerDetail = {
        customer_no: this.customerNo,
        company_name: this.customerCompanyName,
        postal_code: this.postalCode,
        address: this.address,
        customer_child: this.dataChildList
      };

      // 得意先明細を修正
      const modify = await this.custService.updateCustomerDetail(this.customerDetail);
      // 登録に失敗した場合、サーバから返ってきたメッセージを表示する
      if (!modify.success) {
        alert(modify.message);
        return;
      }
      // 修正後処理
      this.matSnackBar.open('修正しました。', '', {
        duration: 1000
      });
      console.log('@@@@ 修正処理が完了しました。');
    }

    // 画面遷移の許可
    this.transitionService.canTransition = true;
    // 得意先一覧画面に戻る
    this.location.back();
  }

  /**
   * 得意先明細の取得
   *
   * 得意先情報を取得し、各項目に設定する。
   * 削除済みの得意先である場合は参照モードに切り替える。
   */
  private async getCustomerDetail() {

    // 得意先の親情報を取得する
    const recParent: any = await this.custService.getCustomerParent(this.customerNo);
    // 得意先の子情報を取得する
    const recChild: any = await this.custService.getCustomerChild(this.customerNo);

    // 取得した結果を各項目にセットする
    this.customerNo = recParent[0].customer_no;
    this.customerCompanyName = recParent[0].company_name;
    this.postalCode = recParent[0].postal_code;
    this.address = recParent[0].address;
    // ExpressionChangedAfterItHasBeenCheckedError対策
    this.changeDetectorRef.detectChanges();
    // 得意先子情報を一覧にセットする
    this.dataSource = recChild;
    if (recChild.length !== 0) {
      // データが存在する場合、取得したリストを保存しておく
      this.dataChildList = recChild;
      // 取得した得意先子番号の最大値を保存
      this.maxChildNo = Math.max(...this.dataChildList.map((p) => p.child_no));
    }
  }

  /**
   * 新規得意先番号の取得
   *
   * 新規得意先番号を取得する。
   */
  private async getNewCustomerNo() {
    // 得意先を取得する
    const rec: any = await this.custService.getNewCustomerNo();
    // 取得した結果をセットする
    this.customerNo = rec.customer_no;
  }

  /**
   * 画面初期化処理
   *
   * 画面項目を初期表示の状態に戻す。
   * ExpressionChangedAfterItHasBeenCheckedError対策のため
   * changeDetectorRefで再描画処理をしている。
   */
  initializeWindow() {
    console.log('@@@@ 画面初期化');
    this.customerCompanyName = '';
    this.address = '';
    this.telNumber = '';
    // ExpressionChangedAfterItHasBeenCheckedError対策
    this.changeDetectorRef.detectChanges();
  }
  /**
   * 得意先子情報一覧のセット
   */
  private async setCustomerChildList(customerChild: any) {

    if (!this.dataChildList) {
      // 得意先子のデータが無い場合、サービスから取得した情報をそのまま追加する
      this.dataChildList = customerChild;
      // 画面に反映する
      this.dataSource = this.dataChildList;
    } else {
      console.log(this.dataChildList);
      // 得意先子情報が既に登録済の場合は、保持している一覧に追加する
      this.dataChildList.push(customerChild[0]);
      // 画面に反映する
      this.dataSource = new MatTableDataSource(this.dataChildList);
    }
  }

}
