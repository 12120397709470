import { Injectable } from '@angular/core';

/**
 * PDFサービス
 */
@Injectable({
  providedIn: 'root'
})
export class PdfService {

  /**
   * PDFサービスのコンストラクター
   * @constructor
   */
  constructor() { }

  /**
   * PDF出力処理
   * @param pdfData PDFデータ
   */
  public async makePdf(pdfData: any) {
    console.log('@@@@ makePdf');

    // Base64形式のPDFデータをBlobオブジェクトに変換する
    var mime_ctype = "application/pdf";
    var blob = await this.toBlob(pdfData, mime_ctype);

    // BlobオブジェクトのPDFファイルをブラウザで開く
    const url = window.URL.createObjectURL(blob);
    window.open(url);
  }

  /**
   * Base64とMIMEコンテンツタイプからBlobオブジェクトを作成する。
   * @param base64 Base64形式の文字列
   * @param mime_ctype MIMEコンテンツタイプ
   */
  private async toBlob(base64, mime_ctype) {
    // Base64からバイナリへ変換
    var bin = atob(base64.replace(/^.*,/, ''));
    var buffer = new Uint8Array(bin.length);
    for (var i = 0; i < bin.length; i++) {
      buffer[i] = bin.charCodeAt(i);
    }

    // Blobを作成
    try {
      var blob = new Blob([buffer.buffer], {
        type: mime_ctype,
      });
      return blob;
    } catch (e) {
      return false;
    }
  }
}
