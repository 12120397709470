import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { DialogComponent } from '../../../common/dialog/dialog.component';
import { AppConfig } from '../../../shared/class/appconfig.class';
import { HeaderTitleService } from '../../../shared/service/headerTitle.service';
import { NavigationService } from '../../../shared/service/navigation.service';
import { NewAdoptService } from '../new-adopt.service';

/**
 * 新規採用情報リストコンポーネント
 */
@Component({
  selector: 'app-new-adopt-list',
  templateUrl: './new-adopt-list.component.html',
  styleUrls: ['./new-adopt-list.component.css']
})
export class NewAdoptListComponent implements OnInit {
  /**
   * 新規採用情報リストコンポーネントのコンストラクター
   * @constructor
   */
  constructor(private title: Title,
              private headerTitle: HeaderTitleService,
              private transitionService: NavigationService,
              private router: Router,
              private matDialog: MatDialog,
              private matSnackBar: MatSnackBar,
              private service: NewAdoptService) {
    // ページタイトルの設定
    this.title.setTitle('新規採用情報リスト | ' + AppConfig.SYSTEM_NANE);
    // ヘッダータイトルの設定
    this.headerTitle.setTitle('新規採用情報リスト');
  }

  /** 取得した新規採用情報一覧を格納 */
  public dataSource = new MatTableDataSource();
  /** 選択した新規採用情報 */
  public selected: any;
  /** チェックボックスの状態 */
  public deleteCheckStatus = false;
  /** サービスに渡す引数 */
  public deleteCheck = 0;
  /** 修正ボタンの状態 */
  public isDisabledModify = true;
  /** 削除ボタンの状態 */
  public isDisabledDelete = true;

  /** 画面表示項目 */
  public displayedColumns: string[] = [
    'id',                   // ID
    'order_no',             // 案件番号
    'order_name',           // 案件名
    'final_item_name',      // 最終商品名
    'size',                 // サイズ
    'weight',               // 重量
    'best_before',          // 賞味期限
    'destination_company',  // 納品先
    'distributor',          // 販売元
    'sales_start',          // 発売開始
    'period',               // 期間
    'production_forecast',  // 生産予想
    'notes',                // 備考
  ];

  /**
   * 初期表示時の処理
   */
  async ngOnInit(): Promise<void> {
    // 修正ボタン、削除ボタン、復元ボタンを非活性
    this.isDisabledModify = true;
    this.isDisabledDelete = true;

    // 選択した新規採用情報をクリア
    this.selected = null;

    // 新規採用情報一覧を取得
    await this.getEmployeeList();
  }

  /**
   * 行選択時の処理
   * @param employee 行選択した新規採用情報
   */
  public selectedRow(employee) {
    // 選択した新規採用情報をセットする
    this.selected = employee;

    if (employee.delete_flag) {
      // 修正ボタン、削除ボタンを活性
      this.isDisabledModify = true;
      this.isDisabledDelete = true;
    } else {
      // 修正ボタン、削除ボタンを非活性
      this.isDisabledModify = false;
      this.isDisabledDelete = false;
    }
  }

  /**
   * 新規登録ボタン押下時の処理
   */
  public onEntry() {
    // 画面遷移の許可
    this.transitionService.canTransition = true;
    // 新規採用情報登録画面に遷移
    this.router.navigate(['/information/new-adopt/entry']);
  }

  /**
   * 修正ボタン押下時の処理
   */
  public onModify() {
    // 案件番号とIDをサービスに保存
    this.service.sendDetailInfomation(this.selected.order_no, this.selected.id);
    // 画面遷移の許可
    this.transitionService.canTransition = true;
    // 新規採用情報修正画面に遷移
    this.router.navigate(['/information/new-adopt/modify']);
  }

  /**
   * 削除ボタン押下時の処理
   *
   * 選択された新規採用情報を論理削除する。
   */
  public onDelete() {
    // 処理開始前に確認ダイアログを表示
    const dialogRef =  this.matDialog.open(DialogComponent, {
      width: '300px',
      height: '300px',
      data: {
        title: '削除確認',
        message: '現在選択されている新規採用情報を削除してよろしいですか？',
      },
      // 画面外のクリックを禁止する設定を追加
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(async(result) => {
      if (!result) {
        // 新規採用情報を削除する
        const rec: any = await this.service.deleteNewAdoptInformationDetail(this.selected.id);

        // 削除に失敗した場合、サーバから返ってきたメッセージを表示する
        if (!rec.success) {
          alert(rec.message);
          return;
        }
        // 削除後処理
        this.matSnackBar.open('削除しました。', '', {
          duration: 1000
        });
        console.log('@@@@ 削除処理が完了しました。');

        // 新規採用情報一覧を再取得する
        this.ngOnInit();
      } else {
        // キャンセル押下時は何もしない
        return;
      }
    });
  }

  /**
   * 新規採用情報一覧を取得する。
   */
  private async getEmployeeList() {
    // 新規採用情報一覧を取得する
    const rec: any = await this.service.getNewAdoptInformationList();
    // 取得した結果をテーブルにセットする
    this.dataSource = rec;
  }
}
