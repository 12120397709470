import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NavigationService } from '../shared/service/navigation.service';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { PasswordChangeComponent } from './password-change/password-change.component';

/**
 * 遷移先の指定
 *
 * path: BaseURL以降のパス<br>
 * component: 遷移先のコンポーネント
 */
@NgModule({
  imports: [RouterModule.forChild([
    {
      path: '',
      component: LoginComponent,
      children: [
        { path: '', redirectTo: 'login', pathMatch: 'full' },
        { path: 'login', component: LoginComponent },
      ]
    },
    {
      path: 'home',
      component: HomeComponent,
      canActivate: [NavigationService],
      canDeactivate: [NavigationService]
    },
    {
      path: 'password-change',
      component: PasswordChangeComponent,
      canActivate: [NavigationService],
      canDeactivate: [NavigationService]
    }
  ])],
  exports: [RouterModule]
})
export class CommonUseRoutingModule { }
