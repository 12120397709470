import { Injectable } from '@angular/core';
import { AppConfig } from '../../shared/class/appconfig.class';
import { HttpClient } from '@angular/common/http';
import { HttpService } from '../../shared/service/http.service';
import { HttpReqOptions } from '../../shared/interface/httpOption.interface';
import { BehaviorSubject } from 'rxjs';

/**
 * 新規採用情報関連サービス
 */
@Injectable({
  providedIn: 'root'
})
export class NewAdoptService {

  // 初期値
  /** 案件番号 */
  public orderNo = new BehaviorSubject<string>('');
  /** ID */
  public id = new BehaviorSubject<number>(0);

  /**
   * 新規採用情報関連サービスのコンストラクター
   * @constructor
   * @param httpService Httpサービス
   * @param http Httpクライアント
   */
  constructor(private httpService: HttpService,
              private http: HttpClient) {
  }

  /**
   * 案件番号とIDを保存
   *
   * 保存した案件番号とIDから新規採用の明細情報を取得する
   * @param orderNo 案件番号
   * @param id ID
   */
  sendDetailInfomation(orderNo: string, id: number) {
    this.orderNo.next(orderNo);
    this.id.next(id);
  }

  /**
   * 案件番号を保存
   *
   * 保存した案件番号から案件の基本情報を取得する
   */
  sendBasicInformation(OrderNo: string) {
    this.orderNo.next(OrderNo);
  }

  /**
   * 新規採用情報一覧の取得
   *
   * 新規採用情報一覧を取得する。
   * HTTPリクエスト種類: GET
   */
  async getNewAdoptInformationList(): Promise<any> {
    // HTTPリクエスト条件設定
    const config: HttpReqOptions = {
      url: AppConfig.apiUrl + 'information/newAdoptList'
    };

    // HTTPリクエスト
    let res: any;
    try {
      res = await this.httpService.send('get', config);
    } catch (err) {
      throw new Error('データ受信失敗');
    }
    // 受信データ処理
    const rec = res.data;
    if (rec && rec.length) {
      console.log('@@@@ データ受信成功');
    }
    return Promise.resolve(rec);
  }

  /**
   * 新規採用情報の削除
   *
   * 案件番号とIDで指定された新規採用情報をテーブルから論理削除する。
   * HTTPリクエスト種類: PUT
   */
  async deleteNewAdoptInformationDetail(id: number): Promise<any> {
    // HTTPリクエスト条件設定
    const config: HttpReqOptions = {
      url: AppConfig.apiUrl + 'information/newAdopt/delete/' + id
    };

    // HTTPリクエスト
    let res: any;
    try {
      res = await this.http.put(config.url, config.body).toPromise();
    } catch (err) {
      throw new Error('データ受信失敗');
    }
    // 受信データ処理
    const rec = res;
    if (rec.data && rec.data.length) {
      console.log('@@@@ データ受信成功');
    }
    return Promise.resolve(rec);
  }
  /**
   * 新規採用情報明細の取得
   *
   * 案件番号とIDで指定された新規採用情報を取得する。
   * HTTPリクエスト種類: GET
   */
  async getNewAdoptInformationDetail(id: number): Promise<any> {
    // HTTPリクエスト条件設定
    const config: HttpReqOptions = {
      url: AppConfig.apiUrl + 'information/newAdoptDetail/' + id
    };

    // HTTPリクエスト
    let res: any;
    try {
      res = await this.httpService.send('get', config);
    } catch (err) {
      throw new Error('データ受信失敗');
    }
    // 受信データ処理
    const rec = res.data;
    if (rec && rec.length) {
      console.log('@@@@ データ受信成功');
    }
    return Promise.resolve(rec);
  }

  /**
   * 案件番号の存在チェック
   *
   * 入力した案件番号が新規採用情報テーブルに存在するか確認する。
   * HTTPリクエスト種類: GET
   */
  async checkOrderNo(orderNo: any): Promise<any> {
    // HTTPリクエスト条件設定
    const config: HttpReqOptions = {
      url: AppConfig.apiUrl + 'information/newAdopt/orderNoCheck/' + orderNo
    };

    // HTTPリクエスト
    let res: any;
    try {
      res = await this.httpService.send('get', config);
    } catch (err) {
      throw new Error('データ受信失敗');
    }
    // 受信データ処理
    const rec = res.data;
    if (rec && rec.length) {
      console.log('@@@@ データ受信成功');
    }
    return Promise.resolve(rec);
  }

  /**
   * 新規採用情報の登録
   *
   * 入力した新規採用情報の明細を登録する
   * HTTPリクエスト種類: POST
   * @param row 登録する新規採用情報の明細
   */
  async insertNewAdoptInformationDetail(row: any): Promise<any> {
    // HTTPリクエスト条件設定
    const config: HttpReqOptions = {
      url: AppConfig.apiUrl + 'information/newAdoptDetail',
      body: row
    };

    // HTTPリクエスト
    let res: any;
    try {
      res = await this.http.post(config.url, config.body).toPromise();
    } catch (err) {
      throw new Error('データ受信失敗');
    }

    // 受信データ処理
    const rec = res;
    if (rec.data && rec.data.length) {
      console.log('@@@@ データ受信成功');
    }
    return Promise.resolve(rec);
  }

  /**
   * 新規採用情報の更新
   *
   * 入力した新規採用情報の明細を更新する
   * HTTPリクエスト種類: PUT
   * @param row 更新する新規採用情報の明細のイメージ
   */
  async updateNewAdoptInformationDetail(row: any): Promise<any> {
    // HTTPリクエスト条件設定
    const config: HttpReqOptions = {
      url: AppConfig.apiUrl + 'information/newAdoptDetail',
      body: row
    };

    // HTTPリクエスト
    let res: any;
    try {
      res = await this.http.put(config.url, config.body).toPromise();
    } catch (err) {
      throw new Error('データ受信失敗');
    }
    // 受信データ処理
    const rec = res;
    if (rec.data && rec.data.length) {
      console.log('@@@@ データ受信成功');
    }
    return Promise.resolve(rec);
  }

  /**
   * 案件番号の取得
   *
   * 案件ステータスが「採用」管理番号を取得する。
   * HTTPリクエスト種類: GET
   * @param bodyData JSONデータ
   */
  async getOrderNo(): Promise<any> {
    // HTTPリクエスト条件設定
    const config: HttpReqOptions = {
      url: AppConfig.apiUrl + 'information/newAdopt/orderNo'
    };

    // HTTPリクエスト
    let res: any;
    try {
      res = await this.http.get(config.url, config.body).toPromise();
    } catch (err) {
      throw new Error('データ受信失敗');
    }
    // 受信データ処理
    const rec = res.data;
    if (rec && rec.length) {
      console.log('@@@@ データ受信成功');
    }
    return Promise.resolve(rec);
  }

  /**
   * 試作依頼情報の取得
   *
   * 案件登録/修正から画面遷移した際に実行。
   * 対象の案件番号に紐づく最新の試作依頼情報を取得する。
   * HTTPリクエスト種類: GET
   * @param bodyData JSONデータ
   */
  async getPrototypeRequest(orderNo: string): Promise<any> {
    // HTTPリクエスト条件設定
    const config: HttpReqOptions = {
      url: AppConfig.apiUrl + 'information/newAdopt/prototypeRequest/' + orderNo
    };

    // HTTPリクエスト
    let res: any;
    try {
      res = await this.http.get(config.url, config.body).toPromise();
    } catch (err) {
      throw new Error('データ受信失敗');
    }
    // 受信データ処理
    const rec = res.data;
    if (rec && rec.length) {
      console.log('@@@@ データ受信成功');
    }
    return Promise.resolve(rec);
  }

}
