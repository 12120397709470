import { Injectable } from '@angular/core';
import { Store, State } from '@ngrx/store';
import { AppState } from '../interface/appstate';
import * as merge from 'deepmerge';

/**
 * Reduxのアクション定義
 */
@Injectable()
export class Actions {

  /**
   * アクション定義のコンストラクター
   * @constructor
   */
  constructor(private ngRedux: Store<AppState>,
              private state: State<AppState>) {
  }

  /** 初期化時のキー名 */
  static INIT_STATE = 'INIT_STATE';
  /** 更新時のキー名 */
  static UPDATE_STATE = 'UPDATE_STATE';

  /**
   * 初期化アクション
   */
  init(): void {
    this.ngRedux.dispatch(
      {type: Actions.INIT_STATE});
  }

  /**
   * 更新アクション
   * @param newValue 値
   */
  update(newValue: AppState): void {
    this.ngRedux.dispatch(
      {type: Actions.UPDATE_STATE, payload: newValue});
  }

  /**
   * 現在の状態オブジェクトのコピーを取得
   */
  getState(): AppState {
    const state = this.state.getValue();
    return merge({}, state, {clone: true});
  }
}
