import { Injectable } from '@angular/core';
import { AppConfig } from '../class/appconfig.class';
import { HttpClient } from '@angular/common/http';
import { HttpReqOptions } from '../interface/httpOption.interface';

/**
 * 排他制御サービス
 */
@Injectable()
export class ExclusiveControlService {

  /**
   * 排他制御サービスのコンストラクター
   * @constructor
   * @param http Httpクライアント
   */
  constructor(private http: HttpClient) { }

  /**
   * 排他ロックを実行するメインメソッド
   *
   * 排他制御テーブルに対象レコードが存在するかチェックを行う
   * 対象レコードが存在しない場合、排他ロックを実行する
   * 対象レコードが存在し、かつ15分が経過している場合、排他ロックを解除し新たに排他ロックを実施する
   * @param functionName 機能名
   * @param targetID 対象ID
   * @param employeeNo 社員番号
   */
  async checkExclusive(functionName: string, targetID: string, employeeNo: string): Promise<any> {
    // サーバに渡すJSON
    const bodyData = {
      employee_no: employeeNo,
      function_name: functionName,
      target_id: targetID
    };

    // 排他レコードの存在チェック
    const rec: any = await this.fetchRecord(functionName, targetID);

    if (rec.data) {
      // 15分経過している場合、排他ロックを解除し新たに排他ロックを実施する
      if (rec.data.hasPassed) {
        console.log('@@@@ 排他ロック削除 → 追加');

        // 排他レコードの削除
        const del: any = await this.deleteRecord(functionName, targetID);
        if (!del.success) {
          alert(del.message);
          return false;
        }

        // 排他レコードの登録
        const entry: any = await this.insertRecord(functionName, targetID);
        if (!entry.success) {
          alert(entry.message);
          return false;
        }
      } else if (rec.data.employee_no === employeeNo) {
        console.log('@@@@ 排他ロック更新');

        // 排他レコードの更新
        const modify: any = await this.updateRecord(functionName, targetID, employeeNo);
        if (!modify.success) {
          alert(modify.message);
          return false;
        }
      } else {
        console.log('@@@@ 他ユーザーが排他ロック中');
        alert(rec.data.employee_name + 'さんが更新中のため、参照モードで表示します。');
        return false;
      }
    } else {
      console.log('@@@@ 排他ロック追加');

      // 排他レコードの登録
      const entry: any = await this.insertRecord(functionName, targetID);
      if (!entry.success) {
        alert(entry.message);
        return false;
      }
    }
    return true;
  }

  /**
   * 排他ロックを取得する
   * @param functionName 機能名
   * @param targetID 対象ID
   */
  async fetchRecord(functionName: string, targetID: string): Promise<any> {
    // HTTPリクエスト条件設定
    const config: HttpReqOptions = {
      url: AppConfig.apiUrl + 'exclusive/fetch',
      body: { function_name: functionName, target_id: targetID }
    };

    // HTTPリクエスト
    let res: any;
    try {
      res = await this.http.put(config.url, config.body).toPromise();
    } catch (err) {
      throw new Error('データ受信失敗');
    }
    // 受信データ処理
    const rec = res;
    if (rec.data && rec.data.length) {
      console.log('@@@@ データ受信成功');
    }
    return Promise.resolve(rec);
  }

  /**
   * 排他ロックを実行する
   * @param functionName 機能名
   * @param targetID 対象ID
   */
  async insertRecord(functionName: string, targetID: string): Promise<any> {
    // HTTPリクエスト条件設定
    const config: HttpReqOptions = {
      url: AppConfig.apiUrl + 'exclusive/regist',
      body: { function_name: functionName, target_id: targetID }
    };

    // HTTPリクエスト
    let res: any;
    try {
      res = await this.http.post(config.url, config.body).toPromise();
    } catch (err) {
      throw new Error('データ受信失敗');
    }
    // 受信データ処理
    const rec = res;
    if (rec.data && rec.data.length) {
      console.log('@@@@ データ受信成功');
    }
    return Promise.resolve(rec);
  }

  /**
   * 排他ロックを更新する
   * @param functionName 機能名
   * @param targetID 対象ID
   * @param employeeNo 社員番号
   */
  async updateRecord(functionName: string, targetID: string, employeeNo: string): Promise<any> {
    // HTTPリクエスト条件設定
    const config: HttpReqOptions = {
      url: AppConfig.apiUrl + 'exclusive/update',
      body: { function_name: functionName, target_id: targetID, employee_no: employeeNo }
    };

    // HTTPリクエスト
    let res: any;
    try {
      res = await this.http.put(config.url, config.body).toPromise();
    } catch (err) {
      throw new Error('データ受信失敗');
    }
    // 受信データ処理
    const rec = res;
    if (rec.data && rec.data.length) {
      console.log('@@@@ データ受信成功');
    }
    return Promise.resolve(rec);
  }

  /**
   * 排他ロックを解除する
   * @param functionName 機能名
   * @param targetID 対象ID
   */
  async deleteRecord(functionName: string, targetID: string): Promise<any> {
    // HTTPリクエスト条件設定
    const config: HttpReqOptions = {
      url: AppConfig.apiUrl + 'exclusive/delete',
      body: { function_name: functionName, target_id: targetID }
    };

    // HTTPリクエスト
    let res: any;
    try {
      res = await this.http.put(config.url, config.body).toPromise();
    } catch (err) {
      throw new Error('データ受信失敗');
    }
    // 受信データ処理
    const rec = res;
    if (rec.data && rec.data.length) {
      console.log('@@@@ データ受信成功');
    }
    return Promise.resolve(rec);
  }
}
