import { Injectable } from '@angular/core';
import { AppConfig } from './../class/appconfig.class';
import { Actions } from './../class/actions';
import * as CryptoJS from 'crypto-js';

/**
 * 暗号化サービス
 */
@Injectable()
export class CryptoService {

  /**
   * 暗号化サービスのコンストラクター
   * @constructor
   */
  constructor(private actions: Actions) {
    console.log('@@@@ cryptoService生成');
  }

  /** 暗号キー */
  private key: string;

  /**
   * 暗号キーの設定
   */
  setCryptpKey(key: string): void {
    this.key = key;
  }

  /**
   * 暗号キー設定の有無確認
   */
  isExistDbKey(): boolean {
    if (!this.key) {
      console.log('暗号キーがありません');
      location.href = AppConfig.loginUrl;
      return false;
    }
    return true;
  }

  /**
   * 暗号化処理
   * @param doc 入力文字列
   * @return string 暗号文字列(Base64形式)
   */
  enCrypt(doc: string) {
    const cryptStr = CryptoJS.AES.encrypt(doc, this.key);
    const base64 = cryptStr.toString();
    return base64;
  }

  /**
   * 復号化処理
   * @param base64 入力文字列(Base64形式)
   * @return string 復号文字列(UTF-8)
   */
  deCrypt(base64: string) {
    const decrypted = CryptoJS.AES.decrypt(base64, this.key);
    const doc = decrypted.toString(CryptoJS.enc.Utf8);
    return doc;
  }
}
