import { Component, OnInit } from '@angular/core';
import { MenuService } from '../../shared/service/menu.service';

/**
 * メニューコンポーネント
 */
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  /** メニュー制御用 */
  menuControl: boolean;

  /** ホーム */
  public homeName = 'ホーム';

  /** 案件：親メニュー */
  public orderMenuName = '案件';

  /** 案件：子メニュー 案件管理 */
  public orderSubMenuName1 = '案件リスト';

  /** 新規採用：親メニュー */
  public informationMenuName = '新規採用';

  /** 新規採用：子メニュー 新規採用情報 */
  public informationSubMenuName1 = '新規採用情報リスト';

  /** メンテナンス：親メニュー */
  public maintenanceMenuName = 'メンテナンス';

  /** メンテナンス：子メニュー 社員マスタ */
  public maintenanceSubMenuName1 = '社員マスタ';
  /** メンテナンス：子メニュー 部署マスタ */
  public maintenanceSubMenuName2 = '部署マスタ';
  /** メンテナンス：子メニュー 得意先マスタ */
  public maintenanceSubMenuName3 = '得意先マスタ';

  /** 設定：親メニュー */
  public settingMenuName = '設定';

  /** 設定：子メニュー */
  public settingSubMenuName1 = 'パスワード変更';

  /** ログアウト */
  public logoutName = 'ログアウト';

  /**
   * メニューコンポーネントのコンストラクター
   * @constructor
   * @param service メニュー関連サービス
   */
  constructor(private service: MenuService) { }

  /**
   * 初期処理の実施
   *
   * メニューの表示/非表示を設定する。
   */
  ngOnInit(): void {
    // メニューの表示/非表示を設定
    this.service.menuControl.subscribe(menu => {
      this.menuControl = menu;
    });
  }

  /**
   * メニューの表示/非表示を切り替える
   * @param id メニューID
   */
  isVisible(id: string) {
    return this.service.isVisible(id);
  }

  /**
   * メニュー選択のイベント処理
   * @param item メニュー名
   */
  onMenuClick(item: string, $event: any) {
    // 選択項目をサービスに渡す
    this.service.onSelectMenu(item);
  }
}
