import { Injectable } from '@angular/core';
import { AppConfig } from '../../shared/class/appconfig.class';
import { HttpClient } from '@angular/common/http';
import { HttpService } from '../../shared/service/http.service';
import { HttpReqOptions } from '../../shared/interface/httpOption.interface';
import { BehaviorSubject } from 'rxjs';

/**
 * 社員メンテナンス関連サービス
 */
@Injectable({
  providedIn: 'root'
})
export class MaintenanceEmployeeService {

  // 初期値
  /** 社員番号 */
  public employeeNo = new BehaviorSubject<string>('');

  /**
   * 社員メンテナンス関連サービスのコンストラクター
   * @constructor
   * @param httpService Httpサービス
   * @param http Httpクライアント
   */
  constructor(private httpService: HttpService,
              private http: HttpClient) {
  }

  /**
   * 社員番号保存
   *
   * 保存した社員番号から社員の明細情報を取得する
   * @param employeeNo 社員番号
   */
  sendDetailInfomation(employeeNo: string) {
    this.employeeNo.next(employeeNo);
  }

  /**
   * 社員マスタ一覧の取得
   *
   * 社員マスタ一覧を取得、削除フラグチェックありの場合は、 削除済み社員も含めて取得する。
   * HTTPリクエスト種類: GET
   */
  async getEmployeeList(deletecheck: any): Promise<any> {
    // HTTPリクエスト条件設定
    const config: HttpReqOptions = {
      url: AppConfig.apiUrl + 'maintenance/employeeList/' + deletecheck
    };

    // HTTPリクエスト
    let res: any;
    try {
      res = await this.httpService.send('get', config);
    } catch (err) {
      throw new Error('データ受信失敗');
    }
    // 受信データ処理
    const rec = res.data;
    if (rec && rec.length) {
      console.log('@@@@ データ受信成功');
    }
    return Promise.resolve(rec);
  }

  /**
   * 社員マスタ明細の取得
   *
   * 社員番号から、社員明細を取得する。
   * HTTPリクエスト種類: GET
   */
  async getEmployeeDetail(employeeno: any): Promise<any> {
    // HTTPリクエスト条件設定
    const config: HttpReqOptions = {
      url: AppConfig.apiUrl + 'maintenance/employee/' + employeeno
    };

    // HTTPリクエスト
    let res: any;
    try {
      res = await this.httpService.send('get', config);
    } catch (err) {
      throw new Error('データ受信失敗');
    }
    // 受信データ処理
    const rec = res.data;
    if (rec && rec.length) {
      console.log('@@@@ データ受信成功');
    }
    return Promise.resolve(rec);
  }

  /**
   * 新規社員番号の取得
   *
   * 社員マスタから最新の社員番号を取得する。
   * HTTPリクエスト種類: GET
   */
  async getNewEmployeeNo(): Promise<any> {
    // HTTPリクエスト条件設定
    const config: HttpReqOptions = {
      url: AppConfig.apiUrl + 'maintenance/employeeDetail/'
    };

    // HTTPリクエスト
    let res: any;
    try {
      res = await this.httpService.send('get', config);
    } catch (err) {
      throw new Error('データ受信失敗');
    }
    // 受信データ処理
    const rec = res.data;
    if (rec && rec.length) {
      console.log('@@@@ データ受信成功');
    }
    return Promise.resolve(rec);
  }

  /**
   * 社員番号の重複チェック
   *
   * 社員番号から社員明マスタを確認し、重複している社員番号が存在するか確認する。
   * HTTPリクエスト種類: GET
   */
  async checkEmployeeNo(employeeno: any): Promise<any> {
    // HTTPリクエスト条件設定
    const config: HttpReqOptions = {
      url: AppConfig.apiUrl + 'maintenance/employeeDetail/' + employeeno
    };

    // HTTPリクエスト
    let res: any;
    try {
      res = await this.httpService.send('get', config);
    } catch (err) {
      throw new Error('データ受信失敗');
    }
    // 受信データ処理
    const rec = res.data;
    if (rec && rec.length) {
      console.log('@@@@ データ受信成功');
    }
    return Promise.resolve(rec);
  }

  /**
   * 社員明細の登録
   *
   * 入力した社員の明細を登録する
   * HTTPリクエスト種類: POST
   * @param row 登録する社員の明細
   */
  async insertEmployeeDetail(row: any): Promise<any> {
    // HTTPリクエスト条件設定
    const config: HttpReqOptions = {
      url: AppConfig.apiUrl + 'maintenance/employeeDetail',
      body: row
    };

    // HTTPリクエスト
    let res: any;
    try {
      res = await this.http.post(config.url, config.body).toPromise();
    } catch (err) {
      throw new Error('データ受信失敗');
    }

    // 受信データ処理
    const rec = res;
    if (rec.data && rec.data.length) {
      console.log('@@@@ データ受信成功');
    }
    return Promise.resolve(rec);
  }

  /**
   * 社員明細の更新
   *
   * 入力した社員の明細を更新する
   * HTTPリクエスト種類: PUT
   * @param row 更新する社員の明細のイメージ
   */
  async updateEmployeeDetail(row: any): Promise<any> {
    // HTTPリクエスト条件設定
    const config: HttpReqOptions = {
      url: AppConfig.apiUrl + 'maintenance/employeeDetail',
      body: row
    };

    // HTTPリクエスト
    let res: any;
    try {
      res = await this.http.put(config.url, config.body).toPromise();
    } catch (err) {
      throw new Error('データ受信失敗');
    }
    // 受信データ処理
    const rec = res;
    if (rec.data && rec.data.length) {
      console.log('@@@@ データ受信成功');
    }
    return Promise.resolve(rec);
  }

  /**
   * 社員マスタ明細の削除
   *
   * 社員番号で指定された社員を社員マスタから論理削除する。
   * HTTPリクエスト種類: PUT
   */
  async deleteEmployeeDetail(employeeno: any): Promise<any> {
    // HTTPリクエスト条件設定
    const config: HttpReqOptions = {
      url: AppConfig.apiUrl + 'maintenance/employeeDetail/delete/' + employeeno
    };

    // HTTPリクエスト
    let res: any;
    try {
      res = await this.http.put(config.url, config.body).toPromise();
    } catch (err) {
      throw new Error('データ受信失敗');
    }
    // 受信データ処理
    const rec = res;
    if (rec.data && rec.data.length) {
      console.log('@@@@ データ受信成功');
    }
    return Promise.resolve(rec);
  }

  /**
   * 社員マスタ明細の復元
   *
   * 社員番号で指定された社員を社員マスタから論理復元する。
   * HTTPリクエスト種類: PUT
   */
  async restoreEmployeeDetail(employeeno: any): Promise<any> {
    // HTTPリクエスト条件設定
    const config: HttpReqOptions = {
      url: AppConfig.apiUrl + 'maintenance/employeeDetail/restore/' + employeeno
    };

    // HTTPリクエスト
    let res: any;
    try {
      res = await this.http.put(config.url, config.body).toPromise();
    } catch (err) {
      throw new Error('データ受信失敗');
    }
    // 受信データ処理
    const rec = res;
    if (rec.data && rec.data.length) {
      console.log('@@@@ データ受信成功');
    }
    return Promise.resolve(rec);
  }

  /**
   * 営業担当者一覧の取得
   *
   * 営業部に所属する社員の社員番号、社員名の一覧を取得する。
   * HTTPリクエスト種類: GET
   */
  async getSalesEmployeeList(): Promise<any> {
    // HTTPリクエスト条件設定
    const config: HttpReqOptions = {
      url: AppConfig.apiUrl + 'maintenance/salesEmployeeList'
    };

    // HTTPリクエスト
    let res: any;
    try {
      res = await this.httpService.send('get', config);
    } catch (err) {
      throw new Error('データ受信失敗');
    }
    // 受信データ処理
    const rec = res.data;
    if (rec && rec.length) {
      console.log('@@@@ データ受信成功');
    }
    return Promise.resolve(rec);
  }
}
