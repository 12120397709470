<div class="container">
  <div fxLayout="row" fxLayout.xs="column">
    <div fxFlex="50">
      <div class="message-container">
        <div class="message-area message-info">
          <mat-icon>account_circle</mat-icon>
          <mat-label class="message">{{accountMessage}}</mat-label>
        </div>
      </div>
    </div>
    <div fxFlex="50"></div>
  </div>
</div>
