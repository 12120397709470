import { ErrorHandler, Injectable } from '@angular/core';
import { AppConfig } from './../class/appconfig.class';
import Bugsnag from '@bugsnag/js';

/**
 * グローバルエラーサービス
 *
 * モジュール全体のエラー発生を捕捉
 */
@Injectable()
export class GlobalErrorService implements ErrorHandler {

  /**
   * グローバルエラーサービスのコンストラクター
   * @constructor
   */
  constructor() {
    console.log('@@@ globalErrorService生成 ');

    // Bugsnagの開始
    Bugsnag.start({ apiKey: AppConfig.BUGSNAG_API_KEY });
  }

  /**
   * エラーハンドラーの実装
   *
   * ダイアログ、コンソール、localStorageにエラーを出力する
   * @param e エラーオブジェクト
   */
  handleError(e: any) {
    console.error('@@@@@グローバルエラー捕捉@@@@@');

    // タイムスタンプの取得
    const dateObj = new Date();
    const timeStamp = dateObj.getTime();
    const timeStr = dateObj.toISOString();
    // スタックトレースを含むエラー情報取得
    const msg = e.name + '\n' + e.message +
      'CallStack:\n' + e.stack.split('\n').slice(2).join('\n');
    // ブラウザのユーザーエージェント取得
    const userAgent = window.navigator.userAgent;

    // コンソールへ出力
    console.error(msg);
    // ログのアップロードのために一時保存
    localStorage.setItem(AppConfig.ERROR_LOG_NAME,
      JSON.stringify({
        timeStamp,
        timeStr,
        message: msg,
        userAgent
      }));

    // Bugsnagに通知
    Bugsnag.notify(e);

    // ログイン画面へ戻る
    window.location.href = AppConfig.loginUrl;
  }
}
