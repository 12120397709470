<div class="container">
  <mat-horizontal-stepper linear #stepper>
    <ng-template matStepperIcon="edit">
      <mat-icon>check</mat-icon>
    </ng-template>

    <!-- 基本情報 -->
    <mat-step label="基本情報">
      <div fxLayout="column">
        <div class="order-container" fxFlex="grow">
          <div fxLayout="row" fxLayout.xs="column">
            <div fxFlex="25">
              <!-- 案件ステータス -->
              <mat-form-field appearance="standard">
                <mat-label>案件ステータス</mat-label>
                <mat-select [(value)]="orderStatus" [formControl]="orderStatusCheck"
                  (selectionChange)="setOrderStatus($event)" required>
                  <mat-option *ngFor="let orderStatus of orderStatusList" [value]="orderStatus.code">
                    {{orderStatus.name}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="orderStatusCheck.hasError('required')">案件ステータスを選択してください。</mat-error>
              </mat-form-field>
            </div>
            <div fxFlex="25">
              <!-- 入力日 -->
              <mat-form-field appearance="standard">
                <mat-label>入力日</mat-label>
                <input matInput
                  class="select-date-label"
                  [formControl]="inputDateCheck"
                  [matDatepicker]="picker"
                  [(ngModel)]="inputDate"
                  (dateChange)="setInputDate($event)"
                  required
                  disabled>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker [disabled]="isReference"></mat-datepicker>
                <mat-error *ngIf="inputDateCheck.hasError('required')">日付を選択してください。</mat-error>
              </mat-form-field>
            </div>
          </div>

          <div fxLayout="row" fxLayout.xs="column">
            <div fxFlex="40">
              <!-- 案件名 -->
              <mat-form-field appearance="standard" class="form-order-name">
                <mat-label>案件名</mat-label>
                <input matInput name="orderName" [(ngModel)]="orderName" [formControl]="orderNameCheck" required>
                <mat-error *ngIf="orderNameCheck.hasError('required')">必須項目です。</mat-error>
              </mat-form-field>
            </div>
            <div fxFlex="60"></div>
          </div>

          <div fxLayout="row" fxLayout.xs="column">
            <div fxFlex="40">
              <!-- 依頼元会社名 -->
              <mat-form-field appearance="standard" class="form-requester-company">
                <mat-label>依頼元会社名</mat-label>
                <input matInput [(ngModel)]="requesterCompany" [formControl]="requesterCompanyCheck"
                  [matAutocomplete]="autoCompany" required>
                <mat-autocomplete autoActiveFirstOption #autoCompany="matAutocomplete">
                  <mat-option *ngFor="let customer of filteredCustomerList | async" [value]="customer.company_name">
                    {{customer.company_name}}
                  </mat-option>
                </mat-autocomplete>
                <mat-error *ngIf="requesterCompanyCheck.hasError('required')">必須項目です。</mat-error>
              </mat-form-field>
            </div>
            <div fxFlex="40">
              <!-- 依頼元所属部課または工場 -->
              <mat-form-field appearance="standard" class="form-requester-department">
                <mat-label>依頼元所属部課または工場</mat-label>
                <input matInput [(ngModel)]="requesterDepartment" [formControl]="requesterDepartmentCheck"
                  [matAutocomplete]="autoDepartment">
                <mat-autocomplete autoActiveFirstOption #autoDepartment="matAutocomplete">
                  <mat-option *ngFor="let customer of filteredCustomerChildList | async" [value]="customer.department_or_factory_name">
                    {{customer.department_or_factory_name}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
            <div fxFlex="20">
              <!-- 依頼元担当者名 -->
              <mat-form-field appearance="standard">
                <mat-label>依頼元担当者名</mat-label>
                <input matInput name="requester-staff" [(ngModel)]="requesterStaff" [disabled]="isReference">
              </mat-form-field>
            </div>
          </div>

          <div fxLayout="row" fxLayout.xs="column">
            <div fxFlex="40">
              <!-- 担当営業 -->
              <mat-form-field appearance="standard">
                <mat-label>担当営業</mat-label>
                <mat-select [(value)]="salesStaff" [formControl]="salesStaffCheck"
                  (selectionChange)="setSalesStaff($event)" required>
                  <mat-option *ngFor="let employee of employeeList" [value]="employee.employee_no">
                    {{employee.employee_name}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="salesStaffCheck.hasError('required')">担当営業を選択してください。</mat-error>
              </mat-form-field>
            </div>
            <div fxFlex="60"></div>
          </div>
        </div>

        <div class="footer-container">
          <div fxLayout="row" fxLayoutAlign="center" class="button-container">
            <!-- 戻る -->
            <button mat-raised-button color="primary" (click)="onCancel()">
              <mat-icon>keyboard_backspace</mat-icon>
              <span class="btn-label">戻る</span>
            </button>

            <!-- 次へ -->
            <button mat-raised-button color="primary" [disabled]="orderFormGroup.invalid" (click)="onNext()" matStepperNext>
              <mat-icon>check</mat-icon>
              <span class="btn-label">次へ</span>
            </button>
          </div>
        </div>
      </div>
    </mat-step>
    <!-- 試作依頼 -->
    <mat-step label="試作依頼">
      <div fxLayout="column">
        <div class="prototype-container" fxFlex="grow">
          <div fxLayout="row" fxLayout.xs="column">
            <div fxFlex="30"></div>
            <div fxFlex="40">
              <!-- 案件名(入力不可) -->
              <mat-form-field appearance="standard" class="display-order-name">
                <mat-label>案件名</mat-label>
                <input matInput [(ngModel)]="orderName" disabled>
              </mat-form-field>
            </div>
            <div fxFlex="30"></div>
          </div>

          <!-- 試作依頼一覧 -->
          <table mat-table [dataSource]="dataSource">
            <!-- 試作番号 -->
            <ng-container matColumnDef="prototype_no">
              <th mat-header-cell *matHeaderCellDef class="column-width-1">試作番号</th>
              <td mat-cell *matCellDef="let request"> {{request.prototype_no}} </td>
            </ng-container>

            <!-- 試作区分 -->
            <ng-container matColumnDef="prototype_kind">
              <th mat-header-cell *matHeaderCellDef class="column-width-3">試作区分</th>
              <td mat-cell *matCellDef="let request"> {{request.prototype_kind_name}} </td>
            </ng-container>

            <!-- 試作ステータス -->
            <ng-container matColumnDef="prototype_status">
              <th mat-header-cell *matHeaderCellDef class="column-width-3">試作ステータス</th>
              <td mat-cell *matCellDef="let request"> {{request.prototype_status_name}} </td>
            </ng-container>

            <!-- 納期 -->
            <ng-container matColumnDef="deadline">
              <th mat-header-cell *matHeaderCellDef class="column-width-1">納期</th>
              <td mat-cell *matCellDef="let request"> {{request.deadline | date: 'y/M/d'}} </td>
            </ng-container>

            <!-- 発送数量 -->
            <ng-container matColumnDef="shipping_quantity">
              <th mat-header-cell *matHeaderCellDef class="column-width-1">発送数量</th>
              <td mat-cell *matCellDef="let request"> {{request.shipping_quantity}} </td>
            </ng-container>

            <!-- サイズ -->
            <ng-container matColumnDef="size">
              <th mat-header-cell *matHeaderCellDef class="column-width-1">サイズ</th>
              <td mat-cell *matCellDef="let request"> {{request.size}} </td>
            </ng-container>

            <!-- 重量 -->
            <ng-container matColumnDef="weight">
              <th mat-header-cell *matHeaderCellDef class="column-width-1">重量</th>
              <td mat-cell *matCellDef="let request"> {{request.weight}} </td>
            </ng-container>

            <!-- LOT(賞味期限) -->
            <ng-container matColumnDef="production_lot">
              <th mat-header-cell *matHeaderCellDef class="column-width-1">LOT(賞味期限)</th>
              <td mat-cell *matCellDef="let request"> {{request.production_lot}} </td>
            </ng-container>

            <!-- 発送日 -->
            <ng-container matColumnDef="shipping_date">
              <th mat-header-cell *matHeaderCellDef class="column-width-1">発送日</th>
              <td mat-cell *matCellDef="let request">
                <span *ngFor="let shipping of request.shippings" class="span-height">
                  {{shipping.shipping_date | date: 'y/M/d'}}<br>
                </span>
              </td>
            </ng-container>

            <!-- 送り状 -->
            <ng-container matColumnDef="invoice">
              <th mat-header-cell *matHeaderCellDef class="column-width-1">送り状</th>
              <td mat-cell *matCellDef="let request">
                <span *ngFor="let shipping of request.shippings" class="span-height">
                  {{shipping.invoice}}<br>
                </span>
              </td>
            </ng-container>

            <!-- 宛て名 -->
            <ng-container matColumnDef="destination">
              <th mat-header-cell *matHeaderCellDef class="column-width-1">宛て名</th>
              <td mat-cell *matCellDef="let request">
                <span *ngFor="let shipping of request.shippings" class="span-height">
                  {{shipping.destination}}<br>
                </span>
              </td>
            </ng-container>

            <!-- 依頼内容 -->
            <ng-container matColumnDef="request_content">
              <th mat-header-cell *matHeaderCellDef class="column-width-1">依頼内容</th>
              <td mat-cell *matCellDef="let request"> {{request.request_content}} </td>
            </ng-container>

            <!-- 備考 -->
            <ng-container matColumnDef="notes">
              <th mat-header-cell *matHeaderCellDef class="column-width-1">備考</th>
              <td mat-cell *matCellDef="let request"> {{request.notes}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let request; columns: displayedColumns;"
              (click)="selectedRow(request)"
              [ngClass]="{'selected': selected == request}">
            </tr>
          </table>
        </div>

        <div class="footer-container">
          <div fxLayout="row wrap" fxLayoutAlign="center" fxLayoutAlign.xs="space-between" class="button-container button-container-xs">
            <!-- 追加 -->
            <button mat-raised-button color="primary" (click)="onAdd()" [disabled]="isDisabledAdd">
              <mat-icon>add_circle</mat-icon>
              <span class="btn-label">追加</span>
            </button>
            <!-- 修正 -->
            <button mat-raised-button color="primary" (click)="onModify()" [disabled]="isDisabledModify">
              <mat-icon>edit</mat-icon>
              <span class="btn-label">{{modifyButtonName}}</span>
            </button>
            <!-- コピー -->
            <button mat-raised-button color="primary" (click)="onCopy()" [disabled]="isDisabledCopy">
              <mat-icon>content_copy</mat-icon>
              <span class="btn-label">コピー</span>
            </button>
            <!-- 削除 -->
            <button mat-raised-button color="primary" (click)="onDelete()" [disabled]="isDisabledDelete">
              <mat-icon>cancel</mat-icon>
              <span class="btn-label">削除</span>
            </button>
          </div>

          <div fxLayout="row" fxLayoutAlign="center" class="button-container">
            <!-- 戻る -->
            <button mat-raised-button color="primary" (click)="onBack()" matStepperPrevious>
              <mat-icon>keyboard_backspace</mat-icon>
              <span class="btn-label">戻る</span>
            </button>
            <!-- 印刷 -->
            <button mat-raised-button color="primary" (click)="onPrint()" [disabled]="isDisabledPrint">
              <mat-icon>print</mat-icon>
              <span class="btn-label">印刷</span>
            </button>
            <!-- 次へ -->
            <button mat-raised-button color="primary" (click)="onNext()" matStepperNext>
              <mat-icon>check</mat-icon>
              <span class="btn-label">次へ</span>
            </button>
          </div>
        </div>
      </div>
    </mat-step>
    <!-- 完了 -->
    <mat-step label="完了">
      <div class="done-container" fxFlex="grow">
        <div fxLayout="row" fxLayout.xs="column">
          <div fxFlex="30"></div>
          <div fxFlex="40">
            <!-- 案件名(入力不可) -->
            <mat-form-field appearance="standard" class="display-order-name">
              <mat-label>案件名</mat-label>
              <input matInput [(ngModel)]="orderName" disabled>
            </mat-form-field>

            <!-- 補足 -->
            <mat-form-field appearance="standard" class="form-supplementary">
              <mat-label>補足</mat-label>
              <textarea matInput name="supplementary" [(ngModel)]="supplementary" [disabled]="isDisabledSupplementary"></textarea>
              <mat-hint>案件ステータスが「採用」の場合のみ記入可</mat-hint>
            </mat-form-field>
          </div>
          <div fxFlex="30"></div>
        </div>

        <div fxLayout="row" fxLayout.xs="column">
          <div fxFlex="15"></div>
          <div fxFlex="70" ngClass="mail-container" ngClass.xs="mail-container-xs">
            <!-- メール通知先 -->
            <mat-label>メール通知先</mat-label>
            <mat-selection-list #mappingDepartment [disabled]="isReference">
              <mat-list-option *ngFor="let department of departmentList"
                [value]="department.department_no" [selected]="department.selected"
                class="mat-list-department">
                <span>{{department.department_name}}</span>
              </mat-list-option>
            </mat-selection-list>
          </div>
          <div fxFlex="15"></div>
        </div>
      </div>

      <div id="footer-container">
        <div fxLayout="row" fxLayoutAlign="center" class="button-container">
          <!-- 戻る -->
          <button mat-raised-button color="primary" (click)="onBack()" matStepperPrevious>
            <mat-icon>keyboard_backspace</mat-icon>
            <span class="btn-label">戻る</span>
          </button>
          <!-- 完了 -->
          <button mat-raised-button color="primary" *ngIf="!isReference" (click)="onRegist(mappingDepartment.selectedOptions.selected)">
            <mat-icon>check</mat-icon>
            <span class="btn-label">完了</span>
          </button>
        </div>
      </div>
    </mat-step>
  </mat-horizontal-stepper>
</div>
