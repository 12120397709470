import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanDeactivate, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Actions } from '../class/actions';
import { AppConfig } from '../class/appconfig.class';
import { CryptoService } from './crypto.service';

/**
 * ナビゲーションサービス
 *
 * ルータの画面遷移処理のフック
 */
@Injectable()
export class NavigationService implements CanActivate, CanDeactivate<any> {

  /**
   * ナビゲーションサービスのコンストラクター
   * @constructor
   */
  constructor(private actions: Actions,
              private cryptoService: CryptoService) {
    console.log('@@@@ NavigationService生成');
  }

  /** 現在のURL */
  currentUrl = '';
  /** 画面遷移許可フラグ */
  canTransition = false;

  /**
   * 表示中の画面を閉じる前の処理
   */
  canDeactivate(component: any,
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean | Observable<any> {
    console.log('@@@@ 遷移元Url:' + state.url);

    if (!this.canTransition) {
      alert('画面遷移は許可されていません');
      history.pushState(null, null, this.currentUrl);
      return false;
    }
    this.canTransition = false;

    return true;
  }

  /**
   * 新たな画面を開く前の処理
   */
  canActivate(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    console.log('@@@@ 遷移先Url:' + state.url);

    // 暗号キーが無効の時はログイン画面へ遷移
    if (!this.cryptoService.isExistDbKey()) {
      console.log('暗号キーが無効です');
      location.href = AppConfig.loginUrl;
      return false;
    }

    // 新しいURLパスをReduxへ通知
    this.actions.update({
      url: state.url
    });
    return true;
  }
}
