import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NavigationService } from '../shared/service/navigation.service';
import { EmployeeListComponent } from './employee/list/employee-list.component';
import { EmployeeEntryModifyComponent } from './employee/entry-modify/employee-entry-modify.component';
import { DepartmentListComponent } from './department/list/department-list.component';
import { CustomerListComponent } from './customer/list/customer-list.component';
import { CustomerEntryModifyComponent } from './customer/entry-modify/customer-entry-modify.component';

/**
 * 遷移先の指定
 *
 * path: BaseURL以降のパス<br>
 * component: 遷移先のコンポーネント
 */
@NgModule({
  imports: [RouterModule.forChild([
    {
      path: 'maintenance/employee',
      component: EmployeeListComponent,
      canActivate: [NavigationService],
      canDeactivate: [NavigationService],
    },
    {
      path: 'maintenance/employee/entry',
      component: EmployeeEntryModifyComponent,
      canActivate: [NavigationService],
      canDeactivate: [NavigationService],
      data: {
        mode: '1'
      }
    },
    {
      path: 'maintenance/employee/modify',
      component: EmployeeEntryModifyComponent,
      canActivate: [NavigationService],
      canDeactivate: [NavigationService],
      data: {
        mode: '2'
      }
    },
    {
      path: 'maintenance/department',
      component: DepartmentListComponent,
      canActivate: [NavigationService],
      canDeactivate: [NavigationService],
    },
    {
      path: 'maintenance/customer',
      component: CustomerListComponent,
      canActivate: [NavigationService],
      canDeactivate: [NavigationService],
    },
    {
      path: 'maintenance/customer/entry',
      component: CustomerEntryModifyComponent,
      canActivate: [NavigationService],
      canDeactivate: [NavigationService],
      data: {
        mode: '1'
      }
    },
    {
      path: 'maintenance/customer/modify',
      component: CustomerEntryModifyComponent,
      canActivate: [NavigationService],
      canDeactivate: [NavigationService],
      data: {
        mode: '2'
      }
    }
  ])],
  exports: [RouterModule]
})
export class MaintenanceRoutingModule { }
