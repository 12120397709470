import { Location } from '@angular/common';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MaintenanceCustomerService } from '../../../maintenance/customer/maintenance-customer.service';
import { AppConfig } from '../../../shared/class/appconfig.class';
import { ExclusiveControlService } from '../../../shared/service/exclusive.service';
import { HeaderTitleService } from '../../../shared/service/headerTitle.service';
import { MenuService } from '../../../shared/service/menu.service';
import { NavigationService } from '../../../shared/service/navigation.service';
import { NewAdoptService } from '../new-adopt.service';

/**
 * 新規採用情報登録・修正コンポーネント
 */
@Component({
  selector: 'app-new-adopt-entry-modify',
  templateUrl: './new-adopt-entry-modify.component.html',
  styleUrls: ['./new-adopt-entry-modify.component.css']
})
export class NewAdoptEntryModifyComponent implements OnInit, OnDestroy {

  // #region 変数設定や初期値

  // 初期値
  /** モード(1:登録、2:修正) */
  public mode: string;
  /** 社員番号 */
  public employeeNo = '';
  /** ID(修正用) */
  public id: number;
  /** 案件番号 */
  public orderNo: string;
  /** 元案件番号 */
  public beforeOrderNo: string;
  /** 最終商品名 */
  public finalItemName: string;
  /** サイズ */
  public size: string;
  /** 重量 */
  public weight: string;
  /** 賞味期限 */
  public bestBefore: string;
  /** 納品先会社 */
  public destinationCompany: string;
  /** 納品先所属部課または工場 */
  public destinationDepartment: string;
  /** 販売元 */
  public distributor: string;
  /** 発売開始 */
  public salesStart: string;
  /** 期間 */
  public period: string;
  /** 生産予想 */
  public productionForecast: string;
  /** 備考 */
  public notes: string;
  /** 登録する新規採用情報の明細 */
  public newAdoptInformationDetail: any;
  /** 登録ボタンの名称 */
  public registButtonName = '';

  /** オートコンプリート用の配列：案件番号 */
  public orderNoList: any = [];
  /** オートコンプリート用の配列：納品先会社 */
  public customerList: any = [];
  /** オートコンプリート一時保存用の配列：納品先所属部課または工場 */
  public customerChildListTemp: any = [];
  /** オートコンプリート用の配列：納品先所属部課または工場 */
  public customerChildList: any = [];

  /** 参照モードかどうか */
  public isReference = false;

  /** 機能名 */
  public static FUNCTION_NAME_MODIFY = '新規採用情報修正';

  // #endregion

  // #region コンストラクター、FormControl等

  /**
   * 新規採用情報登録・修正コンポーネントのコンストラクター
   * @constructor
   */
  constructor(private title: Title,
              private headerTitle: HeaderTitleService,
              private transitionService: NavigationService,
              private router: Router,
              private route: ActivatedRoute,
              private location: Location,
              private matSnackBar: MatSnackBar,
              private changeDetectorRef: ChangeDetectorRef,
              private service: NewAdoptService,
              private custService: MaintenanceCustomerService,
              private menuService: MenuService,
              private excService: ExclusiveControlService) {
    // モードによって表示する文言を変更する
    this.route.data.subscribe(data => this.mode = data.mode);
    if (this.mode === '1') {
      // ページタイトルの設定
      this.title.setTitle('新規採用情報登録 | ' + AppConfig.SYSTEM_NANE);
      // ヘッダータイトルの設定
      this.headerTitle.setTitle('新規採用情報登録');
      // ボタン名称の設定
      this.registButtonName = '登録';
    } else if (this.mode === '2') {
      // ページタイトルの設定
      this.title.setTitle('新規採用情報修正 | ' + AppConfig.SYSTEM_NANE);
      // ヘッダータイトルの設定
      this.headerTitle.setTitle('新規採用情報修正');
      // ボタン名称の設定
      this.registButtonName = '修正';
    }
  }

  /** チェック：案件番号 */
  public orderNoCheck = new FormControl();
  /** フォーム(AutoComplete用)：納品先会社 */
  public destinationCompanyCheck = new FormControl();
  /** フォーム(AutoComplete用)：納品先所属部課または工場 */
  public destinationDepartmentCheck = new FormControl();

  /** フィルタリングされる案件番号一覧 */
  public filteredOrderNo: Observable<any>;
  /** フィルタリングされる得意先親マスタ一覧 */
  public filteredCustomerList: Observable<any>;
  /** フィルタリングされる得意先子マスタ一覧 */
  public filteredCustomerChildList: Observable<any>;

  // #endregion

  // #region 初期処理

  /**
   * 初期処理
   *
   * 画面タイトル、ボタン名称の設定
   * 修正の場合は新規採用情報を取得し設定する。
   */
  async ngOnInit(): Promise<void> {

    // 画面の初期化
    this.initializeWindow();

    // オートコンプリートの情報取得
    this.setAutoComplateInformation();

    // 社員番号の取得
    this.employeeNo = this.menuService.getEmployeeNo();

    if (this.mode === '1') {
      // サービスから案件番号を取得
      this.service.orderNo.subscribe(async orderNo => {
        this.orderNo = orderNo;
      });
      if (this.orderNo !== '') {
        // 案件番号が空でない場合、最新の試作依頼情報を取得
        const rec = await this.service.getPrototypeRequest(this.orderNo);

        if (rec !== null) {
          // 試作依頼情報が存在する場合、取得した試作依頼情報からサイズと重量をセットする
          this.size = rec.size;
          this.weight = rec.weight;
        }
      }
    } else if (this.mode === '2') {
      // 修正モードであれば、サービスから案件番号とIDを取得
      this.service.orderNo.subscribe(async orderNo => {
        this.orderNo = orderNo;
        this.beforeOrderNo = orderNo;
      });
      this.service.id.subscribe(async id => {
        this.id = id;
      });
      // 該当新規採用情報の検索
      await this.getNewAdoptInformationDetail();

      // 排他チェック
      const check: any = await this.excService.checkExclusive(NewAdoptEntryModifyComponent.FUNCTION_NAME_MODIFY, this.id.toString(), this.employeeNo);
      if (!check) {
        // 他ユーザーが排他ロックをしているため、参照モードで表示する
        this.isReference = true;
        this.orderNoCheck = new FormControl({ value: this.orderNo, disabled: this.isReference });
        this.destinationCompanyCheck = new FormControl({ value: this.destinationCompany, disabled: this.isReference });
        this.destinationDepartmentCheck = new FormControl({ value: this.destinationDepartment, disabled: this.isReference });
      }
    }
  }

  /**
   * 画面初期化処理
   *
   * 画面項目を初期表示の状態に戻す。
   * ExpressionChangedAfterItHasBeenCheckedError対策のため
   * changeDetectorRefで再描画処理をしている。
   */
  private initializeWindow() {
    console.log('@@@@ 画面初期化');
    this.orderNo = '';
    this.finalItemName = '';
    this.size = '';
    this.weight = '';
    this.bestBefore = '';
    this.destinationCompany = '';
    this.destinationDepartment = '';
    this.distributor = '';
    this.salesStart = '';
    this.period = '';
    this.productionForecast = '';
    this.notes = '';
    // ExpressionChangedAfterItHasBeenCheckedError対策
    this.changeDetectorRef.detectChanges();
  }

  /**
   * オートコンプリートに設定する情報の取得および設定
   */
  private async setAutoComplateInformation() {
    // 得意先親マスタの一覧取得
    const rec1: any = await this.custService.getCustomerParentList();
    // 得意先子マスタの一覧取得
    const rec2: any = await this.custService.getCustomerChildList();
    // 案件ステータスが「採用」の案件番号取得
    const rec3 = await this.service.getOrderNo();
    // 取得した結果をセットする
    this.customerList = rec1;
    this.customerChildList = rec2;
    this.orderNoList = rec3;

    // 案件番号のオートコンプリートを設定
    this.filteredOrderNo = this.orderNoCheck.valueChanges.pipe(
      startWith(this.orderNo),
      map(value => (value) ? this.orderNoList
        .filter(v => v.order_no.includes(value)) : this.orderNoList)
    );

    // 納品先会社のオートコンプリートを設定
    this.filteredCustomerList = this.destinationCompanyCheck.valueChanges.pipe(
      startWith(this.destinationCompany),
      map(value => {
        const rec = this.customerList.filter(v => v.company_name === value);
        const customerNo = (rec && rec.length) ? rec[0].customer_no : '';
        this.customerChildListTemp = this.customerChildList.filter(v => v.customer_no === customerNo);
        this.destinationDepartmentCheck.setValue(this.destinationDepartment);
        return (value) ? this.customerList.filter(v => v.company_name.includes(value)) : this.customerList;
      })
    );

    // 納品先所属部課または工場のオートコンプリートを設定
    this.filteredCustomerChildList = this.destinationDepartmentCheck.valueChanges.pipe(
      startWith(this.destinationDepartment),
      map(value => (value) ? this.customerChildListTemp
        .filter(v => v.department_or_factory_name.includes(value)) : this.customerChildListTemp)
    );
  }

  /**
   * 新規採用情報明細の取得
   *
   * 新規採用情報の明細を取得し、各項目に設定する。
   */
  private async getNewAdoptInformationDetail() {
    // 新規採用情報を取得する
    const rec: any = await this.service.getNewAdoptInformationDetail(this.id);

    // 取得した結果を各項目にセットする
    this.finalItemName = rec.final_item_name;
    this.size = rec.size;
    this.weight = rec.weight;
    this.bestBefore = rec.best_before;
    this.destinationCompany = rec.destination_company;
    this.destinationDepartment = rec.destination_department_or_factory;
    this.distributor = rec.distributor;
    this.salesStart = rec.sales_start;
    this.period = rec.period;
    this.productionForecast = rec.production_forecast;
    this.notes = rec.notes;

    // ExpressionChangedAfterItHasBeenCheckedError対策
    this.changeDetectorRef.detectChanges();
  }

  // #endregion

  // #region ボタン押下時の処理

  /**
   * 戻るボタン押下時の処理
   *
   * 新規採用情報リスト、案件登録から遷移してくるため、location.back() ではなく router を利用する。
   */
  async onCancel() {
    // 画面遷移の許可
    this.transitionService.canTransition = true;
    // 新規採用情報リストに遷移する
    this.router.navigate(['/information/new-adopt']);
  }

  /**
   * 登録・修正ボタン押下時の処理
   *
   * 入力チェックと案件番号の存在チェックを行い、
   * 問題が無い場合は、新規採用情報テーブルへ登録・更新を行う。
   */
  async onRegist(): Promise<void> {

    // 画面項目に何も入力をしていない場合アラートを表示し後続処理
    if (!this.orderNo && !this.finalItemName && !this.size && !this.weight &&
      !this.bestBefore && !this.destinationCompany && !this.destinationDepartment && !this.distributor &&
      !this.salesStart && !this.period && !this.productionForecast && !this.notes) {
      alert('画面項目に入力がされていないため、登録・修正ができません。');
      return;
    }

    // 案件番号に入力がある場合のみ案件番号存在チェックをする
    if (this.orderNo) {
      const rec: any = await this.service.checkOrderNo(this.orderNo);
      if (!rec) {
        console.log('案件番号が存在しないかステータスが「採用」以外のため処理中断');
        alert('入力した案件番号は案件ステータスが「採用」以外か、存在しません。\n案件番号を変更してください。');
        return;
      }
    }

    // モード毎に登録・修正の処理を実行する
    if (this.mode === '1') {
      console.log('@@@@ 登録ボタン押下');

      // サーバに渡すJSON
      this.newAdoptInformationDetail = {
        id: this.id,
        order_no: this.orderNo,
        final_item_name: this.finalItemName,
        size: this.size,
        weight: this.weight,
        best_before: this.bestBefore,
        destination_company: this.destinationCompany,
        destination_department_or_factory: this.destinationDepartment,
        distributor: this.distributor,
        sales_start: this.salesStart,
        period: this.period,
        production_forecast: this.productionForecast,
        notes: this.notes
      };

      // 新規採用情報明細を登録
      const entry = await this.service.insertNewAdoptInformationDetail(this.newAdoptInformationDetail);
      // 登録に失敗した場合、サーバから返ってきたメッセージを表示する
      if (!entry.success) {
        alert(entry.message);
        return;
      }
      // 登録後処理
      this.matSnackBar.open('登録しました。', '', {
        duration: 1000
      });
      console.log('@@@@ 登録処理が完了しました。');
    } else if (this.mode === '2') {
      console.log('@@@@ 修正ボタン押下');

      // サーバに渡すJSON
      this.newAdoptInformationDetail = {
        id: this.id,
        before_order_no: this.beforeOrderNo,
        order_no: this.orderNo,
        final_item_name: this.finalItemName,
        size: this.size,
        weight: this.weight,
        best_before: this.bestBefore,
        destination_company: this.destinationCompany,
        destination_department_or_factory: this.destinationDepartment,
        distributor: this.distributor,
        sales_start: this.salesStart,
        period: this.period,
        production_forecast: this.productionForecast,
        notes: this.notes
      };

      // 新規採用情報明細を修正
      const modify = await this.service.updateNewAdoptInformationDetail(this.newAdoptInformationDetail);
      // 登録に失敗した場合、サーバから返ってきたメッセージを表示する
      if (!modify.success) {
        alert(modify.message);
        return;
      }
      // 修正後処理
      this.matSnackBar.open('修正しました。', '', {
        duration: 1000
      });
      console.log('@@@@ 修正処理が完了しました。');
    }

    // 画面遷移の許可
    this.transitionService.canTransition = true;
    // 新規採用情報リストに遷移する
    this.router.navigate(['/information/new-adopt']);
  }

  // #endregion

  // #region 終了時の処理

  /**
   * 終了時処理
   * 画面が閉じる際に案件番号の値を初期値にする。
   */
  async ngOnDestroy() {
    // サービスに保存している案件番号を初期値にする
    this.service.sendBasicInformation('');

    if (this.mode === '2') {
      // 排他ロックを解除
      console.log('@@@@ 排他ロック削除');
      const del: any = await this.excService.deleteRecord(NewAdoptEntryModifyComponent.FUNCTION_NAME_MODIFY, this.id.toString());
      if (!del.success) alert(del.message);
    }
  }

  // #endregion

}
