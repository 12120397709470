<h2 mat-dialog-title>{{data.title}}</h2>
<mat-dialog-content>
  <div class="dialog-container">
    <div fxLayout="row" fxLayout.xs="column">
      <div fxFlex="30">
        <!-- 試作区分 -->
        <mat-form-field appearance="standard" class="form-prototype-kind">
          <mat-label>試作区分</mat-label>
          <mat-select [(value)]="prototypeKind" [formControl]="prototypeKindCheck"
            (selectionChange)="setPrototypeKind($event.value)" required>
            <mat-option *ngFor="let prototypeKind of prototypeKindList" [value]="prototypeKind.code">
              {{prototypeKind.name}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="prototypeKindCheck.hasError('required')">試作区分を選択してください。</mat-error>
        </mat-form-field>
      </div>
      <div fxFlex="30">
        <!-- 試作ステータス -->
        <mat-form-field appearance="standard" class="form-prototype-status">
          <mat-label>試作ステータス</mat-label>
          <mat-select [(value)]="prototypeStatus" [formControl]="prototypeStatusCheck"
            (selectionChange)="setPrototypeStatus($event)" required>
            <mat-option *ngFor="let prototypeStatus of filteredPrototypeStatusList | async" [value]="prototypeStatus.code">
              {{prototypeStatus.name}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="prototypeStatusCheck.hasError('required')">試作ステータスを選択してください。</mat-error>
        </mat-form-field>
      </div>
    </div>
    <div fxLayout="row" fxLayout.xs="column">
      <div fxFlex="25">
        <!-- 費用 -->
        <mat-form-field appearance="standard">
          <mat-label>費用</mat-label>
          <mat-select [(value)]="cost" (selectionChange)="setCost($event)" [disabled]="isReference">
            <mat-option *ngFor="let cost of costList" [value]="cost.code">
              {{cost.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div fxFlex="25">
        <!-- サイズ -->
        <mat-form-field appearance="standard">
          <mat-label>サイズ</mat-label>
          <input matInput name="size" [(ngModel)]="size" [disabled]="isReference">
        </mat-form-field>
      </div>
      <div fxFlex="25">
        <!-- 重量 -->
        <mat-form-field appearance="standard">
          <mat-label>重量</mat-label>
          <input matInput name="weight" [(ngModel)]="weight" [disabled]="isReference">
        </mat-form-field>
      </div>
      <div fxFlex="25"></div>
    </div>
    <div fxLayout="row" fxLayout.xs="column">
      <div fxFlex="40">
        <!-- 納期 -->
        <mat-form-field appearance="standard" class="form-deadline-date">
          <mat-label>納期</mat-label>
          <input matInput
            class="select-date-label"
            [matDatepicker]="deadlinePicker"
            [(ngModel)]="deadlineDate"
            disabled>
          <mat-datepicker-toggle matSuffix [for]="deadlinePicker"></mat-datepicker-toggle>
          <mat-datepicker #deadlinePicker [disabled]="isReference"></mat-datepicker>
        </mat-form-field>

        <!-- 納期の調整 -->
        <span class="checkbox-label" style="margin-left: 35px;">調整可</span>
        <mat-checkbox [(ngModel)]="isDeadlineAdjustment" [disabled]="isReference"></mat-checkbox>
      </div>
      <div fxFlex="30">
        <!-- 納品方法 -->
        <mat-form-field appearance="standard">
          <mat-label>納品方法</mat-label>
          <mat-select [(value)]="deliveryMethod" [formControl]="deliveryMethodCheck"
            (selectionChange)="setDeliveryMethod($event)" required>
            <mat-option *ngFor="let deliveryMethod of deliveryMethodList" [value]="deliveryMethod.code">
              {{deliveryMethod.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div fxLayout="row" fxLayout.xs="column">
      <div fxFlex="40">
        <!-- 発送数量 -->
        <mat-form-field appearance="standard" class="form-shipping-quantity">
          <mat-label>発送数量</mat-label>
          <input matInput [(ngModel)]="shippingQuantity" [formControl]="shippingQuantityCheck" required>
          <mat-error *ngIf="shippingQuantityCheck.hasError('required')">必須項目です。</mat-error>
        </mat-form-field>

        <!-- 発送数量の調整 -->
        <span class="checkbox-label" style="margin-left: 35px;">調整可</span>
        <mat-checkbox [(ngModel)]="isShippingAdjustment" [disabled]="isReference"></mat-checkbox>
      </div>
    </div>
    <div fxLayout="row" fxLayout.xs="column">
      <div fxFlex="25">
        <!-- 依頼日 -->
        <mat-form-field appearance="standard" class="form-request-date">
          <mat-label>依頼日</mat-label>
          <input matInput
            class="select-date-label"
            [formControl]="requestDateCheck"
            [matDatepicker]="requestPicker"
            [(ngModel)]="requestDate"
            required
            disabled>
          <mat-datepicker-toggle matSuffix [for]="requestPicker"></mat-datepicker-toggle>
          <mat-datepicker #requestPicker [disabled]="isReference"></mat-datepicker>
          <mat-error *ngIf="requestDateCheck.hasError('required')">日付を選択してください。</mat-error>
        </mat-form-field>
      </div>
      <div fxFlex="25">
        <!-- LOT(賞味期限) -->
        <mat-form-field appearance="standard">
          <mat-label class="text-danger text-bolder">LOT(賞味期限)</mat-label>
          <input matInput [(ngModel)]="productionLot" [disabled]="isReference">
        </mat-form-field>
      </div>
    </div>
    <div fxLayout="column">
      <div fxFlex>
        <!-- 発送情報一覧 -->
        <table mat-table [dataSource]="dataSource">
          <!-- 発送日 -->
          <ng-container matColumnDef="shipping_date">
            <th mat-header-cell *matHeaderCellDef class="column-width-110">
              <mat-label class="text-warning text-bolder">発送日</mat-label>
            </th>
            <td mat-cell *matCellDef="let shipping">
              <mat-form-field appearance="standard" class="form-in-table">
                <input matInput [(ngModel)]="shipping.shipping_date" [matDatepicker]="picker" disabled>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker [disabled]="isReference"></mat-datepicker>
              </mat-form-field>
            </td>
          </ng-container>

          <!-- 送り状 -->
          <ng-container matColumnDef="invoice">
            <th mat-header-cell *matHeaderCellDef class="column-width-110">
              <mat-label class="text-warning text-bolder">送り状</mat-label>
            </th>
            <td mat-cell *matCellDef="let shipping">
              <mat-form-field appearance="standard" class="form-in-table">
                <input matInput class="input-in-table" [(ngModel)]="shipping.invoice" [disabled]="isReference">
              </mat-form-field>
            </td>
          </ng-container>

          <!-- 宛て名会社 -->
          <ng-container matColumnDef="destination_company_name">
            <th mat-header-cell *matHeaderCellDef class="column-width-200">宛て名会社</th>
            <td mat-cell *matCellDef="let shipping">
              <mat-form-field appearance="standard" class="form-in-table">
                <input matInput class="input-in-table" [(ngModel)]="shipping.destination_company_name"
                  [formControl]="shipping.destinationCompanyCheck" [matAutocomplete]="autoCompany">
                <mat-autocomplete autoActiveFirstOption #autoCompany="matAutocomplete">
                  <mat-option *ngFor="let customer of shipping.filteredCustomerList | async" [value]="customer.company_name">
                    {{customer.company_name}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </td>
          </ng-container>

          <!-- 宛て名所属部課または工場 -->
          <ng-container matColumnDef="destination_department_or_factory_name">
            <th mat-header-cell *matHeaderCellDef class="column-width-200">宛て名所属部課または工場</th>
            <td mat-cell *matCellDef="let shipping">
              <mat-form-field appearance="standard" class="form-in-table">
                <input matInput class="input-in-table" [(ngModel)]="shipping.destination_department_or_factory_name"
                  [formControl]="shipping.destinationDepartmentCheck" [matAutocomplete]="autoDepartment">
                <mat-autocomplete autoActiveFirstOption #autoDepartment="matAutocomplete">
                  <mat-option *ngFor="let customer of shipping.filteredCustomerChildList | async" [value]="customer.department_or_factory_name">
                    {{customer.department_or_factory_name}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </td>
          </ng-container>

          <!-- 宛て名担当者 -->
          <ng-container matColumnDef="destination_staff">
            <th mat-header-cell *matHeaderCellDef class="column-width-110">宛て名担当者</th>
            <td mat-cell *matCellDef="let shipping">
              <mat-form-field appearance="standard" class="form-in-table">
                <input matInput class="input-in-table" [(ngModel)]="shipping.destination_staff" [disabled]="isReference">
              </mat-form-field>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let shipping; columns: displayedColumns;"
            (click)="selectedRow(shipping)"
            [ngClass]="{'selected': selected == shipping}">
          </tr>
        </table>
      </div>
      <div fxFlex style="margin: 8px 0;">
        <!-- 依頼元と宛て名が同じ -->
        <span class="checkbox-label">依頼元と宛て名が同じ</span>
        <mat-checkbox [(ngModel)]="isSame" [disabled]="isReference"></mat-checkbox>
      </div>
      <div fxFlex>
        <!-- 行追加ボタン -->
        <button mat-raised-button color="primary" (click)="onAddedRow()" [disabled]="isReference">
          <span>行追加</span>
        </button>
        <!-- 行削除ボタン -->
        <button mat-raised-button color="primary" (click)="onDeletedRow()" [disabled]="isReference" style="margin-left: 16px;">
          <span>行削除</span>
        </button>
      </div>
    </div>
    <div fxLayout="row" fxLayout.xs="column">
      <div fxFlex="35">
        <!-- 依頼内容 -->
        <mat-form-field appearance="standard" class="form-request-content">
          <mat-label>依頼内容</mat-label>
          <textarea matInput [(ngModel)]="requestContent" [disabled]="isReference"></textarea>
        </mat-form-field>
      </div>
      <div fxFlex="35">
        <!-- 備考 -->
        <mat-form-field appearance="standard" class="form-notes">
          <mat-label>備考</mat-label>
          <textarea matInput [(ngModel)]="notes" [disabled]="isReference"></textarea>
          <mat-hint>原料手配中の場合、着日・量・商社・商品名などを記載</mat-hint>
        </mat-form-field>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div fxLayout="row wrap" fxLayoutAlign="center" class="button-container">
    <button mat-raised-button color="primary" mat-dialog-close>キャンセル</button>
    <button mat-raised-button color="primary" (click)="onClick()" *ngIf="!isReference" [disabled]="formGroup.invalid">決定</button>
  </div>
</mat-dialog-actions>
