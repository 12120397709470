import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { DialogComponent } from '../../../common/dialog/dialog.component';
import { AttachmentSendDialogComponent } from '../attachment-send-dialog/attachment-send-dialog.component';
import { OrderManagementService } from '../order-management.service';

/**
 * 添付管理ダイアログコンポーネント
 */
@Component({
  selector: 'app-attachment-management-dialog',
  templateUrl: './attachment-management-dialog.component.html',
  styleUrls: ['./attachment-management-dialog.component.css']
})
export class AttachmentManagementDialogComponent implements OnInit {

  // #region フィールド

  /** 登録モード */
  public readonly Regist: number = 0;
  /** 更新モード */
  public readonly Update: number = 1;
  /** 登録する添付ファイルのファイル選択用デコレーター */
  @ViewChild('fileInputRegist')
  /** 登録用の添付ファイル選択 */
  public fileInputRegist: any;
  /** 更新する添付ファイルのファイル選択用デコレーター */
  @ViewChild('fileInputUpdate')
  /** 更新用の添付ファイル選択 */
  public fileInputUpdate: any;

  /** 取得した添付ファイル一覧 */
  public dataSource = new MatTableDataSource<any>();
  /** 選択した添付ファイル情報 */
  public selected: any;
  /** チェックを付けた添付ファイルの連番 */
  public checkedAttachmentSeq = [];

  /** 添付送信ボタンの状態 */
  public isDisabledSend = true;

  /** 画面表示項目 */
  public displayedColumns: string[] = [
    'file_select_check',      // ファイル選択チェックボックス
    'order_no_seq',           // 添付ファイル連番
    'attachment_file_name',   // 添付ファイル名
    'comment',                // コメント
    'attachment_update',      // 添付更新
    'attachment_delete'       // 添付削除
  ];

  // #endregion

  // #region コンストラクター

  /**
   * 添付管理ダイアログコンポーネントのコンストラクター
   * @constructor
   * @param data 画面から渡されたデータ
   * @param matDialog MatDialog
   * @param matSnackBar MatSnackBar
   * @param service 案件管理関連サービス
   */
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private matDialog: MatDialog,
              private matSnackBar: MatSnackBar,
              private service: OrderManagementService) {
  }

  // #endregion

  // #region ngOnInit

  /**
   * 初期表示時の処理
   */
  async ngOnInit(): Promise<void> {
    // 案件添付ファイルの一覧取得
    await this.getOrderAttachmentFileList();
  }

  // #endregion

  // #region データ取得時の処理

  /**
   * 案件添付ファイルの一覧を取得する。
   */
  private async getOrderAttachmentFileList() {
    // 案件添付ファイルの一覧取得
    const select = await this.service.getAttachmentFileList(this.data.order_no);
    // 取得に失敗した場合、サーバから返ってきたメッセージを表示する
    if (!select.success) {
      alert(select.message);
      return;
    }
    // 取得した結果をテーブルにセットする
    setTimeout(() => {
      this.dataSource.data = select.data;
    });
  }

  // #endregion

  // #region 項目選択時の処理

  /**
   * ファイル選択チェックボックス押下時の処理
   * @param event チェックイベント
   * @param file 選択行のデータ
   */
  public selectedFile(event, file) {
    console.log('@@@@ ファイル選択チェックボックス押下');

    if (event.checked) {
      // チェックした添付ファイルの連番を配列に追加
      this.checkedAttachmentSeq.push(file.order_no_seq);
    } else {
      // チェックした添付ファイルの連番を配列から削除
      const index = this.checkedAttachmentSeq.indexOf(file.order_no_seq);
      this.checkedAttachmentSeq.splice(index, 1);
    }

    // チェックがされている場合にのみ、添付送信ボタンを活性
    this.isDisabledSend = (this.checkedAttachmentSeq.length) ? false : true;
  }

  // #endregion

  // #region ボタン押下時の処理

  /**
   * 添付追加ボタン押下時の処理
   */
  public onAttachmentAdd() {
    console.log('@@@@ 添付追加ボタン押下');

    // ファイル選択ダイアログ表示
    this.fileInputRegist.nativeElement.click();
  }

  /**
   * 添付更新ボタン押下時の処理
   * @param file 選択行のデータ
   */
  public onAttachmentUpdate(file) {
    console.log('@@@@ 添付更新ボタン押下');

    // 選択した添付ファイル情報をセットする
    this.selected = file;

    // ファイル選択ダイアログ表示
    this.fileInputUpdate.nativeElement.click();
  }

  /**
   * 添付削除ボタン押下時の処理
   * @param file 選択行のデータ
   */
  public onAttachmentDelete(file) {
    console.log('@@@@ 添付削除ボタン押下');

    // 削除処理開始前に確認ダイアログを表示
    const dialogRef =  this.matDialog.open(DialogComponent, {
      width: '300px',
      height: '300px',
      data: {
        title: '削除確認',
        message: file.attachment_file_name + ' を削除してよろしいでしょうか？',
      },
      // 画面外のクリックを禁止する設定を追加
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(async(result) => {
      if (!result) {
        console.log('@@@@ 添付ファイルの削除処理開始');

        // 削除処理
        const del = await this.service.deleteAttachmentFile(file.order_no, file.order_no_seq);
        // 削除処理に失敗した場合、サーバから返ってきたメッセージを表示する
        if (!del.success) {
          alert(del.message);
          return;
        }
        // 削除後処理
        this.matSnackBar.open('削除しました。', '', {
          duration: 1000
        });
        console.log('@@@@ 添付ファイルの削除処理完了');

        // 添付ファイル一覧の再取得
        await this.getOrderAttachmentFileList();
      } else {
        // キャンセル押下時は何もしない
        console.log('ダイアログでキャンセルされました。');
        return;
      }
    });
  }

  /**
   * 添付送信ボタン押下時の処理
   */
  public onAttachmentSend() {
    console.log('@@@@ 添付送信ボタン押下');

    const dialogRef =  this.matDialog.open(AttachmentSendDialogComponent, {
      data: {
        order_no: this.data.order_no,
        order_name: this.data.order_name,
        seq: this.checkedAttachmentSeq
      },
      // 画面外のクリックを禁止する設定を追加
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(async(result) => {
      if (result) {
        // チェックした添付ファイルの連番を初期化
        this.checkedAttachmentSeq = [];
        // 添付送信ボタンを非活性
        this.isDisabledSend = true;

        // 添付ファイル一覧の再取得
        await this.getOrderAttachmentFileList();
      } else {
        // キャンセル押下時は何もしない
        console.log('ダイアログでキャンセルされました。');
        return;
      }
    });
  }

  // #endregion

  // #region 共通処理

  /**
   * ファイル選択ダイアログでのファイル選択処理
   *
   * ファイル選択ダイアログでファイルを選択した場合に実行される
   * 選択したファイルをbase64形式に変換し登録・更新処理に渡す
   * @param event 選択したファイルの情報
   * @param mode イベントの呼び出し元
   */
  public onChangeFileInput(event: any, mode: number) {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      // 該当要素のvalueを削除する
      // この処理がない場合、同じファイル名の選択を感知できない
      event.target.value = '';

      // ファイルサイズが2MBを超える場合、エラーを表示し後続処理を中止する
      if (file.size > 2097152) {
        alert('2MBを超えるファイルは登録できません。');
        return;
      }
      // 同一のファイル名がすでに登録済みの場合、エラーを表示し後続処理を中止する
      const exist = this.dataSource.data.filter(v => v.attachment_file_name == file.name);
      if (mode === this.Regist) {
        if (exist && exist.length) {
          alert('同一のファイルは登録できません。');
          return;
        }
      } else if (mode === this.Update) {
        if (this.selected.attachment_file_name != file.name) {
          if (exist && exist.length) {
            alert('同一のファイルは登録できません。');
            return;
          }
        }
      }

      // 選択したファイルをbase64形式に変換し、登録・更新処理に渡す
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        console.log('base64形式への変換完了');
        if (mode === this.Regist) {
          this.registAttachmentFile(file, reader.result);
        } else if (mode === this.Update) {
          this.uploadAttachmentFile(file, reader.result);
        }
      };
    }
  }

  /**
   * 添付ファイルの登録処理
   * @param file 選択したファイルの情報
   * @param base64 選択したファイルのbase64データ
   * @returns
   */
  private async registAttachmentFile(file, base64) {
    console.log('@@@@ 添付ファイルの登録処理開始');

    // サーバに渡すJSON
    const bodyData = {
      order_no: this.data.order_no,
      attachment_file_name: file.name,
      attachment_file_data: base64,
      mime_type: file.type
    };
    // 登録処理
    const regist = await this.service.insertAttachmentFile(bodyData);
    // 登録処理に失敗した場合、サーバから返ってきたメッセージを表示する
    if (!regist.success) {
      alert(regist.message);
      return;
    }
    // 登録後処理
    this.matSnackBar.open('登録しました。', '', {
      duration: 1000
    });
    console.log('@@@@ 添付ファイルの登録処理完了');

    // 添付ファイル一覧の再取得
    await this.getOrderAttachmentFileList();
  }

  /**
   * 添付ファイルの更新処理
   * @param file 選択したファイルの情報
   * @param base64 選択したファイルのbase64データ
   * @returns
   */
  private async uploadAttachmentFile(file, base64) {
    console.log('@@@@ 添付ファイルの更新処理開始');

    // サーバに渡すJSON
    const bodyData = {
      order_no: this.data.order_no,
      order_no_seq: this.selected.order_no_seq,
      attachment_file_name: file.name,
      attachment_file_data: base64,
      mime_type: file.type,
      comment: this.selected.comment
    };
    // 更新処理
    const update = await this.service.updateAttachmentFile(bodyData);
    // 更新処理に失敗した場合、サーバから返ってきたメッセージを表示する
    if (!update.success) {
      alert(update.message);
      return;
    }
    // 更新後処理
    this.matSnackBar.open('更新しました。', '', {
      duration: 1000
    });
    console.log('@@@@ 添付ファイルの更新処理完了');

    // 選択した添付ファイル情報をクリア
    this.selected = null;

    // 添付ファイル一覧の再取得
    await this.getOrderAttachmentFileList();
  }

  // #endregion
}
