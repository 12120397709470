<div class="container" fxLayout="column">
  <div id="table-container" fxFlex="grow">
    <table mat-table [dataSource]="dataSource" class="employee-list">

      <!-- 社員番号 -->
      <ng-container matColumnDef="employee_no">
        <th mat-header-cell *matHeaderCellDef class="column-width-2">社員番号</th>
        <td mat-cell *matCellDef="let employee">{{employee.employee_no}}</td>
      </ng-container>

      <!-- 社員名 -->
      <ng-container matColumnDef="employee_name">
        <th mat-header-cell *matHeaderCellDef class="column-width-2">社員名</th>
        <td mat-cell *matCellDef="let employee"> {{employee.employee_name}} </td>
      </ng-container>

      <!-- 所属部署 -->
      <ng-container matColumnDef="department_name">
        <th mat-header-cell *matHeaderCellDef class="column-width-4">所属部署</th>
        <td mat-cell *matCellDef="let employee" class="pre-line"> {{employee.department_name}} </td>
      </ng-container>

      <!-- メールアドレス -->
      <ng-container matColumnDef="mail_address">
        <th mat-header-cell *matHeaderCellDef class="column-width-5">メールアドレス</th>
        <td mat-cell *matCellDef="let employee"> {{employee.mail_address}} </td>
      </ng-container>

      <!-- 携帯電話 -->
      <ng-container matColumnDef="mobile_number">
        <th mat-header-cell *matHeaderCellDef class="column-width-4">携帯電話</th>
        <td mat-cell *matCellDef="let employee"> {{employee.mobile_number}} </td>
      </ng-container>

      <!-- 入社日 -->
      <ng-container matColumnDef="join_company_date">
        <th mat-header-cell *matHeaderCellDef class="column-width-3">入社日</th>
        <td mat-cell *matCellDef="let employee"> {{employee.join_company_date | date: 'y/M/d'}} </td>
      </ng-container>

      <!-- 退社日 -->
      <ng-container matColumnDef="leave_company_date">
        <th mat-header-cell *matHeaderCellDef class="column-width-3">退社日</th>
        <td mat-cell *matCellDef="let employee"> {{employee.leave_company_date | date: 'y/M/d'}} </td>
      </ng-container>

      <!-- 削除フラグ -->
      <ng-container matColumnDef="delete_flag">
        <th mat-header-cell *matHeaderCellDef class="column-width-1 last-column">削除済</th>
        <td mat-cell *matCellDef="let employee" class="td-center last-column"> {{employee.delete_flag | i18nPlural: isDeleted}} </td>
      </ng-container>

      <!-- 作成者 -->
      <ng-container matColumnDef="created_by">
        <th mat-header-cell *matHeaderCellDef [style.display]="'none'" class="column-width-1">作成者</th>
        <td mat-cell *matCellDef="let employee" [style.display]="'none'"> {{employee.created_by}} </td>
      </ng-container>

      <!-- 作成日時 -->
      <ng-container matColumnDef="create_datetime">
        <th mat-header-cell *matHeaderCellDef [style.display]="'none'" class="column-width-1">作成日時</th>
        <td mat-cell *matCellDef="let employee" [style.display]="'none'"> {{employee.create_datetime}} </td>
      </ng-container>

      <!-- 更新者 -->
      <ng-container matColumnDef="updated_by">
        <th mat-header-cell *matHeaderCellDef [style.display]="'none'" class="column-width-1">更新者</th>
        <td mat-cell *matCellDef="let employee" [style.display]="'none'"> {{employee.updated_by}} </td>
      </ng-container>

      <!-- 更新日時 -->
      <ng-container matColumnDef="update_datetime">
        <th mat-header-cell *matHeaderCellDef [style.display]="'none'" class="column-width-1">更新日時</th>
        <td mat-cell *matCellDef="let employee" [style.display]="'none'"> {{employee.update_datetime}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let employee; columns: displayedColumns;"
        (click)="selectedRow(employee)"
        [ngClass]="{'selected': selected == employee}">
      </tr>
    </table>
  </div>

  <div id="footer-container">
    <span id="deletecheck-position">削除済も含む</span><mat-checkbox (change)="setDeleteCheck($event)" [checked]="deleteCheckStatus"></mat-checkbox>
    <br>
    <div fxLayout="row wrap" fxLayoutAlign="center" fxLayoutAlign.xs="space-between" class="button-container">
      <!-- 新規登録ボタン -->
      <button mat-raised-button color="primary" (click)="onEntry()">
        <mat-icon>person_add</mat-icon>
        <span class="btn-label">新規登録</span>
      </button>
      <!-- 修正ボタン -->
      <button mat-raised-button color="primary" (click)="onModify()" [disabled]="isDisabledModify">
        <mat-icon>edit</mat-icon>
        <span class="btn-label">修正</span>
      </button>
      <!-- 削除ボタン -->
      <button mat-raised-button color="primary" (click)="onDelete()" [disabled]="isDisabledDelete">
        <mat-icon>cancel</mat-icon>
        <span class="btn-label">削除</span>
      </button>
      <!-- 復元ボタン -->
      <button mat-raised-button color="primary" (click)="onRestore()" [disabled]="isDisabledRestore">
        <mat-icon>settings_backup_restore</mat-icon>
        <span class="btn-label">復元</span>
      </button>
    </div>
  </div>
</div>
