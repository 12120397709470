import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';

@Injectable()
export class MatPaginatorIntlJa extends MatPaginatorIntl {
  itemsPerPageLabel = '1 ページあたりの行数: ';
  firstPageLabel = '先頭ページへ';
  nextPageLabel = '次ページへ';
  previousPageLabel = '前ページへ';
  lastPageLabel = '最終ページへ';

  getRangeLabel = (page: number, pageSize: number, length: number): string => {
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
    return `${length} 件中 ${startIndex + 1} ～ ${endIndex} 件目`;
  };
}
