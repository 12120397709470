import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Location } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';
import { AppConfig } from '../../../shared/class/appconfig.class';
import { HeaderTitleService } from '../../../shared/service/headerTitle.service';
import { NavigationService } from '../../../shared/service/navigation.service';
import { MaintenanceEmployeeService } from '../maintenance-employee.service';
import { MaintenanceDepartmentService } from './../../department/maintenance-department.service';

/**
 * 社員マスタ登録・修正コンポーネント
 */
@Component({
  selector: 'app-employee-entry-modify',
  templateUrl: './employee-entry-modify.component.html',
  styleUrls: ['./employee-entry-modify.component.css']
})
export class EmployeeEntryModifyComponent implements OnInit {

  // 初期値
  /** モード(1:登録、2:修正) */
  public mode: string;
  /** 社員番号 */
  public employeeNo: string;
  /** 元社員番号 */
  public beforeEmployeeNo: string;
  /** 社員名 */
  public employeeName: string;
  /** パスワード */
  public password: string;
  /** メールアドレス */
  public mailAddress: string;
  /** 携帯電話 */
  public mobilePhoneNumber: string;
  /** 入社日 */
  public joinDate: Date;
  /** 退社日 */
  public leaveDate: Date;
  /** 作成者 */
  public createdBy: string;
  /** 作成日時 */
  public createDateTime: string;
  /** 更新者 */
  public updatedBy: string;
  /** 更新日時 */
  public updateDateTime: string;
  /** 付与されている権限 */
  public hasAuthority: any = [];
  /** 付与されていない権限 */
  public notHasAuthority: any = [];
  /** 登録する社員明細 */
  public employeeDetail: any;
  /** 部署名一覧 */
  public departmentNameList: any;

  /** 登録ボタンの名称 */
  public registButtonName = '';
  /** 項目制御(参照モード用) */
  public itemControl = false;
  /** 入力でFormControlを使用する項目の表示切替 */
  public inputMode = true;
  /** チェックボックス位置(手前) */
  public before = 'before';

  /**
   * 社員マスタ登録・修正コンポーネントのコンストラクター
   * @constructor
   */
  constructor(private title: Title,
              private headerTitle: HeaderTitleService,
              private transitionService: NavigationService,
              private route: ActivatedRoute,
              private location: Location,
              private matSnackBar: MatSnackBar,
              private changeDetectorRef: ChangeDetectorRef,
              private empService: MaintenanceEmployeeService,
              private depService: MaintenanceDepartmentService) {
    // モードによって表示する文言を変更する
    this.route.data.subscribe(data => this.mode = data.mode);
    if (this.mode === '1') {
      // ページタイトルの設定
      this.title.setTitle('社員マスタ登録 | ' + AppConfig.SYSTEM_NANE);
      // ヘッダータイトルの設定
      this.headerTitle.setTitle('社員マスタ登録');
      // ボタン名称の設定
      this.registButtonName = '登録';
    } else if (this.mode === '2') {
      // ページタイトルの設定
      this.title.setTitle('社員マスタ修正 | ' + AppConfig.SYSTEM_NANE);
      // ヘッダータイトルの設定
      this.headerTitle.setTitle('社員マスタ修正');
      // ボタン名称の設定
      this.registButtonName = '修正';
    }
  }

  /** チェック：社員名 */
  public employeenameCheck = new FormControl('', [Validators.required]);
  /** チェック：メールアドレス */
  public mailAddressCheck = new FormControl('', [Validators.required, Validators.email]);
  /** チェック：携帯電話 */
  public mobilePhoneNumberCheck = new FormControl('', Validators.pattern(/^(070|080|090)-\d{4}-\d{4}$/));
  /** チェック：入社日 */
  public joinDateCheck = new FormControl('', [Validators.required]);
  /**
   * チェック：パスワード
   *
   * パスワードチェック：8桁以上かつ大文字小文字記号を最低１文字
   */
  public checkPass = new FormControl('', [
    Validators.required,
    Validators.pattern(/^(?=.*[!-/:-@[-`{-~])(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])[!-~]{8,}$/)
  ]);

  /**
   * 初期処理
   *
   * 画面タイトル、ボタン名称の設定、役職、部署の取得、設定
   * 修正、参照の場合は社員情報を取得し設定する。
   */
  async ngOnInit(): Promise<void> {
    // 部署一覧の取得
    await this.getDepartmentList();

    // 画面の初期化
    this.initializeWindow();

    if (this.mode === '1') {
      // 登録モードであれば、新規番号を採番
      await this.getNewEmployeeNo();
    } else if (this.mode === '2') {
      // 修正モードであれば、サービスから社員番号を取得
      this.empService.employeeNo.subscribe(async employeeNo => {
        this.employeeNo = employeeNo;
        this.beforeEmployeeNo = employeeNo;
      });
      // 該当社員検索
      await this.getEmployeeDetail();
    }
  }

  /**
   * 社員番号変更時の処理
   * @param value 入力された値
   */
  onChange(value: string) {
    const newValue = value.padStart(4, '0');
    this.employeeNo = newValue;
    return newValue;
  }

  /**
   * 入社日設定
   *
   * 入社日設定時に設定内容を保存する。
   * @param event 入社日
   */
  setJoinDate(event) {
    // 入力された値が正当かどうかチェック
    if (!moment(event.value).isValid()) {
      // 正当な値でない場合は後続処理を行わない
      return;
    }
    // 入社日を設定
    this.joinDate = event.value;
  }

  /**
   * 退社日設定
   *
   * 退社日設定時に設定内容を保存する。
   * @param event 退社日
   */
  setLeaveDate(event) {
    // 入力された値が正当かどうかチェック
    if (!moment(event.value).isValid()) {
      // 正当な値でない場合は後続処理を行わない
      return;
    }
    // 退社日を設定
    this.leaveDate = event.value;
  }

  /**
   * 戻るボタン押下時の処理
   */
  async onCancel() {
    // 画面遷移の許可
    this.transitionService.canTransition = true;
    this.location.back();
  }

  /**
   * 登録ボタン押下時の処理
   *
   * 入力チェックを行い問題なければ、
   * 社員マスタへ登録、更新を行う。
   * 所属部署が選択されている場合は、
   * 社員部署マッピングマスタも作成する。
   * 修正時、社員番号が変更されていた場合、
   * 変更しても問題ないかを確認してから修正処理を行う。
   * @param params 所属部署の選択状態
   */
  async onRegist(params: any): Promise<void> {
    // 登録処理開始前に入力内容をチェックし該当する場合はアラートを表示
    if (!this.employeeName || !this.mailAddress || !this.joinDate) {
      console.log('入力必須項目が未入力のため処理中断');
      alert('入力必須項目が未入力です。');
      return;
    }
    if (this.joinDate && !moment(this.joinDate).isValid()) {
      console.log('入社日に日付以外が入力されているため処理中断');
      alert('入社日に日付以外が入力されています。');
      return;
    }
    if (this.leaveDate && !moment(this.leaveDate).isValid()) {
      console.log('退社日に日付以外が入力されているため処理中断');
      alert('退社日に日付以外が入力されています。');
      return;
    }

    // 所属部署を配列に変換
    // [ "0001", "0002", "0003", ... ]
    const mappingDepartmentNo = params.map(x => x.value);
    console.log(mappingDepartmentNo);

    // 社員権限を配列に変換
    // [ 1, 2, 3, ... ]
    const mappingAuthority = this.hasAuthority.map(x => x.function_id);
    console.log(mappingAuthority);

    // モード毎に登録・修正の処理を実行する
    if (this.mode === '1') {
      console.log('@@@@ 登録ボタン押下');

      if (!this.password) {
        console.log('入力必須項目が未入力のため処理中断');
        alert('新規登録の場合、パスワードの入力は必須です。');
        return;
      }
      // パスワードがパスワード規則に沿っていない場合、エラーを表示
      const pettern = '^(?=.*[!-/:-@[-`{-~])(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])[!-~]{8,}$';
      if (!this.password.match(pettern)) {
        alert('パスワードには8桁以上の大小英字と数字記号を使用してください。');
        return;
      }

      // 番号チェック
      const rec: any = await this.empService.checkEmployeeNo(this.employeeNo);

      if (rec) {
        console.log('社員番号重複のため処理中断');
        alert('社員番号が重複しています。\n異なる番号に変更してください。');
        return;
      }

      // サーバに渡すJSON
      this.employeeDetail = {
        employee_no: this.employeeNo,
        employee_name: this.employeeName,
        password: this.password,
        mail_address: this.mailAddress,
        mobile_number: this.mobilePhoneNumber,
        join_company_date: new Date(this.joinDate).toLocaleDateString(),
        leave_company_date: new Date(this.leaveDate).toLocaleDateString(),
        mapping_department_no: mappingDepartmentNo,
        mapping_function_id: mappingAuthority,
      };

      // 退社日付が初期値の場合はnullにして渡す
      if (this.employeeDetail.leave_company_date == '1970/1/1') {
        this.employeeDetail.leave_company_date = null;
      }

      // 社員明細および社員部署マッピングを登録
      const entry = await this.empService.insertEmployeeDetail(this.employeeDetail);
      // 登録に失敗した場合、サーバから返ってきたメッセージを表示する
      if (!entry.success) {
        alert(entry.message);
        return;
      }
      // 登録後処理
      this.matSnackBar.open('登録しました。', '', {
        duration: 1000
      });
      console.log('@@@@ 登録処理が完了しました。');
    } else if (this.mode === '2') {
      console.log('@@@@ 修正ボタン押下');

      if (!this.password) {
        // 何も入力していない場合は空白をセット
        this.password = '';
      }

      if (this.password !== '') {
        // パスワードがパスワード規則に沿っていない場合、エラーを表示
        const pettern = '^(?=.*[!-/:-@[-`{-~])(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])[!-~]{8,}$';
        if (!this.password.match(pettern)) {
          alert('パスワードには8桁以上の大小英字と数字記号を使用してください。');
          return;
        }
      }

      // 社員番号修正チェック
      if (this.employeeNo != this.beforeEmployeeNo) {
        // 修正後の社員番号と重複している社員番号が存在するか確認する
        const rec: any = await this.empService.checkEmployeeNo(this.employeeNo);

        if (rec) {
          console.log('社員番号重複のため処理中断');
          alert('修正後の社員番号が存在しています。\n異なる番号に変更してください。');
          return;
        }
      }

      this.employeeDetail = {
        before_employee_no: this.beforeEmployeeNo,
        employee_no: this.employeeNo,
        employee_name: this.employeeName,
        password: this.password,
        mail_address: this.mailAddress,
        mobile_number: this.mobilePhoneNumber,
        join_company_date: new Date(this.joinDate).toLocaleDateString(),
        leave_company_date: new Date(this.leaveDate).toLocaleDateString(),
        mapping_department_no: mappingDepartmentNo,
        mapping_function_id: mappingAuthority,
      };

      // 退社日付が初期値の場合はnullにして渡す
      if (this.employeeDetail.leave_company_date == '1970/1/1') {
        this.employeeDetail.leave_company_date = null;
      }

      // 社員明細および社員部署マッピングを修正
      const modify = await this.empService.updateEmployeeDetail(this.employeeDetail);
      // 登録に失敗した場合、サーバから返ってきたメッセージを表示する
      if (!modify.success) {
        alert(modify.message);
        return;
      }
      // 修正後処理
      this.matSnackBar.open('修正しました。', '', {
        duration: 1000
      });
      console.log('@@@@ 修正処理が完了しました。');
    }

    // 画面遷移の許可
    this.transitionService.canTransition = true;
    // 社員一覧画面に戻る
    this.location.back();
  }

  /**
   * 社員明細の取得
   *
   * 社員明細を取得し、各項目に設定する。
   * 削除済みの社員である場合は参照モードに切り替える。
   */
  private async getEmployeeDetail() {
    // 社員を取得する
    const rec: any = await this.empService.getEmployeeDetail(this.employeeNo);

    // 取得した結果を各項目にセットする
    this.employeeName = rec.employee_name;
    this.password = rec.password;
    this.mailAddress = rec.mail_address;
    this.mobilePhoneNumber = rec.mobile_number;
    this.joinDate = rec.join_company_date;
    this.leaveDate = rec.leave_company_date;
    this.createdBy = rec.created_employee_name;
    this.createDateTime = rec.create_datetime;
    this.updatedBy = rec.updated_employee_name;
    this.updateDateTime = rec.update_datetime;
    this.hasAuthority = rec.hasAuthority;
    this.notHasAuthority = rec.notHasAuthority;

    // 所属部署のチェックを設定する
    if (rec.department_no) {
      const checkDepartment = rec.department_no.split(',');
      this.departmentNameList.forEach(item => {
        checkDepartment.forEach(element => {
          if (element == item.department_no) {
            item.selected = true;
          }
        });
      });
    }
    // ExpressionChangedAfterItHasBeenCheckedError対策
    this.changeDetectorRef.detectChanges();
  }

  /**
   * 新規社員番号の取得
   *
   * 新規社員番号を取得する。
   */
  private async getNewEmployeeNo() {
    // 社員を取得する
    const rec: any = await this.empService.getNewEmployeeNo();
    // 取得した結果をセットする
    this.employeeNo = rec.employee_no;
    this.notHasAuthority = rec.notHasAuthority;
  }

  /**
   * 部署一覧の取得
   *
   * 部署一覧を取得し、チェックボックスを作成する。
   */
  private async getDepartmentList() {
    // 削除フラグが false の部署一覧を取得する
    const rec: any = await this.depService.getDepartmentList(0);
    // 「全部署」以外を抽出する
    const result: any = rec.filter(v => v.department_no !== '0001');
    // 取得した結果をセットする
    this.departmentNameList = result;
  }

  /**
   * 画面初期化処理
   *
   * 画面項目を初期表示の状態に戻す。
   * ExpressionChangedAfterItHasBeenCheckedError対策のため
   * changeDetectorRefで再描画処理をしている。
   */
  initializeWindow() {
    console.log('@@@@ 画面初期化');
    this.employeeName = '';
    this.mailAddress = '';
    this.mobilePhoneNumber = '';
    this.joinDate = null;
    this.leaveDate = null;
    this.createDateTime = '';
    this.updateDateTime = '';
    // ExpressionChangedAfterItHasBeenCheckedError対策
    this.changeDetectorRef.detectChanges();
  }

  /**
   * ドラッグアンドドロップされた時のイベント処理
   * @param event ドラッグアンドドロップされた対象
   */
  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
    }
  }
}
