import { Injectable } from '@angular/core';
import { AppConfig } from '../../shared/class/appconfig.class';
import { HttpClient } from '@angular/common/http';
import { HttpService } from '../../shared/service/http.service';
import { HttpReqOptions } from '../../shared/interface/httpOption.interface';
import { BehaviorSubject } from 'rxjs';

/**
 * 得意先メンテナンス関連サービス
 */
@Injectable({
  providedIn: 'root'
})
export class MaintenanceCustomerService {

  // 初期値
  /** 得意先番号 */
  public customerNo = new BehaviorSubject<string>('');
  /** 得意先子情報 */
  public customerChild = new BehaviorSubject<any>({});

  /**
   * HTTPサービスの利用
   * @constructor
   * @param httpService
   * @param http
   */
  constructor(private httpService: HttpService,
              private http: HttpClient) { }

  /**
   * 得意先番号保存
   *
   * 保存した得意先番号から得意先の明細情報を取得する
   * @param customerNo 得意先番号
   */
  sendDetailInfomation(customerNo: string) {
    this.customerNo.next(customerNo);
  }
  /**
   * 得意先子情報保存
   *
   * 保存した得意先子の情報を画面に渡す
   * @param customerChild 得意先子情報
   */
  sendCustomerChildInfomation(customerChild: any) {
    console.log(customerChild);
    this.customerChild.next(customerChild);
  }


  /**
   * 得意先マスタ一覧の取得
   *
   * 得意先マスタ一覧を取得、削除フラグチェックありの場合は、 削除済み得意先も含めて取得する。
   * HTTPリクエスト種類: GET
   */
  async getCustomerList(deletecheck: any): Promise<any> {
    // HTTPリクエスト条件設定
    const config: HttpReqOptions = {
      url: AppConfig.apiUrl + 'maintenance/customerList/' + deletecheck
    };

    // HTTPリクエスト
    let res: any;
    try {
      res = await this.httpService.send('get', config);
    } catch (err) {
      throw new Error('データ受信失敗');
    }
    // 受信データ処理
    const rec = res.data;
    if (rec && rec.length) {
      console.log('@@@@ データ受信成功');
    }
    return Promise.resolve(rec);
  }

  /**
   * 得意先親マスタの一覧取得
   *
   * 得意先親マスタから一覧を取得する。
   * HTTPリクエスト種類: GET
   */
  async getCustomerParentList(): Promise<any> {
    // HTTPリクエスト条件設定
    const config: HttpReqOptions = {
      url: AppConfig.apiUrl + 'maintenance/customerParentList'
    };

    // HTTPリクエスト
    let res: any;
    try {
      res = await this.httpService.send('get', config);
    } catch (err) {
      throw new Error('データ受信失敗');
    }
    // 受信データ処理
    const rec = res.data;
    if (rec && rec.length) {
      console.log('@@@@ データ受信成功');
    }
    return Promise.resolve(rec);
  }

  /**
   * 得意先子マスタの一覧取得
   *
   * 得意先子マスタから一覧を取得する。
   * HTTPリクエスト種類: GET
   */
  async getCustomerChildList(): Promise<any> {
    // HTTPリクエスト条件設定
    const config: HttpReqOptions = {
      url: AppConfig.apiUrl + 'maintenance/customerChildList'
    };

    // HTTPリクエスト
    let res: any;
    try {
      res = await this.httpService.send('get', config);
    } catch (err) {
      throw new Error('データ受信失敗');
    }
    // 受信データ処理
    const rec = res.data;
    if (rec && rec.length) {
      console.log('@@@@ データ受信成功');
    }
    return Promise.resolve(rec);
  }

  /**
   * 得意先親情報の取得
   *
   * 得意先番号から、得意先親情報を取得する。
   * HTTPリクエスト種類: GET
   */
  async getCustomerParent(customerno: any): Promise<any> {
    // HTTPリクエスト条件設定
    const config: HttpReqOptions = {
      url: AppConfig.apiUrl + 'maintenance/customerParent/' + customerno
    };

    // HTTPリクエスト
    let res: any;
    try {
      res = await this.httpService.send('get', config);
    } catch (err) {
      throw new Error('データ受信失敗');
    }
    // 受信データ処理
    const rec = res.data;
    if (rec && rec.length) {
      console.log('@@@@ データ受信成功');
    }
    return Promise.resolve(rec);
  }

  /**
   * 得意先子情報の取得
   *
   * 得意先番号から、得意先子情報を取得する。
   * HTTPリクエスト種類: GET
   */
  async getCustomerChild(customerno: any): Promise<any> {
    // HTTPリクエスト条件設定
    const config: HttpReqOptions = {
      url: AppConfig.apiUrl + 'maintenance/customerChild/' + customerno
    };

    // HTTPリクエスト
    let res: any;
    try {
      res = await this.httpService.send('get', config);
    } catch (err) {
      throw new Error('データ受信失敗');
    }
    // 受信データ処理
    const rec = res.data;
    if (rec && rec.length) {
      console.log('@@@@ データ受信成功');
    }
    return Promise.resolve(rec);
  }

  /**
   * 新規得意先番号の取得
   *
   * 得意先マスタから最新の得意先番号を取得する。
   * HTTPリクエスト種類: GET
   */
  async getNewCustomerNo(): Promise<any> {
    // HTTPリクエスト条件設定
    const config: HttpReqOptions = {
      url: AppConfig.apiUrl + 'maintenance/customer'
    };

    // HTTPリクエスト
    let res: any;
    try {
      res = await this.httpService.send('get', config);
    } catch (err) {
      throw new Error('データ受信失敗');
    }
    // 受信データ処理
    const rec = res.data;
    if (rec && rec.length) {
      console.log('@@@@ データ受信成功');
    }
    return Promise.resolve(rec);
  }

  /**
   * 得意先番号の取得
   *
   * 会社名に一致する得意先番号を得意先親マスタから取得する。
   * HTTPリクエスト種類: GET
   * @param customerCompanyName 会社名
   */
  async getCustomerNo(customerCompanyName: string): Promise<any> {
    // HTTPリクエスト条件設定
    const config: HttpReqOptions = {
      url: AppConfig.apiUrl + 'maintenance/customer/getCustomerNo/' + customerCompanyName
    };

    // HTTPリクエスト
    let res: any;
    try {
      res = await this.httpService.send('get', config);
    } catch (err) {
      throw new Error('データ受信失敗');
    }
    // 受信データ処理
    const rec = res.data;
    if (rec && rec.length) {
      console.log('@@@@ データ受信成功');
    }
    return Promise.resolve(rec);
  }

  /**
   * 得意先の重複チェック
   *
   * 得意先番号から得意先親マスタを確認し、重複している得意先番号が存在するか確認する。
   * HTTPリクエスト種類: GET
   */
  async checkCustomerNo(customerno: any): Promise<any> {
    // HTTPリクエスト条件設定
    const config: HttpReqOptions = {
      url: AppConfig.apiUrl + 'maintenance/customer/checkCustomerNo/' + customerno
    };

    // HTTPリクエスト
    let res: any;
    try {
      res = await this.httpService.send('get', config);
    } catch (err) {
      throw new Error('データ受信失敗');
    }
    // 受信データ処理
    const rec = res.data;
    if (rec && rec.length) {
      console.log('@@@@ データ受信成功');
    }
    return Promise.resolve(rec);
  }

  /**
   * 得意先明細の登録
   *
   * 入力した得意先の明細を登録する
   * HTTPリクエスト種類: POST
   * @param row 登録する得意先の明細
   */
  async insertCustomerDetail(row: any): Promise<any> {
    // HTTPリクエスト条件設定
    const config: HttpReqOptions = {
      url: AppConfig.apiUrl + 'maintenance/customerDetail',
      body: row
    };

    // HTTPリクエスト
    let res: any;
    try {
      res = await this.http.post(config.url, config.body).toPromise();
    } catch (err) {
      throw new Error('データ受信失敗');
    }

    // 受信データ処理
    const rec = res;
    if (rec.data && rec.data.length) {
      console.log('@@@@ データ受信成功');
    }
    return Promise.resolve(rec);
  }

  /**
   * 得意先明細の更新
   *
   * 入力した得意先の明細を更新する
   * HTTPリクエスト種類: PUT
   * @param row 更新する得意先の明細情報
   */
  async updateCustomerDetail(row: any): Promise<any> {
    // HTTPリクエスト条件設定
    const config: HttpReqOptions = {
      url: AppConfig.apiUrl + 'maintenance/customerDetail',
      body: row
    };

    // HTTPリクエスト
    let res: any;
    try {
      res = await this.http.put(config.url, config.body).toPromise();
    } catch (err) {
      throw new Error('データ受信失敗');
    }
    // 受信データ処理
    const rec = res;
    if (rec.data && rec.data.length) {
      console.log('@@@@ データ受信成功');
    }
    return Promise.resolve(rec);
  }

  /**
   * 得意先親マスタの削除
   *
   * 得意先番号で指定された得意先を得意先親マスタから論理削除する。
   * HTTPリクエスト種類: PUT
   */
  async deleteCustomerParent(customerno: any): Promise<any> {
    // HTTPリクエスト条件設定
    const config: HttpReqOptions = {
      url: AppConfig.apiUrl + 'maintenance/customerParent/delete/' + customerno
    };

    // HTTPリクエスト
    let res: any;
    try {
      res = await this.http.put(config.url, config.body).toPromise();
    } catch (err) {
      throw new Error('データ受信失敗');
    }
    // 受信データ処理
    const rec = res;
    if (rec.data && rec.data.length) {
      console.log('@@@@ データ受信成功');
    }
    return Promise.resolve(rec);
  }

  /**
   * 得意先マスタの復元
   *
   * 得意先番号で指定された得意先を得意先親マスタから論理復元する。
   * HTTPリクエスト種類: PUT
   */
  async restoreCustomerDetail(customerno: any): Promise<any> {
    // HTTPリクエスト条件設定
    const config: HttpReqOptions = {
      url: AppConfig.apiUrl + 'maintenance/customerParent/restore/' + customerno
    };

    // HTTPリクエスト
    let res: any;
    try {
      res = await this.http.put(config.url, config.body).toPromise();
    } catch (err) {
      throw new Error('データ受信失敗');
    }
    // 受信データ処理
    const rec = res;
    if (rec.data && rec.data.length) {
      console.log('@@@@ データ受信成功');
    }
    return Promise.resolve(rec);
  }

  /**
   * 得意先子マスタの登録
   *
   * HTTPリクエスト種類: POST
   * @param bodyData JSONデータ
   */
  async insertCustomerChild(bodyData: any): Promise<any> {
    // HTTPリクエスト条件設定
    const config: HttpReqOptions = {
      url: AppConfig.apiUrl + 'maintenance/customerChild',
      body: bodyData
    };

    // HTTPリクエスト
    let res: any;
    try {
      res = await this.http.post(config.url, config.body).toPromise();
    } catch (err) {
      throw new Error('データ受信失敗');
    }

    // 受信データ処理
    const rec = res;
    if (rec.data && rec.data.length) {
      console.log('@@@@ データ受信成功');
    }
    return Promise.resolve(rec);
  }
}
