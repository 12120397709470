import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { HeaderTitleService } from '../../shared/service/headerTitle.service';
import { AppConfig } from '../../shared/class/appconfig.class';
import { HttpClient } from '@angular/common/http';
import { HttpReqOptions } from '../../shared/interface/httpOption.interface';
import { NavigationService } from '../../shared/service/navigation.service';
import { MenuService } from '../../shared/service/menu.service';
import { StartupService } from '../../shared/service/startup.service';
import { Router } from '@angular/router';

/**
 * ログインコンポーネント
 */
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  /**
   * ログインコンポーネントのコンストラクター
   * @constructor
   */
  constructor(private title: Title,
              private headerTitle: HeaderTitleService,
              private http: HttpClient,
              private startup: StartupService,
              private transitionService: NavigationService,
              private menuService: MenuService,
              private router: Router) {
    // ページタイトル設定
    this.title.setTitle(AppConfig.SYSTEM_NANE);
  }

  /** 社員番号 */
  employeeNo: string;
  /** パスワード */
  password: string;
  /** エラーメッセージ */
  public errMessage = '';

  /** チェック：社員番号 */
  public employeeNoCheck = new FormControl('', [Validators.required]);
  /** チェック：パスワード */
  public passwordCheck = new FormControl('', [Validators.required]);

  /**
   * 初期処理の実施
   */
  ngOnInit(): void {
    // ヘッダータイトル設定
    this.headerTitle.setTitle(AppConfig.SYSTEM_NANE);
  }

  /**
   * バックエンドへログインリクエスト
   * @returns Promise<void>
   */
  async submit(): Promise<void> {
    const config: HttpReqOptions = {
      url: AppConfig.authUrl,
      body: {
        employeeNo: this.employeeNo,
        password: this.password
      }
    };

    // ログイン情報をPOST
    const res: any = await this.http
      .post(config.url, config.body)
      .toPromise();

    // ログイン失敗した場合、画面上にメッセージを表示
    if (!res.success) {
      this.errMessage = res.message;
      return;
    }

    // Web API接続先Url取得
    AppConfig.apiUrl = res.data.apiUrl;

    // サーバーから必要情報の取得・設定
    await this.startup.getUserData();

    // メニュー表示の許可
    this.menuService.setMenuControl(true);

    // 画面遷移の許可
    this.transitionService.canTransition = true;

    // ホーム画面に遷移
    this.router.navigate(['/home']);
  }

  /**
   * エラーメッセージのクリア
   *
   * エラーメッセージが表示されている場合は何かしらの入力をした直後にメッセージをクリアする
   */
  messageClear() {
    if (this.errMessage !== '') {
      this.errMessage = '';
    }
  }
}
