<h2 mat-dialog-title>{{data.title}}</h2>
<mat-dialog-content>
  <div class="container">
    <div>
      <mat-form-field appearance="standard">
        <mat-label>部署番号</mat-label>
        <!-- 登録/修正 -->
        <input matInput type="tel" maxlength="4" name="departmentNo" [(ngModel)]="departmentNo" *ngIf="inputNumberMode"
          (change)="$event.target.value = onChange($event.target.value)"
          oninput="value = value.replace(/\D/g, '')"
          required>
        <!-- 参照 -->
        <input matInput name="departmentNo" [(ngModel)]="departmentNo" *ngIf="compareMode" [disabled]="itemNumberControl">
      </mat-form-field>
    </div>
    <div>
      <mat-form-field appearance="standard">
        <mat-label>部署名</mat-label>
        <!-- 登録/修正 -->
        <input matInput name="departmentName" [(ngModel)]="departmentName" *ngIf="inputMode" required>
        <!-- 参照 -->
        <input matInput name="departmentName" [(ngModel)]="departmentName" *ngIf="compareNameMode" [disabled]="itemControl">
      </mat-form-field>
    </div>
    <div>
      <mat-form-field appearance="standard">
        <mat-label>メールアドレス</mat-label>
        <!-- 登録/修正 -->
        <input type="email" matInput [formControl]="mailAddressCheck"  name="mailAddress" [(ngModel)]="mailAddress" *ngIf="inputMode">
        <mat-error *ngIf="mailAddressCheck.hasError('email')">メールアドレス形式で入力してください。</mat-error>
        <!-- 参照 -->
        <input matInput name="mailAddress" [(ngModel)]="mailAddress" *ngIf="compareNameMode" [disabled]="itemControl">
      </mat-form-field>
    </div>
    <mat-dialog-actions>
      <button class="dialog-button" mat-raised-button color="primary" mat-dialog-close="cancel">キャンセル</button>
      <button class="dialog-button" mat-raised-button color="primary" (click)="onClick()" [disabled]="compareNameMode">
        <span class="btn-label">{{buttonName}}</span>
      </button>
    </mat-dialog-actions>
  </div>
</mat-dialog-content>
