import { Component, OnInit } from '@angular/core';
import { HeaderTitleService } from '../../shared/service/headerTitle.service';

/**
 * ヘッダーコンポーネント
 */
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  /**
   * ヘッダータイトル
   *
   * ヘッダーのタイトルを付ける場合は各コンポーネントからHeaderTitileServiceを用いてタイトルを付与する。
   * 初期値は空白。
   */
  headerTitle = '';

  /**
   * ヘッダーコンポーネントのコンストラクター
   * @constructor
   * @param title ヘッダータイトルサービス
   */
  constructor(private title: HeaderTitleService) { }

  /**
   * 初期処理の実施
   */
  ngOnInit(): void {
    // サービスから取得したヘッダータイトルを設定
    this.title.headerTitle.subscribe(updateTitle => {
      this.headerTitle = updateTitle;
    });
  }
}
