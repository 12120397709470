import { Injectable } from '@angular/core';
import { AppConfig } from '../../shared/class/appconfig.class';
import { HttpClient } from '@angular/common/http';
import { HttpService } from '../../shared/service/http.service';
import { HttpReqOptions } from '../../shared/interface/httpOption.interface';
import { BehaviorSubject } from 'rxjs';

/**
 * 部署メンテナンス関連サービス
 */
@Injectable({
  providedIn: 'root'
})
export class MaintenanceDepartmentService {

  // 初期値
  /** 社員番号 */
  public employeeNo = new BehaviorSubject<string>('');

  /**
   * 部署メンテナンス関連サービスのコンストラクター
   * @constructor
   * @param httpService Httpサービス
   * @param http Httpクライアント
   */
  constructor(private httpService: HttpService,
              private http: HttpClient) { }

  /**
   * 部署一覧の取得
   *
   * 部署マスタから部署一覧を取得する。
   * HTTPリクエスト種類: GET
   */
  async getDepartmentList(deletecheck: any): Promise<any> {
    // HTTPリクエスト条件設定
    const config: HttpReqOptions = {
      url: AppConfig.apiUrl + 'maintenance/departmentList/' + deletecheck
    };

    // HTTPリクエスト
    let res: any;
    try {
      res = await this.httpService.send('get', config);
    } catch (err) {
      throw new Error('データ受信失敗');
    }
    // 受信データ処理
    const rec = res.data;
    if (rec && rec.length) {
      console.log('@@@@ データ受信成功');
    }
    return Promise.resolve(rec);
  }

  /**
   * 部署番号の重複チェック
   *
   * 部署番号から部署マスタを確認し、重複している部署番号が存在するか確認する。
   * HTTPリクエスト種類: GET
   */
  async checkDepartmentNo(departmentno: any): Promise<any> {
    // HTTPリクエスト条件設定
    const config: HttpReqOptions = {
      url: AppConfig.apiUrl + 'maintenance/departmentCheck/' + departmentno
    };

    // HTTPリクエスト
    let res: any;
    try {
      res = await this.httpService.send('get', config);
    } catch (err) {
      throw new Error('データ受信失敗');
    }
    // 受信データ処理
    const rec = res.data;
    if (rec && rec.length) {
      console.log('@@@@ データ受信成功');
    }
    return Promise.resolve(rec);
  }

  /**
   * 部署マスタの新規登録
   *
   * 入力された部署マスタ情報を新規登録する
   * HTTPリクエスト種類: POST
   * @param data 登録する部署マスタデータ
   */
  async insertDepartment(data: any): Promise<any> {
    // HTTPリクエスト条件設定
    const config: HttpReqOptions = {
      url: AppConfig.apiUrl + 'maintenance/departmentRegist',
      body: data
    };

    // HTTPリクエスト
    let res: any;
    try {
      res = await this.http.post(config.url, config.body).toPromise();
    } catch (err) {
      throw new Error('データ受信失敗');
    }

    // 受信データ処理
    const rec = res;
    if (rec.data && rec.data.length) {
      console.log('@@@@ データ受信成功');
    }
    return Promise.resolve(rec);
  }

  /**
   * 部署マスタの更新
   *
   * 入力された部署マスタ情報を更新する
   * HTTPリクエスト種類: POST
   * @param data 登録する部署マスタデータ
   */
  async updateDepartment(data: any): Promise<any> {
    // HTTPリクエスト条件設定
    const config: HttpReqOptions = {
      url: AppConfig.apiUrl + 'maintenance/departmentUpdate',
      body: data
    };

    // HTTPリクエスト
    let res: any;
    try {
      res = await this.http.put(config.url, config.body).toPromise();
    } catch (err) {
      throw new Error('データ受信失敗');
    }

    // 受信データ処理
    const rec = res;
    if (rec.data && rec.data.length) {
      console.log('@@@@ データ受信成功');
    }
    return Promise.resolve(rec);
  }

  /**
   * 部署マスタの並び替え登録
   *
   * 並び替えられた部署マスタを登録する
   * HTTPリクエスト種類: POST
   * @param data 登録する部署マスタデータ
   */
  async updateDepartmentSort(data: any): Promise<any> {
    // HTTPリクエスト条件設定
    const config: HttpReqOptions = {
      url: AppConfig.apiUrl + 'maintenance/departmentSort',
      body: data
    };

    // HTTPリクエスト
    let res: any;
    try {
      res = await this.http.post(config.url, config.body).toPromise();
    } catch (err) {
      throw new Error('データ受信失敗');
    }

    // 受信データ処理
    const rec = res;
    if (rec.data && rec.data.length) {
      console.log('@@@@ データ受信成功');
    }
    return Promise.resolve(rec);
  }

  /**
   * 部署マスタの削除
   *
   * 部署番号で指定された部署を部署マスタから論理削除する。
   * HTTPリクエスト種類: PUT
   * @param departmentno 削除する部署番号
   */
  async deleteDepartment(departmentno: any): Promise<any> {
    // HTTPリクエスト条件設定
    const config: HttpReqOptions = {
      url: AppConfig.apiUrl + 'maintenance/departmentDelete/' + departmentno
    };

    // HTTPリクエスト
    let res: any;
    try {
      res = await this.http.put(config.url, config.body).toPromise();
    } catch (err) {
      throw new Error('データ受信失敗');
    }
    // 受信データ処理
    const rec = res;
    if (rec.data && rec.data.length) {
      console.log('@@@@ データ受信成功');
    }
    return Promise.resolve(rec);
  }

  /**
   * 部署マスタの復元
   *
   * 部署番号で指定された部署を部署マスタから論理復元する。
   * HTTPリクエスト種類: PUT
   */
  async restoreDepartment(departmentno: any): Promise<any> {
    // HTTPリクエスト条件設定
    const config: HttpReqOptions = {
      url: AppConfig.apiUrl + 'maintenance/departmentRestore/' + departmentno
    };

    // HTTPリクエスト
    let res: any;
    try {
      res = await this.http.put(config.url, config.body).toPromise();
    } catch (err) {
      throw new Error('データ受信失敗');
    }
    // 受信データ処理
    const rec = res;
    if (rec.data && rec.data.length) {
      console.log('@@@@ データ受信成功');
    }
    return Promise.resolve(rec);
  }

}
