<div class="container" fxLayout="column">
  <div id="table-container" fxFlex="grow">
    <table mat-table [dataSource]="dataSource" class="new-adopt-list">

      <!-- 案件番号 -->
      <ng-container matColumnDef="order_no">
        <th mat-header-cell *matHeaderCellDef class="column-width-2">案件番号</th>
        <td mat-cell *matCellDef="let newAdopt">{{newAdopt.order_no}}</td>
      </ng-container>

      <!-- ID -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef [style.display]="'none'" class="column-width-1">ID</th>
        <td mat-cell *matCellDef="let newAdopt" [style.display]="'none'">{{newAdopt.id}}</td>
      </ng-container>

      <!-- 案件名 -->
      <ng-container matColumnDef="order_name">
        <th mat-header-cell *matHeaderCellDef class="column-width-5">案件名</th>
        <td mat-cell *matCellDef="let newAdopt"> {{newAdopt.order_name}} </td>
      </ng-container>

      <!-- 最終商品名 -->
      <ng-container matColumnDef="final_item_name">
        <th mat-header-cell *matHeaderCellDef class="column-width-5">最終商品名</th>
        <td mat-cell *matCellDef="let newAdopt" class="pre-line"> {{newAdopt.final_item_name}} </td>
      </ng-container>

      <!-- サイズ -->
      <ng-container matColumnDef="size">
        <th mat-header-cell *matHeaderCellDef class="column-width-3">サイズ</th>
        <td mat-cell *matCellDef="let newAdopt"> {{newAdopt.size}} </td>
      </ng-container>

      <!-- 重量 -->
      <ng-container matColumnDef="weight">
        <th mat-header-cell *matHeaderCellDef class="column-width-3">重量</th>
        <td mat-cell *matCellDef="let newAdopt"> {{newAdopt.weight}} </td>
      </ng-container>

      <!-- 賞味期限 -->
      <ng-container matColumnDef="best_before">
        <th mat-header-cell *matHeaderCellDef class="column-width-3">賞味期限</th>
        <td mat-cell *matCellDef="let newAdopt"> {{newAdopt.best_before}} </td>
      </ng-container>

      <!-- 納品先 -->
      <ng-container matColumnDef="destination_company">
        <th mat-header-cell *matHeaderCellDef class="column-width-5">納品先</th>
        <td mat-cell *matCellDef="let newAdopt"> {{newAdopt.destination_company}} </td>
      </ng-container>

      <!-- 販売元 -->
      <ng-container matColumnDef="distributor">
        <th mat-header-cell *matHeaderCellDef class="column-width-5">販売元</th>
        <td mat-cell *matCellDef="let newAdopt"> {{newAdopt.distributor}} </td>
      </ng-container>

      <!-- 発売開始 -->
      <ng-container matColumnDef="sales_start">
        <th mat-header-cell *matHeaderCellDef class="column-width-3">発売開始</th>
        <td mat-cell *matCellDef="let newAdopt"> {{newAdopt.sales_start}} </td>
      </ng-container>

      <!-- 期間 -->
      <ng-container matColumnDef="period">
        <th mat-header-cell *matHeaderCellDef class="column-width-4">期間</th>
        <td mat-cell *matCellDef="let newAdopt"> {{newAdopt.period}} </td>
      </ng-container>

      <!-- 生産予想 -->
      <ng-container matColumnDef="production_forecast">
        <th mat-header-cell *matHeaderCellDef class="column-width-3">生産予想</th>
        <td mat-cell *matCellDef="let newAdopt"> {{newAdopt.production_forecast}} </td>
      </ng-container>

      <!-- 備考 -->
      <ng-container matColumnDef="notes">
        <th mat-header-cell *matHeaderCellDef class="column-width-5">備考</th>
        <td mat-cell *matCellDef="let newAdopt" class="last-child"> {{newAdopt.notes}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let newAdopt; columns: displayedColumns;"
        (click)="selectedRow(newAdopt)"
        [ngClass]="{'selected': selected == newAdopt}">
      </tr>
    </table>
  </div>

  <div id="footer-container">
    <div fxLayout="row wrap" fxLayoutAlign="center" fxLayoutAlign.xs="space-between" class="button-container">
      <!-- 新規登録ボタン -->
      <button mat-raised-button color="primary" (click)="onEntry()">
        <mat-icon>add_box</mat-icon>
        <span class="btn-label">新規登録</span>
      </button>
      <!-- 修正ボタン -->
      <button mat-raised-button color="primary" (click)="onModify()" [disabled]="isDisabledModify">
        <mat-icon>edit</mat-icon>
        <span class="btn-label">修正</span>
      </button>
      <!-- 削除ボタン -->
      <button mat-raised-button color="primary" (click)="onDelete()" [disabled]="isDisabledDelete">
        <mat-icon>cancel</mat-icon>
        <span class="btn-label">削除</span>
      </button>
    </div>
  </div>
</div>
