<!-- モバイル画面(ハンバーガーメニュー) -->
<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #sidenav class="sidenav" mode="over" closed>
    <mat-accordion>
      <!-- 案件 -->
      <mat-expansion-panel *ngIf="isVisible('order')">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon class="menu-icon-mobile">science</mat-icon>
            {{orderMenuName}}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <!-- 案件リスト -->
        <mat-card type="button" *ngIf="isVisible('orderManagement')"
          (click)="onMenuClick('orderManagement',$event); sidenav.toggle()">
          {{orderSubMenuName1}}
        </mat-card>
      </mat-expansion-panel>

      <!-- 新規採用 -->
      <mat-expansion-panel *ngIf="isVisible('information')">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon class="menu-icon-mobile">storefront</mat-icon>
            {{informationMenuName}}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <!-- 新規採用情報 -->
        <mat-card type="button" *ngIf="isVisible('newAdoptInformation')"
          (click)="onMenuClick('newAdoptInformation',$event); sidenav.toggle()">
          {{informationSubMenuName1}}
        </mat-card>
      </mat-expansion-panel>

      <!-- メンテナンス -->
      <mat-expansion-panel *ngIf="isVisible('maintenance')">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon class="menu-icon-mobile">build</mat-icon>
            {{maintenanceMenuName}}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <!-- 社員マスタ -->
        <mat-card type="button" *ngIf="isVisible('maintenanceEmployee')"
          (click)="onMenuClick('maintenanceEmployee',$event); sidenav.toggle()">
          {{maintenanceSubMenuName1}}
        </mat-card>
        <!-- 部署マスタ -->
        <mat-card type="button" *ngIf="isVisible('maintenanceDepartment')"
          (click)="onMenuClick('maintenanceDepartment',$event); sidenav.toggle()">
          {{maintenanceSubMenuName2}}
        </mat-card>
        <!-- 得意先マスタ -->
        <mat-card type="button" *ngIf="isVisible('maintenanceCustomer')"
          (click)="onMenuClick('maintenanceCustomer',$event); sidenav.toggle()">
          {{maintenanceSubMenuName3}}
        </mat-card>
      </mat-expansion-panel>

      <!-- 設定 -->
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon class="menu-icon-mobile">settings</mat-icon>
            {{settingMenuName}}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <!-- パスワード変更 -->
        <mat-card type="button" id="password-change-mobile" class="password-change-mobile"
          (click)="onMenuClick('passwordChange',$event); sidenav.toggle()">
          {{settingSubMenuName1}}
        </mat-card>
      </mat-expansion-panel>
    </mat-accordion>

    <!-- ホーム -->
    <mat-card type="button" id="home-mobile" class="home-mobile"
      (click)="onMenuClick('home',$event); sidenav.toggle()">
      <mat-icon class="menu-icon-mobile">home</mat-icon>
      {{homeName}}
    </mat-card>
    <!-- ログアウト -->
    <mat-card type="button" id="logout-mobile" class="logout-mobile"
      (click)="onMenuClick('logout',$event)">
      <mat-icon class="menu-icon-mobile">exit_to_app</mat-icon>
      {{logoutName}}
    </mat-card>
  </mat-sidenav>
</mat-sidenav-container>
<button *ngIf="menuControl" class="menu-icon-button" mat-icon-button (click)="sidenav.toggle()">
  <mat-icon id="menu-icon">menu</mat-icon>
</button>
<!-- PC画面(ヘッダーにメニューボタン) -->
<div class="toolBarMenu" *ngIf="menuControl">
  <ng-container>
    <!-- 案件 -->
    <button mat-button class="order-menu-pc" [matMenuTriggerFor]="order" *ngIf="isVisible('order')">
      {{orderMenuName}}
      <mat-icon class="menu-icon-pc">keyboard_arrow_down</mat-icon>
    </button>
    <mat-menu #order="matMenu" class="">
      <!-- 案件リスト -->
      <button mat-menu-item class="menu-list-pc-wide" (click)="onMenuClick('orderManagement',$event)" *ngIf="isVisible('orderManagement')">
        {{orderSubMenuName1}}
      </button>
    </mat-menu>

    <!-- 新規採用 -->
    <button mat-button class="information-menu-pc" [matMenuTriggerFor]="information" *ngIf="isVisible('information')">
      {{informationMenuName}}
      <mat-icon class="menu-icon-pc">keyboard_arrow_down</mat-icon>
    </button>
    <mat-menu #information="matMenu" class="">
      <!-- 新規採用情報 -->
      <button mat-menu-item class="menu-list-pc-wide" (click)="onMenuClick('newAdoptInformation',$event)" *ngIf="isVisible('newAdoptInformation')">
        {{informationSubMenuName1}}
      </button>
    </mat-menu>

    <!-- メンテナンス -->
    <button mat-button class="maintenance-menu-pc" [matMenuTriggerFor]="maintenance" *ngIf="isVisible('maintenance')">
      {{maintenanceMenuName}}
      <mat-icon class="menu-icon-pc">keyboard_arrow_down</mat-icon>
    </button>
    <mat-menu #maintenance="matMenu" class="maintenance-menu-sub">
      <!-- 社員マスタ -->
      <button mat-menu-item class="menu-list-pc" (click)="onMenuClick('maintenanceEmployee',$event)" *ngIf="isVisible('maintenanceEmployee')">
        {{maintenanceSubMenuName1}}
      </button>
      <!-- 部署マスタ -->
      <button mat-menu-item class="menu-list-pc" (click)="onMenuClick('maintenanceDepartment',$event)" *ngIf="isVisible('maintenanceDepartment')">
        {{maintenanceSubMenuName2}}
      </button>
      <!-- 得意先マスタ -->
      <button mat-menu-item class="menu-list-pc" (click)="onMenuClick('maintenanceCustomer',$event)" *ngIf="isVisible('maintenanceCustomer')">
        {{maintenanceSubMenuName3}}
      </button>
    </mat-menu>

    <!-- 設定 -->
    <button mat-button class="setting-menu-pc" [matMenuTriggerFor]="setting">
      {{settingMenuName}}
      <mat-icon class="menu-icon-pc">keyboard_arrow_down</mat-icon>
    </button>
    <mat-menu #setting="matMenu">
      <!-- パスワード変更 -->
      <button mat-menu-item class="menu-list-pc" (click)="onMenuClick('passwordChange',$event)">
        {{settingSubMenuName1}}
      </button>
    </mat-menu>

    <!-- ホーム -->
    <button mat-button class="home-pc" (click)="onMenuClick('home',$event)">
      {{homeName}}
      <mat-icon class="menu-icon-pc">home</mat-icon>
    </button>
    <!-- ログアウト -->
    <button mat-button class="logout-pc" (click)="onMenuClick('logout',$event)">
      {{logoutName}}
      <mat-icon class="menu-icon-pc">exit_to_app</mat-icon>
    </button>
  </ng-container>
</div>
