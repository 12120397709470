import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MaintenanceCustomerService } from '../../../maintenance/customer/maintenance-customer.service';
import { OrderManagementService } from '../order-management.service';
import { Mode } from './../entry-modify/order-entry-modify.component';

/** Enum：試作区分 */
export enum PrototypeKind {
  /** A.既存品 */
  Exist,
  /** B.既存品(要LT) */
  Exist_LineTest,
  /** C.既存品(ラボ試作) */
  Exist_Lab,
  /** D.既存生地形態変更(要LT) */
  FormChange,
  /** E.既存生地形態変更(要LT・ラボ確認) */
  FormChange_Confirm,
  /** F.既存生地形態変更(ラボ試作) */
  FormChange_Lab,
  /** G.新規LT */
  LineTest,
  /** H.ラボ試作 */
  Lab
}
/** Enum：試作ステータス */
export enum PrototypeStatus {
  /** 既存品確認 */
  Exist,
  /** ラボ試作中 */
  Lab,
  /** ラインテスト中 */
  LineTest,
  /** 発送待機 */
  Wait,
  /** 完了 */
  Done
}

/**
 * 試作依頼ダイアログコンポーネント
 */
@Component({
  selector: 'app-order-entry-modify-dialog',
  templateUrl: './order-entry-modify-dialog.component.html',
  styleUrls: ['./order-entry-modify-dialog.component.css']
})
export class OrderEntryModifyDialogComponent implements OnInit {

  // #region フィールド

  /** 試作区分 */
  public prototypeKind = '';
  /** 試作ステータス */
  public prototypeStatus: number;
  /** 費用 */
  public cost = '';
  /** サイズ */
  public size = '';
  /** 重量 */
  public weight = '';
  /** 納期 */
  public deadlineDate: Date;
  /** 納期の調整 */
  public isDeadlineAdjustment = false;
  /** 納品方法 */
  public deliveryMethod = 0;
  /** 発送数量 */
  public shippingQuantity = '';
  /** 発送数量の調整 */
  public isShippingAdjustment = false;
  /** 依頼日 */
  public requestDate = new Date();
  /** LOT(賞味期限) */
  public productionLot = '';
  /** 発送日 */
  public shippingDate: Date;
  /** 送り状 */
  public invoice = '';
  /** 依頼元と宛て名が同じ */
  public isSame = false;
  /** 依頼内容 */
  public requestContent = '';
  /** 備考 */
  public notes = '';

  /** 取得した試作区分一覧 */
  public prototypeKindList: any = [];
  /** 取得した試作ステータス一覧 */
  public prototypeStatusList: any = [];
  /** フィルタリングされる試作ステータス一覧 */
  public filteredPrototypeStatusList: Observable<any>;
  /** 取得した費用一覧 */
  public costList: any = [];
  /** 取得した納品方法一覧 */
  public deliveryMethodList: any = [];
  /** 取得した得意先親マスタ一覧 */
  public customerList: any = [];
  /** 取得した得意先子マスタ一覧 */
  public customerChildList: any = [];
  /** 取得した得意先子マスタ一覧 */
  public customerChildListTemp: any = [];

  /** 取得した発送情報一覧 */
  public dataShippingList: any = [];
  /** 取得した発送情報一覧 */
  public dataSource = new MatTableDataSource();
  /** 選択した発送情報 */
  public selected: any;

  /** 参照モードかどうか */
  public isReference = false;

  /** 画面表示項目 */
  public displayedColumns: string[] = [
    'shipping_date',                          // 発送日
    'invoice',                                // 送り状
    'destination_company_name',               // 宛て名会社
    'destination_department_or_factory_name', // 宛て名所属部課または工場
    'destination_staff',                      // 宛て名担当者
  ];

  /** フォームグループ */
  public formGroup: FormGroup;
  /** チェック：試作区分 */
  public prototypeKindCheck = new FormControl('', [Validators.required]);
  /** チェック：試作ステータス */
  public prototypeStatusCheck = new FormControl('', [Validators.required]);
  /** チェック：納品方法 */
  public deliveryMethodCheck = new FormControl('', [Validators.required]);
  /** チェック：発送数量 */
  public shippingQuantityCheck = new FormControl('', [Validators.required]);
  /** チェック：依頼日 */
  public requestDateCheck = new FormControl('', [Validators.required]);

  // #endregion

  // #region コンストラクター

  /**
   * 試作依頼ダイアログコンポーネントのコンストラクター
   * @constructor
   * @param data 画面から渡されたデータ
   * @param dialogRef MatDialogRef
   * @param service 案件管理関連サービス
   * @param custService 得意先メンテナンス関連サービス
   */
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private dialogRef: MatDialogRef<OrderEntryModifyDialogComponent>,
              private service: OrderManagementService,
              private custService: MaintenanceCustomerService) {
    // フォームグループの作成
    this.createForm();
  }

  // #endregion

  // #region 初期表示時の処理

  /**
   * 初期表示時の処理
   */
  async ngOnInit(): Promise<void> {
    // 試作区分の一覧取得
    await this.getPrototypeKindList();
    // 試作ステータスの一覧取得
    await this.getPrototypeStatusList();
    // 費用の一覧取得
    await this.getCostList();
    // 納品方法の一覧取得
    await this.getDeliveryMethod();
    // 得意先の一覧取得
    await this.getCustomerList();

    if (this.data.mode == Mode.Modify || this.data.mode == Mode.Reference) {
      // 試作依頼詳細の取得
      const rec: any = this.data.request;

      // 取得した結果を各項目にセットする
      this.prototypeKind = rec.prototype_kind;
      this.prototypeStatus = rec.prototype_status;
      this.cost = rec.cost;
      this.size = rec.size;
      this.weight = rec.weight;
      this.deadlineDate = rec.deadline;
      this.isDeadlineAdjustment = rec.deadline_adjustment;
      this.deliveryMethod = rec.delivery_method;
      this.shippingQuantity = rec.shipping_quantity;
      this.isShippingAdjustment = rec.shipping_adjustment;
      this.requestDate = rec.request_date;
      this.productionLot = rec.production_lot;
      this.requestContent = rec.request_content;
      this.notes = rec.notes;
      this.dataShippingList = rec.shippings;
      this.dataSource = new MatTableDataSource(rec.shippings);
    } else if (this.data.mode == Mode.Copy) {
      // 試作依頼詳細の取得(ディープコピー)
      const copy: any = JSON.parse(JSON.stringify(this.data.request));

      // 「発送日」「送り状」はコピー対象外とする
      copy.shippings.forEach(shipping => {
        shipping.shipping_date = null;
        shipping.invoice = '';
      });

      // 「試作ステータス」はコピーした試作区分の初期値とする
      this.setPrototypeKind(copy.prototype_kind);

      // 取得した結果を各項目にセットする
      // 「納期」「LOT(賞味期限)」はコピー対象外とする
      // 「依頼日」はコピーした日付とする
      this.cost = copy.cost;
      this.size = copy.size;
      this.weight = copy.weight;
      this.deliveryMethod = copy.delivery_method;
      this.shippingQuantity = copy.shipping_quantity;
      this.isShippingAdjustment = copy.shipping_adjustment;
      this.requestDate = new Date();
      this.requestContent = copy.request_content;
      this.notes = copy.notes;
      this.dataShippingList = copy.shippings;
      this.dataSource = new MatTableDataSource(copy.shippings);
    }

    // フォームグループを作成
    this.createForm();

    // Operatorsを設定
    this.setOperators();
  }

  /**
   * フォームグループを作成する。
   */
  private async createForm() {
    if (this.data.mode == Mode.Reference) {
      this.isReference = true;
      this.prototypeKindCheck = new FormControl({ value: this.prototypeKind, disabled: this.isReference });
      this.prototypeStatusCheck = new FormControl({ value: this.prototypeStatus, disabled: this.isReference });
      this.deliveryMethodCheck = new FormControl({ value: this.deliveryMethod, disabled: this.isReference });
      this.shippingQuantityCheck = new FormControl({ value: this.shippingQuantity, disabled: this.isReference });
      this.requestDateCheck = new FormControl({ value: this.requestDate, disabled: this.isReference });
    } else {
      this.isReference = false;
      this.prototypeKindCheck = new FormControl(this.prototypeKind, [Validators.required]);
      this.prototypeStatusCheck = new FormControl(this.prototypeStatus, [Validators.required]);
      this.deliveryMethodCheck = new FormControl(this.deliveryMethod, [Validators.required]);
      this.shippingQuantityCheck = new FormControl(this.shippingQuantity, [Validators.required]);
      this.requestDateCheck = new FormControl(this.requestDate, [Validators.required]);
    }

    this.formGroup = new FormGroup({
      prototypeKind: this.prototypeKindCheck,
      prototypeStatus: this.prototypeStatusCheck,
      deliveryMethod: this.deliveryMethodCheck,
      shippingQuantity: this.shippingQuantityCheck,
      requestDate: this.requestDateCheck
    });
  }

  /**
   * RxJSのOperatorsを設定する。
   */
  private setOperators() {
    // 試作ステータス
    this.filteredPrototypeStatusList = this.prototypeKindCheck.valueChanges.pipe(
      startWith(this.prototypeKind),
      map(value => {
        let values = [];
        switch (value) {
          case PrototypeKind.Exist:
            // 既存品確認、発送待機、完了
            values = [PrototypeStatus.Exist, PrototypeStatus.Wait, PrototypeStatus.Done];
            break;
          case PrototypeKind.Exist_LineTest:
          case PrototypeKind.FormChange:
            // ラインテスト中、発送待機、完了
            values = [PrototypeStatus.LineTest, PrototypeStatus.Wait, PrototypeStatus.Done];
            break;
          case PrototypeKind.Exist_Lab:
          case PrototypeKind.FormChange_Lab:
          case PrototypeKind.Lab:
            // ラボ試作中、発送待機、完了
            values = [PrototypeStatus.Lab, PrototypeStatus.Wait, PrototypeStatus.Done];
            break;
          case PrototypeKind.FormChange_Confirm:
          case PrototypeKind.LineTest:
            // ラボ試作中、ラインテスト中、発送待機、完了
            values = [PrototypeStatus.Lab, PrototypeStatus.LineTest, PrototypeStatus.Wait, PrototypeStatus.Done];
            break;
        }
        return this.prototypeStatusList.filter(v => values.includes(v.code));
      })
    );
    this.dataShippingList.forEach(shipping => {
      shipping.destinationCompanyCheck  = new FormControl({ value: shipping.destination_company_name, disabled: this.isReference });
      shipping.destinationDepartmentCheck  = new FormControl({ value: shipping.destination_department_or_factory_name, disabled: this.isReference });
      shipping.customerChildListTemp = [];

      // 宛て名会社
      shipping.filteredCustomerList = shipping.destinationCompanyCheck.valueChanges.pipe(
        startWith(shipping.destination_company_name),
        map(value => {
          const rec = this.customerList.filter(v => v.company_name === value);
          const customerNo = (rec && rec.length) ? rec[0].customer_no : '';
          shipping.customerChildListTemp = this.customerChildList.filter(v => v.customer_no === customerNo);
          shipping.destinationDepartmentCheck.setValue(shipping.destination_department_or_factory_name);
          return (value) ? this.customerList.filter(v => v.company_name.includes(value)) : this.customerList;
        })
      );
      // 宛て名所属部課または工場
      shipping.filteredCustomerChildList = shipping.destinationDepartmentCheck.valueChanges.pipe(
        startWith(shipping.destination_department_or_factory_name),
        map(value => (value) ? shipping.customerChildListTemp.filter(v => v.department_or_factory_name.includes(value)) : shipping.customerChildListTemp)
      );
    });
  }

  // #endregion

  // #region データ取得時の処理

  /**
   * 試作区分の一覧を取得する。
   */
  private async getPrototypeKindList() {
    // 試作区分の一覧取得
    const rec: any = await this.service.getCodeNameList('prototype_kind');
    // 先頭に空行を追加する
    rec.unshift({ code: '', name: '' });
    // 取得した結果をセットする
    this.prototypeKindList = rec;
  }

  /**
   * 試作ステータスの一覧を取得する。
   */
  private async getPrototypeStatusList() {
    // 試作ステータスの一覧取得
    const rec: any = await this.service.getCodeNameList('prototype_status');
    // 先頭に空行を追加する
    rec.unshift({ code: '', name: '' });
    // 取得した結果をセットする
    this.prototypeStatusList = rec;
  }

  /**
   * 費用の一覧を取得する。
   */
  private async getCostList() {
    // 費用の一覧取得
    const rec: any = await this.service.getCodeNameList('cost');
    // 先頭に空行を追加する
    rec.unshift({ code: '', name: '' });
    // 取得した結果をセットする
    this.costList = rec;
  }

  /**
   * 納品方法の一覧を取得する。
   */
  private async getDeliveryMethod() {
    // 納品方法の一覧取得
    const rec: any = await this.service.getCodeNameList('delivery_method');
    // 取得した結果をセットする
    this.deliveryMethodList = rec;
  }

  /**
   * 得意先の一覧を取得する。
   */
  private async getCustomerList() {
    // 得意先親マスタの一覧取得
    const rec1: any = await this.custService.getCustomerParentList();
    // 得意先子マスタの一覧取得
    const rec2: any = await this.custService.getCustomerChildList();
    // 取得した結果をセットする
    this.customerList = rec1;
    this.customerChildList = rec2;
  }

  // #endregion

  // #region 項目選択時の処理

  /**
   * 試作区分選択時の処理
   * @param value 試作区分
   */
  public setPrototypeKind(value) {
    // 選択値をセット
    this.prototypeKind = value;

    // 試作ステータスの初期値をセット
    switch (value) {
      case PrototypeKind.Exist:
        this.prototypeStatus = PrototypeStatus.Exist;
        this.prototypeStatusCheck.setValue(this.prototypeStatus);
        break;
      case PrototypeKind.Exist_LineTest:
      case PrototypeKind.FormChange:
        this.prototypeStatus = PrototypeStatus.LineTest;
        this.prototypeStatusCheck.setValue(this.prototypeStatus);
        break;
      case PrototypeKind.Exist_Lab:
      case PrototypeKind.FormChange_Confirm:
      case PrototypeKind.FormChange_Lab:
      case PrototypeKind.LineTest:
      case PrototypeKind.Lab:
        this.prototypeStatus = PrototypeStatus.Lab;
        this.prototypeStatusCheck.setValue(this.prototypeStatus);
        break;
      default:
        break;
    }
  }

  /**
   * 試作ステータス選択時の処理
   * @param event 選択時のイベント
   */
  public setPrototypeStatus(event) {
    // 選択値をセット
    this.prototypeStatus = event.value;
  }

  /**
   * 費用選択時の処理
   * @param event 選択時のイベント
   */
  public setCost(event) {
    // 選択値をセット
    this.cost = event.value;
  }

  /**
   * 納品方法選択時の処理
   * @param event 選択時のイベント
   */
  public setDeliveryMethod(event) {
    // 選択値をセット
    this.deliveryMethod = event.value;
  }

  /**
   * 行選択時の処理
   * @param value 行選択した発送情報
   */
  public selectedRow(value) {
    // 選択した明細をセットする
    this.selected = value;
  }

  // #endregion

  // #region ボタン押下時の処理

  /**
   * 行追加ボタン押下時の処理
   */
  public onAddedRow() {
    console.log('@@@@ 行追加ボタン押下');

    if (this.isSame) {
      // 依頼元と宛て名が同じ場合
      const rec: any = this.data.request;
      const shipping = {
        shipping_date: null,
        invoice: '',
        destination: '',
        destination_company_no: '',
        destination_company_name: rec.requester_company_name,
        destination_department_or_factory_no: null,
        destination_department_or_factory_name: rec.requester_department_or_factory_name,
        destination_staff: rec.requester_staff,
        destinationCompanyCheck: new FormControl(rec.requester_company_name),
        destinationDepartmentCheck: new FormControl(rec.requester_department_or_factory_name),
        filteredCustomerList: null,
        filteredCustomerChildList: null,
        customerChildListTemp: []
      };
      shipping.filteredCustomerList = shipping.destinationCompanyCheck.valueChanges.pipe(
        startWith(shipping.destination_company_name),
        map(value => {
          const rec = this.customerList.filter(v => v.company_name === value);
          const customerNo = (rec && rec.length) ? rec[0].customer_no : '';
          shipping.customerChildListTemp = this.customerChildList.filter(v => v.customer_no === customerNo);
          shipping.destinationDepartmentCheck.setValue(shipping.destination_department_or_factory_name);
          return (value) ? this.customerList.filter(v => v.company_name.includes(value)) : this.customerList;
        })
      );
      shipping.filteredCustomerChildList = shipping.destinationDepartmentCheck.valueChanges.pipe(
        startWith(shipping.destination_department_or_factory_name),
        map(value => (value) ? shipping.customerChildListTemp.filter(v => v.department_or_factory_name.includes(value)) : shipping.customerChildListTemp)
      );
      this.dataShippingList.push(shipping);
    } else {
      const shipping = {
        shipping_date: null,
        invoice: '',
        destination: '',
        destination_company_no: '',
        destination_company_name: '',
        destination_department_or_factory_no: null,
        destination_department_or_factory_name: '',
        destination_staff: '',
        destinationCompanyCheck: new FormControl(''),
        destinationDepartmentCheck: new FormControl(''),
        filteredCustomerList: null,
        filteredCustomerChildList: null,
        customerChildListTemp: []
      };
      shipping.filteredCustomerList = shipping.destinationCompanyCheck.valueChanges.pipe(
        startWith(shipping.destination_company_name),
        map(value => {
          const rec = this.customerList.filter(v => v.company_name === value);
          const customerNo = (rec && rec.length) ? rec[0].customer_no : '';
          shipping.customerChildListTemp = this.customerChildList.filter(v => v.customer_no === customerNo);
          shipping.destinationDepartmentCheck.setValue(shipping.destination_department_or_factory_name);
          return (value) ? this.customerList.filter(v => v.company_name.includes(value)) : this.customerList;
        })
      );
      shipping.filteredCustomerChildList = shipping.destinationDepartmentCheck.valueChanges.pipe(
        startWith(shipping.destination_department_or_factory_name),
        map(value => (value) ? shipping.customerChildListTemp.filter(v => v.department_or_factory_name.includes(value)) : shipping.customerChildListTemp)
      );
      this.dataShippingList.push(shipping);
    }
    this.dataSource = new MatTableDataSource(this.dataShippingList);
  }

  /**
   * 行削除ボタン押下時の処理
   */
  public onDeletedRow() {
    console.log('@@@@ 行削除ボタン押下');
    if (!this.selected) alert('発送情報を選択してください。');

    // 選択した行を保持している一覧から削除し、再度画面にセットする
    this.dataShippingList = this.dataShippingList.filter(v => v !== this.selected);
    this.dataSource = new MatTableDataSource(this.dataShippingList);

    // 選択した発送情報をクリア
    this.selected = null;
  }

  /**
   * 決定ボタン押下時の処理
   */
  async onClick() {
    console.log('@@@@ 決定ボタン押下');

    this.dataShippingList.forEach(shipping => {
      // チェック：宛て名会社
      // 宛て名所属部課または工場が入力されている場合、条件付き必須とする
      if (!shipping.destination_company_name && shipping.destination_department_or_factory_name) {
        alert('宛て名所属部課または工場を入力する場合は宛て名会社を入力してください。');
        return;
      }

      // 宛て名を文字列連結してセットする
      shipping.destination =
        shipping.destination_company_name + ' ' +
        shipping.destination_department_or_factory_name + ' ' +
        shipping.destination_staff;
    });

    // 入力した内容を返却する
    const result = {
      prototype_kind: this.prototypeKind,
      prototype_kind_name: this.prototypeKindList.find(v => v.code === this.prototypeKind).name,
      prototype_status: this.prototypeStatus,
      prototype_status_name: this.prototypeStatusList.find(v => v.code === this.prototypeStatus).name,
      cost: (this.cost === '') ? null : this.cost,
      size: this.size,
      weight: this.weight,
      deadline: this.deadlineDate,
      deadline_adjustment: this.isDeadlineAdjustment,
      delivery_method: this.deliveryMethod,
      shipping_quantity: this.shippingQuantity,
      shipping_adjustment: this.isShippingAdjustment,
      request_date: this.requestDate,
      production_lot: this.productionLot,
      request_content: this.requestContent,
      notes: this.notes,
      shippings: this.dataShippingList
    };

    // ダイアログを閉じる
    this.dialogRef.close(result);
  }

  // #endregion
}
