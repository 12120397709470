<h2 mat-dialog-title>添付送信</h2>
<mat-dialog-content>
  <div class="container">
    <div>
      <!-- 送信先 -->
      <mat-label>送信先</mat-label>
      <mat-selection-list #selectDepartment>
        <mat-list-option class="select-area"
          [checkboxPosition]="after"
          *ngFor="let department of departmentList"
          [value]="department.mail_address"
          [selected]="department.selected">
          {{department.department_name}}
        </mat-list-option>
      </mat-selection-list>
    </div>
    <div>
      <mat-form-field appearance="standard">
        <mat-label>コメント</mat-label>
        <textarea id="comment" type="text" matInput name="comment" [(ngModel)]="comment"></textarea>
      </mat-form-field>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div fxLayout="row wrap" fxLayoutAlign="center" class="button-container">
    <button mat-raised-button color="primary" mat-dialog-close>キャンセル</button>
    <button mat-raised-button color="primary" (click)="onSend(selectDepartment.selectedOptions.selected)">送信</button>
  </div>
</mat-dialog-actions>
