import { Injectable } from '@angular/core';
import { Actions } from '../class/actions';
import { AppConfig } from '../class/appconfig.class';
import { HttpReqOptions } from '../interface/httpOption.interface';
import { CryptoService } from './crypto.service';
import { HttpService } from './http.service';
import { LogoutService } from './logout.service';
import { MenuService } from './menu.service';

/**
 * スタートアップサービス
 *
 * サーバからログオン後に必要となる情報を設定・取得する
 */
@Injectable()
export class StartupService {

  /**
   * スタートアップサービスのコンストラクター
   * @constructor
   */
  constructor(private actions: Actions,
              private cryptoService: CryptoService,
              private httpService: HttpService,
              private logoutService: LogoutService,
              private menuService: MenuService) {
    console.log('@@@@ StartupService生成');
  }

  /**
   * 社員データと暗号キーをサーバーから取得して保存する
   */
  async getUserData() {
    // ユーザー認証リクエスト送信
    const config: HttpReqOptions = {url: AppConfig.baseUrl + '/api/user'};
    const res: any = await this.httpService.send('get', config);

    // アプリの動作設定値をサーバーからの指示で上書き
    const bconfig = res.data.browserConfig;
    // 自動ログアウトまでの時間
    AppConfig.AUTO_LOGOUT = bconfig.AUTO_LOGOUT;
    // トークンの有効期限確認間隔
    AppConfig.CHECK_TOKEN_INTERVAL = bconfig.CHECK_TOKEN_INTERVAL;
    // ログアウトタイマー開始
    this.logoutService.startMonitorLogoutTime();

    // 暗号キーの設定
    this.cryptoService.setCryptpKey(bconfig.DB_KEY);
    // 社員番号の設定
    this.actions.update({employeeNo: res.data.userData.employee_no});
    // ユーザー情報の設定
    this.menuService.setEmployeeNo(res.data.userData.employee_no);
    this.menuService.setEmployeeName(res.data.userData.employee_name);
    // 権限の設定
    this.menuService.setUserAuthority(res.data.userData.authority);

    console.log('@@@ 社員情報の設定完了');
  }
}
