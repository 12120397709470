
<form #checkPass="ngForm" class="passwordChange-container">
  <!-- エラーメッセージ -->
  <div class="err-message">{{errMessage}}</div>
  <div>
    <mat-form-field
      class="input-position"
      appearance="fill"
    >
      <mat-label>旧パスワード</mat-label>
      <input
        matInput
        type="password"
        name="oldPassword"
        [(ngModel)]="oldPassword"
        [formControl]="checkOldPass"
        required
        (input)="messageClear()"
      >
      <div
        class="check"
        *ngIf="checkOldPass.invalid && (checkOldPass.dirty || checkOldPass.touched)">
        <span
          *ngIf="checkOldPass.hasError('required')">
          旧パスワードは必須です。
        </span>
      </div>
    </mat-form-field>
  </div>
  <div>
    <mat-form-field class="input-position" appearance="fill">
      <mat-label>新パスワード</mat-label>
      <input
        matInput
        type="password"
        name="newPassword"
        [(ngModel)]="newPassword"
        [formControl]="checkNewPass"
        required
        (input)="messageClear()"
      >
      <div
        class="check"
        *ngIf="checkNewPass.invalid && (checkNewPass.dirty || checkNewPass.touched)">
        <span
          *ngIf="checkNewPass.hasError('required')">
          新パスワードは必須です。
        </span>
        <span
          *ngIf="checkNewPass.hasError('pattern')">
          大小英数字記号を使った8桁以上のパスワードにしてください。
        </span>
      </div>
    </mat-form-field>
  </div>
  <div>
    <mat-form-field class="input-position" appearance="fill">
      <mat-label>新パスワード確認</mat-label>
      <input
        matInput
        type="password"
        name="passwordConfirm"
        [(ngModel)]="passwordConfirm"
        [formControl]="checkPassConfirm"
        required
        (input)="messageClear()"
      >
      <div
        class="check"
        *ngIf="checkPassConfirm.invalid && (checkPassConfirm.dirty || checkPassConfirm.touched)">
        <span
          *ngIf="checkPassConfirm.hasError('required')">
          パスワード確認は必須です。
        </span>
      </div>
    </mat-form-field>
  </div>
  <div>
    <button
      mat-raised-button
      id="changeButton"
      class="btn-password-change"
      color="primary"
      type="submit"
      [disabled]="checkPass.invalid"
      (click)="submit()">
      変更
    </button>
  </div>
</form>
