import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { HeaderTitleService } from '../../shared/service/headerTitle.service';
import { AppConfig } from '../../shared/class/appconfig.class';
import { MenuService } from '../../shared/service/menu.service';

/**
 * ホームコンポーネント
 */
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  /** ログインユーザー向けメッセージ */
  accountMessage: string;

  /**
   * ホームコンポーネントのコンストラクター
   * @constructor
   */
  constructor(private title: Title,
              private headerTitle: HeaderTitleService,
              private menuService: MenuService) {
    // ページタイトル設定
    this.title.setTitle('ホーム | ' + AppConfig.SYSTEM_NANE);
  }

  /**
   * 初期処理の実施
   *
   * ヘッダータイトルの設定と通知メッセージを取得・設定する。
   */
  async ngOnInit(): Promise<void> {
    // ヘッダータイトル設定
    this.headerTitle.setTitle(AppConfig.SYSTEM_NANE);

    // ユーザー情報を表示する
    const employeeName = this.menuService.getEmployeeName();
    this.accountMessage = 'ようこそ、' + employeeName + ' さん';
  }
}
