import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NavigationService } from '../shared/service/navigation.service';
import { OrderListComponent } from './management/list/order-list.component';
import { OrderEntryModifyComponent, Mode } from './management/entry-modify/order-entry-modify.component';

/**
 * 遷移先の指定
 *
 * path: BaseURL以降のパス<br>
 * component: 遷移先のコンポーネント
 */
@NgModule({
  imports: [RouterModule.forChild([
    {
      path: 'order/management',
      component: OrderListComponent,
      canActivate: [NavigationService],
      canDeactivate: [NavigationService],
    },
    {
      path: 'order/management/entry',
      component: OrderEntryModifyComponent,
      canActivate: [NavigationService],
      canDeactivate: [NavigationService],
      data: {
        mode: Mode.Entry
      }
    },
    {
      path: 'order/management/copy',
      component: OrderEntryModifyComponent,
      canActivate: [NavigationService],
      canDeactivate: [NavigationService],
      data: {
        mode: Mode.Copy
      }
    },
    {
      path: 'order/management/modify',
      component: OrderEntryModifyComponent,
      canActivate: [NavigationService],
      canDeactivate: [NavigationService],
      data: {
        mode: Mode.Modify
      }
    },
    {
      path: 'order/management/reference',
      component: OrderEntryModifyComponent,
      canActivate: [NavigationService],
      canDeactivate: [NavigationService],
      data: {
        mode: Mode.Reference
      }
    },
  ])],
  exports: [RouterModule]
})
export class OrderRoutingModule { }
