import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NavigationService } from '../shared/service/navigation.service';
import { NewAdoptListComponent } from './new-adopt/list/new-adopt-list.component';
import { NewAdoptEntryModifyComponent } from './new-adopt/entry-modify/new-adopt-entry-modify.component';

/**
 * 遷移先の指定
 *
 * path: BaseURL以降のパス<br>
 * component: 遷移先のコンポーネント
 */
@NgModule({
  imports: [RouterModule.forChild([
    {
      path: 'information/new-adopt',
      component: NewAdoptListComponent,
      canActivate: [NavigationService],
      canDeactivate: [NavigationService],
    },
    {
      path: 'information/new-adopt/entry',
      component: NewAdoptEntryModifyComponent,
      canActivate: [NavigationService],
      canDeactivate: [NavigationService],
      data: {
        mode: '1'
      }
    },
    {
      path: 'information/new-adopt/modify',
      component: NewAdoptEntryModifyComponent,
      canActivate: [NavigationService],
      canDeactivate: [NavigationService],
      data: {
        mode: '2'
      }
    }
  ])],
  exports: [RouterModule]
})
export class InformationRoutingModule { }
