import { NgModule, ErrorHandler } from '@angular/core';

// コンポーネント
import { NewAdoptListComponent } from './new-adopt/list/new-adopt-list.component';
import { NewAdoptEntryModifyComponent } from './new-adopt/entry-modify/new-adopt-entry-modify.component';

// オプションモジュール
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { GlobalErrorService } from '../shared/service/globalError.service';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MaterialModule } from '../shared/class/material.module';
import { InformationRoutingModule } from './information-routing.module';


@NgModule({
  declarations: [
    NewAdoptListComponent,
    NewAdoptEntryModifyComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    DragDropModule,
    MaterialModule,
    InformationRoutingModule
  ],
  exports: [
    NewAdoptListComponent,
    NewAdoptEntryModifyComponent
  ],
  providers: [
    { provide: ErrorHandler, useClass: GlobalErrorService }
  ]
})
export class InformationModule { }
