import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LogoutService } from './logout.service';
import { NavigationService } from './navigation.service';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

/**
 * メニュー関連サービス
 */
@Injectable()
export class MenuService {
  /**
   * メニュー関連サービスのコンストラクター
   * @constructor
   * @param logoutService ログアウトサービス
   * @param transitionService ナビゲーションサービス
   * @param router ルーター
   */
  constructor(private logoutService: LogoutService,
              private transitionService: NavigationService,
              private router: Router) {
  }

  /** メニュー制御用 */
  menuControl = new BehaviorSubject(false);

  /**
   * ヘッダーにあるメニューの表示/非表示を制御する
   * @param menu メニューの表示/非表示
   */
  setMenuControl(menu: boolean) {
    this.menuControl.next(menu);
  }

  /** 社員番号 */
  employeeNo: string;

  /**
   * 社員番号を設定する
   * @param value 社員番号
   */
  setEmployeeNo(value: string) {
    this.employeeNo = value;
  }

  /**
   * 社員名を取得する
   */
  getEmployeeNo() {
    return this.employeeNo;
  }

  /** 社員名 */
  employeeName: string;

  /**
   * 社員名を設定する
   * @param value 社員名
   */
  setEmployeeName(value: string) {
    this.employeeName = value;
  }

  /**
   * 社員名を取得する
   */
  getEmployeeName() {
    return this.employeeName;
  }

  /** ユーザー権限 */
  userAuthority = null;

  /**
   * ユーザー権限を設定する
   * @param value ユーザー権限
   */
  setUserAuthority(value: any) {
    this.userAuthority = value;
  }

  /**
   * ユーザー権限を取得する
   */
  getUserAuthority() {
    return this.userAuthority;
  }

  /**
   * メニューの表示権限があるか確認する
   * @param id メニューID
   */
  isVisible(id: string) {
    if (this.userAuthority === null) return false;
    const result = this.userAuthority.find((v) => {
      return v.function_name_eng == id;
    });
    if (result != undefined) return true;
    return false;
  }

  /**
   * メニューの画面遷移処理
   * @param item メニュー名
   */
  onSelectMenu(item: string) {
    switch (item) {
      case 'home':
        console.log('@@@@ ホーム');
        this.goHome();
        break;

      case 'orderManagement':
        console.log('@@@@ 案件リスト画面へ遷移');
        this.goOrderManagement();
        break;

      case 'newAdoptInformation':
        console.log('@@@@ 新規採用情報リスト画面へ遷移');
        this.goNewAdoptInformation();
        break;

      case 'maintenanceEmployee':
        console.log('@@@@ 社員メンテナンス画面へ遷移');
        this.goMaintenanceEmployee();
        break;
      case 'maintenanceDepartment':
        console.log('@@@@ 部署メンテナンス画面へ遷移');
        this.goMaintenanceDepartment();
        break;
      case 'maintenanceCustomer':
        console.log('@@@@ 得意先メンテナンス画面へ遷移');
        this.goMaintenanceCustomer();
        break;

      case 'passwordChange':
        console.log('@@@@ パスワード変更画面へ遷移');
        this.goPasswordChange();
        break;
      case 'logout':
        console.log('@@@@ ログアウト');
        this.logoutService.logout();
        break;
    }
  }
  /**
   * ホームへの画面遷移
   */
  goHome() {
    // 画面遷移の許可
    this.transitionService.canTransition = true;
    // 画面遷移
    this.router.navigate(['/home']);
  }
  /**
   * パスワード変更への画面遷移
   */
  goPasswordChange() {
    // 画面遷移の許可
    this.transitionService.canTransition = true;
    // 画面遷移
    this.router.navigate(['/password-change']);
  }
  /**
   * 社員メンテナンスへの画面遷移
   */
  goMaintenanceEmployee() {
    // 画面遷移の許可
    this.transitionService.canTransition = true;
    // 画面遷移
    this.router.navigate(['/maintenance/employee']);
  }
  /**
   * 部署メンテナンスへの画面遷移
   */
  goMaintenanceDepartment() {
    // 画面遷移の許可
    this.transitionService.canTransition = true;
    // 画面遷移
    this.router.navigate(['/maintenance/department']);
  }
  /**
   * 得意先メンテナンスへの画面遷移
   */
  goMaintenanceCustomer() {
    // 画面遷移の許可
    this.transitionService.canTransition = true;
    // 画面遷移
    this.router.navigate(['/maintenance/customer']);
  }

  /**
   * 案件リストへの画面遷移
   */
  goOrderManagement() {
    // 画面遷移の許可
    this.transitionService.canTransition = true;
    // 画面遷移
    this.router.navigate(['/order/management']);
  }

  /**
   * 案件リストへの画面遷移
   */
  goNewAdoptInformation() {
    // 画面遷移の許可
    this.transitionService.canTransition = true;
    // 画面遷移
    this.router.navigate(['/information/new-adopt']);
  }
}
