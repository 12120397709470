<div class="container" fxLayout="column">
  <div id="customer-container" fxFlex="grow">
    <div id="header-container">
      <div fxLayout="row" fxLayout.xs="column">
        <div fxFlex>
          <!-- 得意先番号 -->
          <mat-form-field appearance="standard">
            <mat-label>得意先番号</mat-label>
            <input matInput type="tel" maxlength="4" name="customerNo" [(ngModel)]="customerNo" *ngIf="inputMode"
              (change)="customerNo=customerNo.padStart(4, '0');" oninput="value = value.replace(/[^0-9]+/i,'')"
              required>
          </mat-form-field>
        </div>
      </div>
      <div fxLayout="row" fxLayout.xs="column">
        <div fxFlex="30" fxFlex.xs="100">
          <!-- 得意先会社名 -->
          <mat-form-field appearance="standard" class="form-name">
            <mat-label>得意先会社名</mat-label>
            <input matInput name="customerCompanyName" [(ngModel)]="customerCompanyName" *ngIf="inputMode" [formControl]="companynameCheck" required>
            <mat-error *ngIf="companynameCheck.hasError('required')">必須項目です。</mat-error>
          </mat-form-field>
        </div>
        <div fxFlex="30" fxFlex.xs="100">
          <!-- 郵便番号 -->
          <mat-form-field appearance="standard">
            <mat-label>郵便番号</mat-label>
            <input matInput maxlength="8" name="postalCode" [(ngModel)]="postalCode" *ngIf="inputMode" [formControl]="postalCodeCheck">
            <mat-error *ngIf="postalCodeCheck.hasError('pattern')">郵便番号を入力してください。</mat-error>
          </mat-form-field>
        </div>
        <div fxFlex="40" fxFlex.xs="100">
          <!-- 住所 -->
          <mat-form-field appearance="standard" class="form-address">
            <mat-label>住所</mat-label>
            <textarea type="text" matInput name="address" [(ngModel)]="address" *ngIf="inputMode" autocomplete="off"></textarea>
          </mat-form-field>
        </div>
      </div>
    </div>

    <table mat-table [dataSource]="dataSource" class="customer-list">

      <!-- 得意先子番号 -->
      <ng-container matColumnDef="child_no">
        <th mat-header-cell *matHeaderCellDef [style.display]="'none'" class="column-width-1"></th>
        <td mat-cell *matCellDef="let employee" [style.display]="'none'"> {{employee.child_no}} </td>
      </ng-container>

      <!-- 所属部課または工場 -->
      <ng-container matColumnDef="department_or_factory_name">
        <th mat-header-cell *matHeaderCellDef class="column-width-5">所属部課または工場</th>
        <td mat-cell *matCellDef="let customer">
          <div id="department-or-factory-name">{{customer.department_or_factory_name}}</div></td>
      </ng-container>

      <!-- 所属部課または工場の郵便番号 -->
      <ng-container matColumnDef="postal_code">
        <th mat-header-cell *matHeaderCellDef class="column-width-3">郵便番号</th>
        <td mat-cell *matCellDef="let customer" class="pre-line"> {{customer.postal_code}} </td>
      </ng-container>

      <!-- 所属部課または工場の住所 -->
      <ng-container matColumnDef="address">
        <th mat-header-cell *matHeaderCellDef class="column-width-5">住所</th>
        <td mat-cell *matCellDef="let customer"> {{customer.address}} </td>
      </ng-container>

      <!-- 所属部課または工場の電話番号 -->
      <ng-container matColumnDef="tel_no">
        <th mat-header-cell *matHeaderCellDef class="column-width-4">電話番号</th>
        <td mat-cell *matCellDef="let customer"> {{customer.tel_no}} </td>
      </ng-container>

      <!-- 代表 -->
      <ng-container matColumnDef="representative">
        <th mat-header-cell *matHeaderCellDef class="column-width-1">代表</th>
        <td mat-cell *matCellDef="let customer" class="td-center"> {{customer.representative | i18nPlural: isRepresentative}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let customer; columns: displayedColumns;"
        (click)="selectedRow(customer)"
        [ngClass]="{'selected': selected == customer}">
      </tr>
    </table>
  </div>

  <div id="footer-container">
    <div fxLayout="row" fxLayoutAlign="center" class="button-container">
      <!-- 行追加ボタン -->
      <button mat-raised-button color="primary" (click)="onAdd()">
        <mat-icon>add_box</mat-icon>
        <span class="btn-label">行追加</span>
      </button>
      <!-- 修正ボタン -->
      <button mat-raised-button color="primary" (click)="onModify()" [disabled]="isDisabledModify">
        <mat-icon>edit</mat-icon>
        <span class="btn-label">修正</span>
      </button>
      <!-- 削除ボタン -->
      <button mat-raised-button color="primary" (click)="onDelete()" [disabled]="isDisabledDelete">
        <mat-icon>cancel</mat-icon>
        <span class="btn-label">削除</span>
      </button>
    </div>
    <div fxLayout="row" fxLayoutAlign="center" class="button-container">
      <!-- 戻る -->
      <button mat-raised-button color="primary" id="cancel-button" (click)="onCancel()">
        <mat-icon>keyboard_backspace</mat-icon>
        <span class="btn-label">戻る</span>
      </button>
      <!-- 登録 -->
      <button mat-raised-button color="primary" id="regist-button" (click)="onRegist()">
        <mat-icon>check</mat-icon>
        <span class="btn-label">{{registButtonName}}</span>
      </button>
    </div>
  </div>
</div>
