<div class="container" fxLayout="column">
  <div id="table-container" fxFlex="grow">

    <table mat-table #table [dataSource]="dataSource" class="department-list" cdkDropList [cdkDropListData]="dataSource"
      (cdkDropListDropped)="onListDrop($event)">

      <!-- 部署番号 -->
      <ng-container matColumnDef="department_no">
        <th mat-header-cell *matHeaderCellDef class="column-width-2">部署番号</th>
        <td mat-cell *matCellDef="let department">
          {{department.department_no}}
        </td>
      </ng-container>

      <!-- 部署名 -->
      <ng-container matColumnDef="department_name">
        <th mat-header-cell *matHeaderCellDef class="column-width-3">部署名</th>
        <td mat-cell *matCellDef="let department">
          {{department.department_name}}
        </td>
      </ng-container>

      <!-- メールアドレス -->
      <ng-container matColumnDef="mail_address">
        <th mat-header-cell *matHeaderCellDef class="column-width-5">メールアドレス</th>
        <td mat-cell *matCellDef="let employee">
          {{employee.mail_address}} </td>
      </ng-container>

      <!-- 削除フラグ -->
      <ng-container matColumnDef="delete_flag">
        <th mat-header-cell *matHeaderCellDef class="column-width-1 last-column">削除済</th>
        <td mat-cell *matCellDef="let department" class="td-center last-column">
          {{department.delete_flag | i18nPlural: isDeleted}}
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let department; columns: displayedColumns;" cdkDrag
        (click)="selectedRow(department)"
        [ngClass]="{'selected': selected == department}">
      </tr>
    </table>
  </div>
  <div id="footer-container">
    <!-- 削除済を含む -->
    <span id="deletecheck-position">削除済も含む</span><mat-checkbox (change)="setDeleteCheck($event)" [checked]="deleteCheckStatus"></mat-checkbox>
    <br>
    <div fxLayout="row wrap" fxLayoutAlign="center" fxLayoutAlign.xs="space-between" class="button-container">
      <!-- 新規登録ボタン -->
      <button mat-raised-button color="primary" (click)="onEntry()">
        <mat-icon>add_box</mat-icon>
        <span class="btn-label">新規登録</span>
      </button>
      <!-- 修正ボタン -->
      <button mat-raised-button color="primary" (click)="onModify()" [disabled]="isDisabledModify">
        <mat-icon>edit</mat-icon>
        <span class="btn-label">修正</span>
      </button>
      <!-- 削除ボタン -->
      <button mat-raised-button color="primary" (click)="onDelete(departmentNo)" [disabled]="isDisabledDelete">
        <mat-icon>cancel</mat-icon>
        <span class="btn-label">削除</span>
      </button>
      <!-- 復元ボタン -->
      <button mat-raised-button color="primary" (click)="onRestore()" [disabled]="isDisabledRestore">
        <mat-icon>settings_backup_restore</mat-icon>
        <span class="btn-label">復元</span>
      </button>
    </div>
  </div>
</div>

