import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MaintenanceDepartmentService } from '../maintenance-department.service';

/**
 * 部署マスタ登録ダイアログ
 */
@Component({
  selector: 'app-department-entry-modify-dialog',
  templateUrl: './department-entry-modify-dialog.component.html',
  styleUrls: ['./department-entry-modify-dialog.component.css']
})
export class DepartmentEntryModifyDialogComponent implements OnInit {

  /** 部署番号 */
  public departmentNo = '';
  /** 部署名 */
  public departmentName = '';
  /** メールアドレス */
  public mailAddress = '';
  /** 入力でFormControlを使用する項目の表示切替 */
  public inputMode = true;
  /** 入力でFormControlを使用する項目の表示切替(部署番号用) */
  public inputNumberMode = true;
  /** 項目制御(参照モード用)(部署番号用) */
  public itemNumberControl = true;
  /** 項目制御(参照モード用) */
  public itemControl = true;
  /** 参照モードのためFormControlを使用しない項目の表示切替 */
  public compareMode = false;
  /** 参照モードのためFormControlを使用しない項目の表示切替(部署名用) */
  public compareNameMode = false;
  /** ボタン名称 */
  public buttonName: string;
  /** 登録する部署マスタ */
  public departmentData: any;

  /**
   * コンストラクタ
   * @constructor
   * @param data 一覧画面から取得したデータ
   */
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private dialogRef: MatDialogRef<DepartmentEntryModifyDialogComponent>,
              private changeDetectorRef: ChangeDetectorRef,
              private depService: MaintenanceDepartmentService) { }

  /**
   * 初期処理
   * ボタン名を設定、一覧画面から受け取った値をセットする。
   */
  ngOnInit(): void {
    if (this.data.deleteflag) {
      this.compareNameMode = true;
      this.inputMode = false;
    }
    if (this.data.departmentno) {
      this.departmentNo = this.data.departmentno;
      this.departmentName = this.data.departmentname;
      this.mailAddress = this.data.mailaddress;
      this.buttonName = '修正';
      this.inputNumberMode = false;
      this.compareMode = true;

      // ExpressionChangedAfterItHasBeenCheckedError対策
      this.changeDetectorRef.detectChanges();
    } else {
      this.buttonName = '新規登録';
    }
  }

  /** チェック：メールアドレス */
  public mailAddressCheck = new FormControl('', [Validators.required, Validators.email]);

  /**
   * 部署番号の変更イベント処理
   * @param value 入力された値
   */
  onChange(value: string) {
    const newValue = value.padStart(4, '0');
    this.departmentNo = newValue;
    return newValue;
  }

  /**
   * OKボタンクリック
   * 入力された情報を部署マスタに反映し、一覧画面に戻る
   */
  async onClick() {
    console.log('@@@@ 登録・更新処理');

    if (!this.departmentNo) {
      console.log('入力必須項目が未入力のため処理中断');
      alert('入力必須項目が未入力です。');
      return;
    }
    if (!this.departmentName) {
      console.log('入力必須項目が未入力のため処理中断');
      alert('入力必須項目が未入力です。');
      return;
    }

    // サーバに渡すJSON
    this.departmentData = {
      department_no: this.departmentNo,
      department_name: this.departmentName,
      mail_address: this.mailAddress,
    };
    // 入力された情報を部署マスタに反映する
    if (this.data.departmentno) {
      this.depService.updateDepartment(this.departmentData);
    } else {
      // 番号チェック
      const rec: any = await this.depService.checkDepartmentNo(this.departmentNo);

      if (rec) {
        console.log('部署番号重複のため処理中断');
        alert('部署番号が重複しています、異なる番号に変更してください。');
        return;
      }

      this.depService.insertDepartment(this.departmentData);
    }
    // 部署マスタ画面に戻る
    this.dialogRef.close(false);
  }

}
