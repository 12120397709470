import { environment } from '../../../environments/environment';

/**
 * アプリケーション設定クラス
 */
export class AppConfig {
  // TODO:未使用項目を削除する

  /** システム名 */
  static readonly SYSTEM_NANE: string = '案件管理システム';

  /** BugsnagのAPIキー */
  static readonly BUGSNAG_API_KEY = 'aaeee16c7878626d88b566bb58711c1c';

  /** 通信リトライ回数 */
  static retryNum = 0;

  /** 自動ログオフ時間(分) */
  static AUTO_LOGOUT = 30;

  /** トークンの有効期限切れチェック間隔(分) */
  static CHECK_TOKEN_INTERVAL = 1;

  /** BaseUrl */
  static baseUrl = environment.apiUrl;
  // static baseUrl = "http://localhost:4200";

  /** ログオフ時の遷移先 */
  static loginUrl = '/';

  /** 自動アップロードのキー名の接頭語 */
  static UPLOAD_PREFIX = 'UPLOAD_';

  /** Cookieトークンの接頭語 */
  static JWT_HEADER = 'authorization';
  /** Cookieトークンの接頭語 */
  static TOKEN_PREFIX = 'Bearer ';

  /** エラーログ記録メッセージ */
  static ERROR_STORE_MESSAGE = 'エラーを記録しました';

  /** エラーログ名 */
  static ERROR_LOG_NAME = 'errorLog';

  /** 認証API接続先 */
  static authUrl = AppConfig.baseUrl + '/auth';

  /** パスワード変更API接続先 */
  static passUrl = AppConfig.baseUrl + '/api/pass';

  /** WebサービスAPI接続先 */
  static apiUrl = AppConfig.baseUrl + '/api/';
}
