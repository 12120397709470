import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

/**
 * ルーティングの親設定
 *
 * このファイルにはルーティングの設定を追加しない。<br>
 * パスを新たに追加する場合は、各機能(traffic/common等)毎にrouting-module.tsファイルにパス設定を追加する。<br>
 * 上記にある機能と分類が分かれる新規画面のパスを追加する場合はrouting-module.tsを新たに作成する。
 */
@NgModule({
  imports: [RouterModule.forRoot([], { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
