import { LocationStrategy } from '@angular/common';
import { Injectable } from '@angular/core';
import { AppConfig } from '../../shared/class/appconfig.class';
import { HttpClient } from '@angular/common/http';
import { HttpService } from '../../shared/service/http.service';
import { HttpReqOptions } from '../../shared/interface/httpOption.interface';
import { BehaviorSubject } from 'rxjs';

/**
 * 案件管理関連サービス
 */
@Injectable({
  providedIn: 'root'
})
export class OrderManagementService {

  // #region コンストラクター

  /**
   * 案件管理関連サービスのコンストラクター
   * @constructor
   * @param httpService Httpサービス
   * @param http Httpクライアント
   */
  constructor(private httpService: HttpService,
              private http: HttpClient,
              private locationStrate: LocationStrategy) {
    // 遷移先の画面から location.back() で画面遷移した場合
    this.locationStrate.onPopState(result => {
      this.isFromLocationBack = true;
    });
  }

  // #endregion

  // #region 取得処理

  /**
   * 案件管理一覧の取得
   *
   * 案件管理一覧を取得する。
   * HTTPリクエスト種類: GET
   * @param bodyData JSONデータ
   */
  async getOrderList(bodyData: any): Promise<any> {
    // HTTPリクエスト条件設定
    const config: HttpReqOptions = {
      url: AppConfig.apiUrl + 'order/orderList',
      body: bodyData
    };

    // HTTPリクエスト
    let res: any;
    try {
      res = await this.http.put(config.url, config.body).toPromise();
    } catch (err) {
      throw new Error('データ受信失敗');
    }
    // 受信データ処理
    const rec = res;
    if (rec.data && rec.data.length) {
      console.log('@@@@ データ受信成功');
    }
    return Promise.resolve(rec);
  }

  /**
   * 案件管理詳細の取得
   *
   * 案件番号から案件管理詳細を取得する。
   * HTTPリクエスト種類: GET
   * @param orderNo 案件番号
   */
  async getOrderDetail(orderNo: string): Promise<any> {
    // HTTPリクエスト条件設定
    const config: HttpReqOptions = {
      url: AppConfig.apiUrl + 'order/orderDetail/' + orderNo + '/'
    };

    // HTTPリクエスト
    let res: any;
    try {
      res = await this.httpService.send('get', config);
    } catch (err) {
      throw new Error('データ受信失敗');
    }
    // 受信データ処理
    const rec = res.data;
    if (rec && rec.length) {
      console.log('@@@@ データ受信成功');
    }
    return Promise.resolve(rec);
  }

  // #endregion

  // #region 登録・更新処理

  /**
   * 案件管理詳細の登録
   *
   * HTTPリクエスト種類: POST
   * @param bodyData JSONデータ
   */
  async insertOrderDetail(bodyData: any): Promise<any> {
    // HTTPリクエスト条件設定
    const config: HttpReqOptions = {
      url: AppConfig.apiUrl + 'order/orderDetail',
      body: bodyData
    };

    // HTTPリクエスト
    let res: any;
    try {
      res = await this.http.post(config.url, config.body).toPromise();
    } catch (err) {
      throw new Error('データ受信失敗');
    }
    // 受信データ処理
    const rec = res;
    if (rec.data && rec.data.length) {
      console.log('@@@@ データ受信成功');
    }
    return Promise.resolve(rec);
  }

  /**
   * 案件管理詳細の更新
   *
   * HTTPリクエスト種類: PUT
   * @param bodyData JSONデータ
   */
  async updateOrderDetail(bodyData: any): Promise<any> {
    // HTTPリクエスト条件設定
    const config: HttpReqOptions = {
      url: AppConfig.apiUrl + 'order/orderDetail',
      body: bodyData
    };

    // HTTPリクエスト
    let res: any;
    try {
      res = await this.http.put(config.url, config.body).toPromise();
    } catch (err) {
      throw new Error('データ受信失敗');
    }
    // 受信データ処理
    const rec = res;
    if (rec.data && rec.data.length) {
      console.log('@@@@ データ受信成功');
    }
    return Promise.resolve(rec);
  }

  // #endregion

  // #region メール送信

  /**
   * 案件採用の通知メールを送信
   *
   * HTTPリクエスト種類: PUT
   * @param bodyData JSONデータ
   */
  public async sendMailForDone(bodyData: any) {
    // HTTPリクエスト条件設定
    const config: HttpReqOptions = {
      url: AppConfig.apiUrl + 'order/orderDetail/sendMail/done',
      body: bodyData
    };

    // HTTPリクエスト
    let res: any;
    try {
      res = await this.http.put(config.url, config.body).toPromise();
    } catch (err) {
      throw new Error('データ受信失敗');
    }
    // 受信データ処理
    const rec = res;
    if (rec.data && rec.data.length) {
      console.log('@@@@ データ受信成功');
    }
    return Promise.resolve(rec);
  }

  /**
   * 試作依頼の通知メールを送信
   *
   * HTTPリクエスト種類: PUT
   * @param bodyData JSONデータ
   */
  public async sendMailForRequest(bodyData: any) {
    // HTTPリクエスト条件設定
    const config: HttpReqOptions = {
      url: AppConfig.apiUrl + 'order/orderDetail/sendMail/request',
      body: bodyData
    };

    // HTTPリクエスト
    let res: any;
    try {
      res = await this.http.put(config.url, config.body).toPromise();
    } catch (err) {
      throw new Error('データ受信失敗');
    }
    // 受信データ処理
    const rec = res;
    if (rec.data && rec.data.length) {
      console.log('@@@@ データ受信成功');
    }
    return Promise.resolve(rec);
  }

  /**
   * 試作依頼の完了メールを送信
   *
   * HTTPリクエスト種類: PUT
   * @param bodyData JSONデータ
   */
  public async sendCompletionMailForRequest(bodyData: any) {
    // HTTPリクエスト条件設定
    const config: HttpReqOptions = {
      url: AppConfig.apiUrl + 'order/orderDetail/sendMail/request/done',
      body: bodyData
    };

    // HTTPリクエスト
    let res: any;
    try {
      res = await this.http.put(config.url, config.body).toPromise();
    } catch (err) {
      throw new Error('データ受信失敗');
    }
    // 受信データ処理
    const rec = res;
    if (rec.data && rec.data.length) {
      console.log('@@@@ データ受信成功');
    }
    return Promise.resolve(rec);
  }

  // #endregion

  // #region PDF作成

  /**
   * 試作依頼書PDFの作成
   *
   * HTTPリクエスト種類: GET
   * @param prototypeNo 試作番号
   */
  async getPrototypeRequestPdf(prototypeNo: string): Promise<any> {
    // HTTPリクエスト条件設定
    const config: HttpReqOptions = {
      url: AppConfig.apiUrl + 'order/pdf/' + prototypeNo + '/'
    };

    // HTTPリクエスト
    let res: any;
    try {
      res = await this.httpService.requestPdfSend('get', config);
    } catch (err) {
      throw new Error('データ受信失敗');
    }
    // 受信データ処理
    const rec = res.data;
    if (rec && rec.length) {
      console.log('@@@@ データ受信成功');
    }
    return Promise.resolve(rec);
  }

  // #endregion

  // #region 名称マスタ

  /**
   * 名称マスタの一覧取得
   *
   * 指定された区分コードに該当する名称マスタの一覧を取得する。
   * HTTPリクエスト種類: GET
   * @param classCode 区分コード
   */
  async getCodeNameList(classCode: string): Promise<any> {
    // HTTPリクエスト条件設定
    const config: HttpReqOptions = {
      url: AppConfig.apiUrl + 'shared/codeNameList/' + classCode + '/'
    };

    // HTTPリクエスト
    let res: any;
    try {
      res = await this.httpService.requestPdfSend('get', config);
    } catch (err) {
      throw new Error('データ受信失敗');
    }
    // 受信データ処理
    const rec = res.data;
    if (rec && rec.length) {
      console.log('@@@@ データ受信成功');
    }
    return Promise.resolve(rec);
  }

  // #endregion

  // #region パラメータ保存

  /** フリーワード */
  freeWord = new BehaviorSubject('');
  /** 対象年 */
  targetYear = new BehaviorSubject('');
  /** 採用チェックボックス */
  isDone = new BehaviorSubject(false);
  /** 不採用チェックボックス */
  isCancel = new BehaviorSubject(false);
  /** 案件番号 */
  orderNo = new BehaviorSubject('');
  /** 案件番号(絞り込み条件) */
  searchOrderNo = new BehaviorSubject('');
  /** 試作番号 */
  prototypeNo = new BehaviorSubject('');
  /** 案件名 */
  orderName = new BehaviorSubject('');
  /** 試作区分 */
  prototypeKind = new BehaviorSubject('');
  /** 試作ステータス */
  prototypeStatus = new BehaviorSubject('');
  /** 費用 */
  cost = new BehaviorSubject('');
  /** サイズ */
  size = new BehaviorSubject('');
  /** 重量 */
  weight = new BehaviorSubject('');
  /** 依頼内容 */
  requestContent = new BehaviorSubject('');
  /** 備考 */
  notes = new BehaviorSubject('');
  /** 依頼元会社名 */
  requesterCompany = new BehaviorSubject('');
  /** 依頼元所属部課または工場 */
  requesterDepartment = new BehaviorSubject('');
  /** 依頼元担当者 */
  requesterStaff = new BehaviorSubject('');
  /** 担当営業 */
  salesStaff = new BehaviorSubject('');

  /**
   * パラメータ保存
   * 画面から渡されたパラメータを保存する
   * @param orderNo 案件番号
   * @param mode 画面モード
   */
  sendParameterSave(orderNo: string) {
    this.orderNo.next(orderNo);
  }

  /**
    * 絞り込み条件保存
    * 画面から渡された絞り込み検索条件を保存する
    * @param freeWord フリーワード
    * @param targetYear 対象年
    * @param isDone 採用チェックボックス
    * @param isCancel 不採用チェックボックス
    * @param orderNo 案件番号
    * @param prototypeNo 試作番号
    * @param orderName 案件名
    * @param prototypeKind 試作区分
    * @param prototypeStatus 試作ステータス
    * @param cost 費用
    * @param size サイズ
    * @param weight 重量
    * @param requestContent 依頼内容
    * @param notes 備考
    * @param requesterCompany 依頼元会社名
    * @param requesterDepartment 依頼元所属部課または工場
    * @param requesterStaff 依頼元担当者
    * @param salesStaff 担当営業
    */
  setSearchConditions(freeWord: string, targetYear: string, isDone: boolean, isCancel: boolean, orderNo: string, prototypeNo: string, orderName: string, prototypeKind: string, prototypeStatus: string,
    cost: string, size: string, weight: string, requestContent: string, notes: string, requesterCompany: string, requesterDepartment: string, requesterStaff: string, salesStaff
  ) {
    this.freeWord.next(freeWord);
    this.targetYear.next(targetYear);
    this.isDone.next(isDone);
    this.isCancel.next(isCancel);
    this.searchOrderNo.next(orderNo);
    this.prototypeNo.next(prototypeNo);
    this.orderName.next(orderName);
    this.prototypeKind.next(prototypeKind);
    this.prototypeStatus.next(prototypeStatus);
    this.cost.next(cost);
    this.size.next(size);
    this.weight.next(weight);
    this.requestContent.next(requestContent);
    this.notes.next(notes);
    this.requesterCompany.next(requesterCompany);
    this.requesterDepartment.next(requesterDepartment);
    this.requesterStaff.next(requesterStaff);
    this.salesStaff.next(salesStaff);
  }

  // #endregion

  // #region 遷移先からの画面遷移かどうか

  /** 遷移先からの画面遷移かどうか */
  private _isFromLocationBack = false;

  /** Getter : 遷移先からの画面遷移かどうか */
  get isFromLocationBack(): boolean {
    return this._isFromLocationBack;
  }

  /** Setter : 遷移先からの画面遷移かどうか */
  set isFromLocationBack(value: boolean) {
    this._isFromLocationBack = value;
  }

  // #endregion

  // #region 添付ファイルの関連処理

  /**
   * 添付ファイル一覧の取得
   *
   * 案件番号から添付ファイルの一覧を取得する。
   * HTTPリクエスト種類: GET
   * @param orderNo 案件番号
   */
  async getAttachmentFileList(orderNo: string): Promise<any> {
    // HTTPリクエスト条件設定
    const config: HttpReqOptions = {
      url: AppConfig.apiUrl + 'order/attachmentFile/' + orderNo
    };

    // HTTPリクエスト
    let res: any;
    try {
      res = await this.http.get(config.url, config.body).toPromise();
    } catch (err) {
      throw new Error('データ受信失敗');
    }
    // 受信データ処理
    const rec = res;
    if (rec.data && rec.data.length) {
      console.log('@@@@ データ受信成功');
    }
    return Promise.resolve(rec);
  }

  /**
   * 添付ファイルのデータ取得
   *
   * 添付ファイルのデータを取得する。
   * HTTPリクエスト種類: GET
   * @param orderNo 案件番号
   * @param seq 連番
   */
  async getAttachmentFile(orderNo: string, seq: string): Promise<any> {
    // HTTPリクエスト条件設定
    const config: HttpReqOptions = {
      url: AppConfig.apiUrl + 'order/attachmentFile/' + orderNo + '/' + seq
    };

    // HTTPリクエスト
    let res: any;
    try {
      res = await this.http.get(config.url, config.body).toPromise();
    } catch (err) {
      throw new Error('データ受信失敗');
    }
    // 受信データ処理
    const rec = res;
    if (rec.data && rec.data.length) {
      console.log('@@@@ データ受信成功');
    }
    return Promise.resolve(rec);
  }

  /**
   * 添付ファイルの登録
   *
   * 添付ファイルのデータを登録する。
   * HTTPリクエスト種類: POST
   * @param bodyData JSONデータ
   */
  async insertAttachmentFile(bodyData: any): Promise<any> {
    // HTTPリクエスト条件設定
    const config: HttpReqOptions = {
      url: AppConfig.apiUrl + 'order/attachmentFile',
      body: bodyData
    };

    // HTTPリクエスト
    let res: any;
    try {
      res = await this.http.post(config.url, config.body).toPromise();
    } catch (err) {
      throw new Error('データ受信失敗');
    }

    // 受信データ処理
    const rec = res;
    if (rec.data && rec.data.length) {
      console.log('@@@@ データ受信成功');
    }
    return Promise.resolve(rec);
  }

  /**
   * 添付ファイルの更新
   *
   * 指定した案件番号と連番に紐づく添付ファイルのデータを更新する。
   * HTTPリクエスト種類: PUT
   * @param bodyData JSONデータ
   */
  async updateAttachmentFile(bodyData: any): Promise<any> {
    // HTTPリクエスト条件設定
    const config: HttpReqOptions = {
      url: AppConfig.apiUrl + 'order/attachmentFile',
      body: bodyData
    };

    // HTTPリクエスト
    let res: any;
    try {
      res = await this.http.put(config.url, config.body).toPromise();
    } catch (err) {
      throw new Error('データ受信失敗');
    }
    // 受信データ処理
    const rec = res;
    if (rec.data && rec.data.length) {
      console.log('@@@@ データ受信成功');
    }
    return Promise.resolve(rec);
  }

  /**
   * 添付ファイルの削除
   *
   * 指定された案件番号・連番に紐づく添付ファイルを案件添付ファイルテーブルから物理削除する。
   * HTTPリクエスト種類: DELETE
   */
  async deleteAttachmentFile(orderNo: string, seq: string): Promise<any> {
    // HTTPリクエスト条件設定
    const config: HttpReqOptions = {
      url: AppConfig.apiUrl + 'order/attachmentFile/' + orderNo + '/' + seq
    };

    // HTTPリクエスト
    let res: any;
    try {
      res = await this.http.delete(config.url, config.body).toPromise();
    } catch (err) {
      throw new Error('データ受信失敗');
    }
    // 受信データ処理
    const rec = res;
    if (rec.data && rec.data.length) {
      console.log('@@@@ データ受信成功');
    }
    return Promise.resolve(rec);
  }

  /**
   * 添付ファイル付きのメール送信
   *
   * 指定した部署に対して添付ファイル付きのメールを送信する。
   * 案件添付ファイルテーブルのコメントを更新する。
   * HTTPリクエスト種類: PUT
   * @param mailData メール
   */
  public async sendMail(mailData: any) {
    // HTTPリクエスト条件設定
    const config: HttpReqOptions = {
      url: AppConfig.apiUrl + 'order/attachmentFile/sendMail',
      body: mailData
    };

    // HTTPリクエスト
    let res: any;
    try {
      res = await this.http.put(config.url, config.body).toPromise();
    } catch (err) {
      throw new Error('データ受信失敗');
    }
    // 受信データ処理
    const rec = res;
    if (rec.data && rec.data.length) {
      console.log('@@@@ データ受信成功');
    }
    return Promise.resolve(rec);
  }

  /**
   * ファイル出力処理
   * @param fileData ファイルデータ
   * @param mimeType MIMEコンテンツタイプ
   */
  public async makeFile(fileData: any, mimeType: any) {
    // Base64形式のファイルデータをBlobオブジェクトに変換する
    const blob = await this.toBlob(fileData, mimeType);

    // Blobオブジェクトのファイルをダウンロード
    const url = window.URL.createObjectURL(blob);

    return url;
  }

  /**
   * Base64とMIMEコンテンツタイプからBlobオブジェクトを作成する。
   * 日本語対応。
   *
   * @param base64 Base64データ
   * @param mime_ctype MIMEコンテンツタイプ
   */
  private async toBlob(base64, mime_ctype) {
    // 日本語の文字化けに対処するためBOMを作成する。
    // var bom = new Uint8Array([0xEF, 0xBB, 0xBF]);

    // Base64からバイナリへ変換
    var bin = atob(base64.replace(/^.*,/, ''));
    var buffer = new Uint8Array(bin.length);
    for (var i = 0; i < bin.length; i++) {
      buffer[i] = bin.charCodeAt(i);
    }

    // Blobを作成
    try {
      var blob = new Blob([buffer.buffer], {
        type: mime_ctype,
      });
      return blob;
    } catch (e) {
      return false;
    }
  }

  // #endregion
}
