/**
 * 以下の方法でログオフ
 * ログオフ時はデータ消去
 * １.logoffメソッド
 * ２.一定時間操作が無いとき
 * ３.JWTトークンの有効祈願が切れたとき
 * スリープ対応のため
 * ウェイクアップ時のチェック
 */
import { Injectable, OnDestroy } from '@angular/core';
import { AppConfig } from '../class/appconfig.class';
import { AppState } from '../interface/appstate';
import { Subscription } from 'rxjs/Subscription';
import { CryptoService } from './crypto.service';
import { CryptoStorageService } from './cryptoStorage.service';
import { State } from '@ngrx/store';

/**
 * ログアウトサービス
 */
@Injectable()
export class LogoutService implements OnDestroy {

  /** 利用時刻 */
  startTime = 0;
  /** タイマー */
  timer: any = null;
  /** グローバルイベント */
  globalEvent: Subscription[] = [];
  /** 状態オブジェクト保存済みフラグ */
  isSavedState = false;

  /**
   * ログアウトサービスのコンストラクター
   * @constructor
   */
  constructor(private cryptoService: CryptoService,
              private cryptoStorageService: CryptoStorageService,
              private ngState: State<any>) {
    console.log('@@@@ logoutService生成');

    // ブラウザまたはタブを閉じる時にイベントを登録
    window.addEventListener('beforeunload', () => this.saveSnapshot());

    // マウスクリック時にイベントを登録
    window.addEventListener('click', () => this.resetLogoutTimer());

    // キーボード操作時にイベントを登録
    window.addEventListener('keydown', () => this.resetLogoutTimer());
  }

  /**
   * サービス破棄時の処理
   *
   * グローバルイベントの受信登録を解除する
   */
  ngOnDestroy(): void {
    this.globalEvent.map(sub => sub.unsubscribe());
  }

  /**
   * 定期間隔間隔でタイムアウトを監視
   */
  startMonitorLogoutTime() {
    this.timer =
      setInterval(() => this.checkTimeout(),
        AppConfig.CHECK_TOKEN_INTERVAL * 60 * 1000);
    this.resetLogoutTimer();
  }

  /**
   * タイムアウト経過時間のリセット
   */
  private resetLogoutTimer() {
    this.startTime = Date.now(); // 利用時刻の更新
  }

  /**
   * タイムアウト確認
   * タイムアウト時はログアウト
   */
  private checkTimeout() {
    console.log('@@@ タイムアウトのチェック');

    // 無操作の経過時間
    const now = Date.now(); // 現在時刻
    const diff = now - this.startTime; // 経過時間
    if (diff > (AppConfig.AUTO_LOGOUT * 60 * 1000)) {
      console.log('@@@ 自動ログアウト時間経過');
      this.logout();
      return;
    }
  }

  /**
   * ログアウト時の処理
   *
   * ローカルデータ消去と状態オブジェクト保存後、ログイン画面へ遷移
   */
  logout() {
    console.log('@@@ Logout開始');

    // 自動ログアウト管理タイマー停止
    window.clearInterval(this.timer);

    // 状態オブジェクト保存
    this.saveSnapshot();

    // Cookieトークン消去
    document.cookie = AppConfig.JWT_HEADER + '=\'\';max-age=0';

    // 暗号キー消去
    this.cryptoService.setCryptpKey('');

    // ログイン画面へ遷移
    location.href = AppConfig.loginUrl;
  }

  /**
   * 状態オブジェクトの保存
   */
  saveSnapshot() {

    // 二重保存防止
    if (this.isSavedState) {
      return;
    }

    if (!this.cryptoService.isExistDbKey()) {
      console.log(
        '@@@ DB暗号キーが無いため状態オブジェクトを保存しません');
      return;
    }

    console.log('@@@@ 状態オブジェクトの保存開始');
    const state = this.ngState.getValue();
    const snapshot: AppState = {
      url: state.url as string,
      employeeNo: state.employeeNo,
      isOpenModal: state.isOpenModal,
      locale: state.locale
    };
    this.cryptoStorageService.setItem('snapshot', snapshot);
    console.log('@@@@ 状態オブジェクトの保存完了');

    // 状態オブジェクト保存済みフラグ
    this.isSavedState = true;
  }
}
