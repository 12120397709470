<div class="container" fxLayout="column">
  <div id="table-container" fxFlex="grow">
    <table mat-table [dataSource]="dataSource" class="customer-list">

      <!-- 得意先番号 -->
      <ng-container matColumnDef="customer_no">
        <th mat-header-cell *matHeaderCellDef class="column-width-2">得意先番号</th>
        <td mat-cell *matCellDef="let customer;" [attr.rowspan]="customer.count"
        [style.display]="customer.count != 0 ? '' : 'none'">{{customer.customer_no}}</td>
      </ng-container>

      <!-- 会社名 -->
      <ng-container matColumnDef="company_name">
        <th mat-header-cell *matHeaderCellDef class="column-width-5">会社名</th>
        <td mat-cell *matCellDef="let customer" [attr.rowspan]="customer.count"
        [style.display]="customer.count != 0 ? '' : 'none'"> {{customer.company_name}} </td>
      </ng-container>

      <!-- 得意先会社の郵便番号 -->
      <ng-container matColumnDef="postal_code">
        <th mat-header-cell *matHeaderCellDef class="column-width-3">郵便番号</th>
        <td mat-cell *matCellDef="let customer" [attr.rowspan]="customer.count"
        [style.display]="customer.count != 0 ? '' : 'none'" class="pre-line"> {{customer.postal_code}} </td>
      </ng-container>

      <!-- 得意先会社の住所 -->
      <ng-container matColumnDef="address">
        <th mat-header-cell *matHeaderCellDef class="column-width-5">住所</th>
        <td mat-cell *matCellDef="let customer" [attr.rowspan]="customer.count"
        [style.display]="customer.count != 0 ? '' : 'none'"> {{customer.address}} </td>
      </ng-container>

      <!-- 得意先会社に紐付く所属部課または工場 -->
      <ng-container matColumnDef="department_or_factory_name">
        <th mat-header-cell *matHeaderCellDef class="column-width-5">所属部課または工場</th>
        <td mat-cell *matCellDef="let customer">
          <div id="department-or-factory-name">{{customer.department_or_factory_name}}</div></td>
      </ng-container>

      <!-- 電話番号 -->
      <ng-container matColumnDef="tel_no">
        <th mat-header-cell *matHeaderCellDef class="column-width-4">電話番号</th>
        <td mat-cell *matCellDef="let customer"> {{customer.tel_no}} </td>
      </ng-container>

      <!-- 代表 -->
      <ng-container matColumnDef="representative">
        <th mat-header-cell *matHeaderCellDef class="column-width-1">代表</th>
        <td mat-cell *matCellDef="let customer" class="td-center"> {{customer.representative | i18nPlural: isRepresentative}} </td>
      </ng-container>

      <!-- 削除フラグ -->
      <ng-container matColumnDef="delete_flag">
        <th mat-header-cell *matHeaderCellDef class="column-width-1 last-column">削除済</th>
        <td mat-cell *matCellDef="let customer" [attr.rowspan]="customer.count"
        [style.display]="customer.count != 0 ? '' : 'none'" class="td-center last-column"> {{customer.delete_flag | i18nPlural: isDeleted}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let customer; columns: displayedColumns;"
        (click)="selectedRow(customer)"
        (mouseover)="hovered = customer"
        (mouseout)="hovered = null"
        [ngClass]="{
          'selected': (selected) ? selected.customer_no == customer.customer_no : false,
          'hovered': (hovered) ? hovered.customer_no == customer.customer_no : false
        }">
      </tr>
    </table>
  </div>

  <div id="footer-container">
    <span id="deletecheck-position">削除済も含む</span><mat-checkbox (change)="setDeleteCheck($event)" [checked]="deleteCheckStatus"></mat-checkbox>
    <br>
    <div fxLayout="row wrap" fxLayoutAlign="center" fxLayoutAlign.xs="space-between" class="button-container">
      <!-- 新規登録ボタン -->
      <button mat-raised-button color="primary" (click)="onEntry()">
        <mat-icon>add_box</mat-icon>
        <span class="btn-label">新規登録</span>
      </button>
      <!-- 修正ボタン -->
      <button mat-raised-button color="primary" (click)="onModify()" [disabled]="isDisabledModify">
        <mat-icon>edit</mat-icon>
        <span class="btn-label">修正</span>
      </button>
      <!-- 削除ボタン -->
      <button mat-raised-button color="primary" (click)="onDelete()" [disabled]="isDisabledDelete">
        <mat-icon>cancel</mat-icon>
        <span class="btn-label">削除</span>
      </button>
      <!-- 復元ボタン -->
      <button mat-raised-button color="primary" (click)="onRestore()" [disabled]="isDisabledRestore">
        <mat-icon>settings_backup_restore</mat-icon>
        <span class="btn-label">復元</span>
      </button>
    </div>
  </div>
</div>
