import { NgModule, ErrorHandler } from '@angular/core';

// コンポーネント
import { EmployeeListComponent } from './employee/list/employee-list.component';
import { EmployeeEntryModifyComponent } from './employee/entry-modify/employee-entry-modify.component';
import { DepartmentListComponent } from './department/list/department-list.component';
import { DepartmentEntryModifyDialogComponent } from './department/entry-modify-dialog/department-entry-modify-dialog.component';
import { CustomerListComponent } from './customer/list/customer-list.component';
import { CustomerEntryModifyComponent } from './customer/entry-modify/customer-entry-modify.component';
import { CustomerLineAddDialogComponent } from './customer/line-add-modify-dialog/customer-line-add-modify-dialog.component';

// オプションモジュール
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { GlobalErrorService } from './../shared/service/globalError.service';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MaterialModule } from '../shared/class/material.module';
import { MaintenanceRoutingModule } from './maintenance-routing.module';

@NgModule({
  declarations: [
    EmployeeListComponent,
    EmployeeEntryModifyComponent,
    DepartmentListComponent,
    DepartmentEntryModifyDialogComponent,
    CustomerListComponent,
    CustomerEntryModifyComponent,
    CustomerLineAddDialogComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    DragDropModule,
    MaterialModule,
    MaintenanceRoutingModule
  ],
  exports: [
    EmployeeListComponent,
    EmployeeEntryModifyComponent,
    CustomerListComponent,
    CustomerLineAddDialogComponent
  ],
  providers: [
    { provide: ErrorHandler, useClass: GlobalErrorService }
  ]
})
export class MaintenanceModule { }
