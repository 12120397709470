import { Injectable } from '@angular/core';
import { CryptoService } from './crypto.service';

/**
 * ローカルストレージ暗号化サービス
 *
 * LocalStorageで保存するデータを暗号化する
 */
@Injectable()
export class CryptoStorageService {

  /** キー名の接頭語 */
  PREFIX = 'encrypto_';

  /**
   * ローカルストレージ暗号化サービスのコンストラクター
   * @constructor
   * @param cryptoService 暗号化サービス
   */
  constructor(private cryptoService: CryptoService) {
    console.log('@@@@ cryptoStorageService生成');
  }

  /**
   * LocalStorageにデータを保存する
   * @param key キー
   * @param data データ(オブジェクト可)
   */
  setItem(key: string, data: any) {
    const keyStr = this.PREFIX + key;
    let str = JSON.stringify(data);
    try {
      str = this.cryptoService.enCrypt(str);
      localStorage.setItem(keyStr, str);
    } catch (e) {
      throw new Error('setItem Error:'
        + e.message);
    }
  }

  /**
   * LocalStorageからデータを取得する
   * @param key キー
   */
  getItem(key: string) {
    const keyStr = this.PREFIX + key;
    let data: any;
    let str = localStorage.getItem(keyStr);
    try {
      str = this.cryptoService.deCrypt(str);
      data = JSON.parse(str);
    } catch (e) {
      console.log('@@@getItem Error:'
        + e.message);
    }
    return data;
  }

  /**
   * LocalStorageからデータを1件削除する
   * @param key キー
   */
  removeItem(key: string) {
    const keyStr = this.PREFIX + key;
    localStorage.removeItem(keyStr);
  }

  /**
   * LocalStorageからデータを全件削除する
   */
  clearItem() {
    const keys = this.getKeys();
    keys.forEach((v, i) => localStorage.remove(this.PREFIX + keys[i]));
  }

  /**
   * LocalStorageに保存されているデータのキー配列を取得する
   * @returns Array キーの文字配列
   */
  getKeys(): any {
    const arrKey = [];
    Object.keys(localStorage).map((key) => {
      if (key.indexOf(this.PREFIX) !== -1) {
        arrKey.push(key.replace(this.PREFIX, ''));
      }
    });
    return arrKey;
  }
}
