<form #formCheck="ngForm">
  <div class="entry-container">
    <div fxLayout="row" fxLayout.xs="column">
      <div fxFlex="10"></div>
      <div fxFlex="80">
        <div fxLayout="row" fxLayout.xs="column">
          <div fxFlex="50">
            <div fxLayout="row" fxLayout.xs="column">
              <div fxFlex class="input-area">
                <!-- 案件番号 -->
                <mat-form-field appearance="standard">
                  <mat-label>案件番号</mat-label>
                  <input matInput type="tel" maxlength="9"
                    name="orderNo"
                    [(ngModel)]="orderNo"
                    [formControl]="orderNoCheck"
                    oninput="value = value.replace(/[^-0-9]+/, '')"
                    [matAutocomplete]="autoOrderNo">
                    <mat-autocomplete autoActiveFirstOption #autoOrderNo="matAutocomplete">
                      <mat-option *ngFor="let order of filteredOrderNo | async" [value]="order.order_no">
                        {{order.order_no}}
                      </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
              </div>
            </div>
            <div fxLayout="row" fxLayout.xs="column">
              <div fxFlex="50" class="input-area">
                <!-- 最終商品名 -->
                <mat-form-field appearance="standard" class="form-long">
                  <mat-label>最終商品名</mat-label>
                  <input matInput name="finalItemName" [(ngModel)]="finalItemName" [disabled]="isReference">
                </mat-form-field>
              </div>
              <div fxFlex="50"></div>
            </div>
            <div fxLayout="row" fxLayout.xs="column">
              <div fxFlex="50" class="input-area">
                <!-- サイズ -->
                <mat-form-field appearance="standard">
                  <mat-label>サイズ</mat-label>
                  <input matInput name="size" [(ngModel)]="size" [disabled]="isReference">
                </mat-form-field>
              </div>
              <div fxFlex="50" class="input-area">
                <!-- 重量 -->
                <mat-form-field appearance="standard">
                  <mat-label>重量</mat-label>
                  <input matInput name="weight" [(ngModel)]="weight" [disabled]="isReference">
                </mat-form-field>
              </div>
              <div fxFlex="50" class="input-area">
                <!-- 　賞味期限 -->
                <mat-form-field appearance="standard">
                  <mat-label>賞味期限</mat-label>
                  <input matInput name="bestBefore" [(ngModel)]="bestBefore" [disabled]="isReference">
                </mat-form-field>
              </div>
            </div>
            <div fxLayout="row" fxLayout.xs="column">
              <div fxFlex="50" class="input-area">
                <!-- 納品先会社 -->
                <mat-form-field appearance="standard" class="form-long">
                  <mat-label>納品先会社</mat-label>
                  <input matInput
                    [(ngModel)]="destinationCompany"
                    [formControl]="destinationCompanyCheck"
                    [matAutocomplete]="autoCompany">
                  <mat-autocomplete autoActiveFirstOption #autoCompany="matAutocomplete">
                    <mat-option *ngFor="let customer of filteredCustomerList | async" [value]="customer.company_name">
                      {{customer.company_name}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>
              <div fxFlex="50" class="input-area">
                <!-- 納品先所属部課または工場 -->
                <mat-form-field appearance="standard" class="form-long">
                  <mat-label>納品先所属部課または工場</mat-label>
                  <input matInput
                    [(ngModel)]="destinationDepartment"
                    [formControl]="destinationDepartmentCheck"
                    [matAutocomplete]="autoDepartment">
                  <mat-autocomplete autoActiveFirstOption #autoDepartment="matAutocomplete">
                    <mat-option *ngFor="let customer of filteredCustomerChildList | async" [value]="customer.department_or_factory_name">
                      {{customer.department_or_factory_name}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>
            </div>
            <div fxLayout="row" fxLayout.xs="column">
              <div fxFlex="50" class="input-area">
                <!-- 販売元 -->
                <mat-form-field appearance="standard" class="form-long">
                  <mat-label>販売元</mat-label>
                  <input matInput name="distributor" [(ngModel)]="distributor" [disabled]="isReference">
                </mat-form-field>
              </div>
              <div fxFlex="50" class="input-area">
                <!-- 発売開始 -->
                <mat-form-field appearance="standard">
                  <mat-label>発売開始</mat-label>
                  <input matInput name="salesStart" [(ngModel)]="salesStart" [disabled]="isReference">
                </mat-form-field>
              </div>
              <div fxFlex="50" class="input-area">
                <!-- 期間 -->
                <mat-form-field appearance="standard">
                  <mat-label>期間</mat-label>
                  <input matInput name="period" [(ngModel)]="period" [disabled]="isReference">
                </mat-form-field>
              </div>
            </div>
            <div fxLayout="row" fxLayout.xs="column">
              <div fxFlex="50" class="input-area">
                <!-- 生産予想 -->
                <mat-form-field appearance="standard" class="form-long">
                  <mat-label>生産予想</mat-label>
                  <input matInput name="productionForecast" [(ngModel)]="productionForecast" [disabled]="isReference">
                </mat-form-field>
              </div>
              <div fxFlex="50" class="input-area">
                <!-- 備考 -->
                <mat-form-field appearance="standard" class="form-long">
                  <mat-label>備考</mat-label>
                  <input matInput name="notes" [(ngModel)]="notes" [disabled]="isReference">
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="center" class="button-container">
          <!-- 戻る -->
          <button mat-raised-button color="primary" (click)="onCancel()">
            <mat-icon>keyboard_backspace</mat-icon>
            <span class="btn-label">戻る</span>
          </button>
          <!-- 登録 -->
          <button mat-raised-button color="primary" *ngIf="!isReference" (click)="onRegist()">
            <mat-icon>check</mat-icon>
            <span class="btn-label">{{registButtonName}}</span>
          </button>
        </div>
      </div>
      <div fxFlex="10"></div>
    </div>
  </div>
</form>
