import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OrderManagementService } from './../order-management.service';
import { MaintenanceDepartmentService } from 'src/app/maintenance/department/maintenance-department.service';

/**
 * 添付送信ダイアログコンポーネント
 */
@Component({
  selector: 'app-attachment-send-dialog-dialog',
  templateUrl: './attachment-send-dialog.component.html',
  styleUrls: ['./attachment-send-dialog.component.css']
})
export class AttachmentSendDialogComponent implements OnInit {

  // #region フィールド

  /** コメント */
  public comment = '';
  /** チェックボックス位置(後ろ) */
  public readonly after = 'after';
  /** 取得した部署一覧 */
  public departmentList = [];

  // #endregion

  // #region コンストラクター

  /**
   * 添付送信ダイアログコンポーネントのコンストラクター
   * @constructor
   * @param data 画面から渡されたデータ
   * @param dialogRef MatDialogRef
   * @param service 案件管理関連サービス
   * @param depService 部署メンテナンス関連サービス
   */
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private dialogRef: MatDialogRef<AttachmentSendDialogComponent>,
              private service: OrderManagementService,
              private depService: MaintenanceDepartmentService) {
  }

  // #endregion

  // #region ngOnInit

  /**
   * 初期処理
   * ボタン名を設定、一覧画面から受け取った値をセットする。
   */
  async ngOnInit(): Promise<void> {
    // 部署一覧の取得
    await this.getDepartmentList();
  }

  // #endregion

  // #region データ取得時の処理

  /**
   * 部署一覧の取得
   *
   * 部署一覧を取得し、チェックボックスを作成する。
   */
  private async getDepartmentList() {
    // 削除フラグが false の部署一覧を取得する
    const rec: any = await this.depService.getDepartmentList(0);
    // 取得した結果をセットする
    this.departmentList = rec;
  }

  // #endregion

  // #region ボタン押下時の処理

  /**
   * 送信ボタン押下時の処理
   */
  public async onSend(params: any) {
    console.log('@@@@ 添付送信ボタン押下');

    // 添付ファイルが未選択の場合、確認ダイアログを表示
    if (!this.data.seq.length) {
      console.log('添付ファイルが未選択のため、処理中断');
      alert('添付ファイルを選択してください。');
      return;
    }

    // 送信先が未選択の場合、確認ダイアログを表示
    if (!params.length) {
      console.log('送信先が未選択のため、処理中断');
      alert('送信先を指定してください。');
      return;
    }

    // 他画面から渡された連番をカンマ区切りで結合
    const seq = this.data.seq.join(',');
    // 送信先のメールアドレスを配列に変換
    const sendMailAddress = params.map(x => x.value);

    // サーバに渡すJSON
    const bodyData = {
      send_mail_address: sendMailAddress,
      comment: this.comment,
      order_no: this.data.order_no,
      order_name: this.data.order_name,
      seq: seq
    };
    // 選択した部署にメール送信、コメントの登録
    const mail = await this.service.sendMail(bodyData);
    // メール送信に失敗した場合、サーバから返ってきたメッセージを表示する
    if (!mail.success) {
      alert(mail.message);
      return;
    }

    // ダイアログを閉じる
    this.dialogRef.close(mail.success);
  }

  // #endregion
}
