import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Moment } from 'moment';
import { MaintenanceEmployeeService } from '../../../maintenance/employee/maintenance-employee.service';
import { AppConfig } from '../../../shared/class/appconfig.class';
import { OrderStatus } from '../../../shared/namespace/order-status';
import { HeaderTitleService } from '../../../shared/service/headerTitle.service';
import { NavigationService } from '../../../shared/service/navigation.service';
import { AttachmentManagementDialogComponent } from '../attachment-management-dialog/attachment-management-dialog.component';
import { OrderManagementService } from '../order-management.service';

/**
 * Datepickerのカスタムフォーマット
 */
export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY',
  },
  display: {
    dateInput: 'YYYY年',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

/**
 * 案件リストコンポーネント
 */
@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class OrderListComponent implements OnInit {

  // #region フィールド

  // 絞り込み条件
  /** フリーワード */
  public freeWord ='';
  /** 対象年 */
  public targetYear = '';
  /** 採用チェックボックス */
  public isDone = false;
  /** 不採用チェックボックス */
  public isCancel = false;
  /** 案件番号 */
  public orderNo = '';
  /** 試作番号 */
  public prototypeNo = '';
  /** 案件名 */
  public orderName = '';
  /** 試作区分 */
  public prototypeKind = '';
  /** 試作ステータス */
  public prototypeStatus = '';
  /** 費用 */
  public cost = '';
  /** サイズ */
  public size = '';
  /** 重量 */
  public weight = '';
  /** 依頼内容 */
  public requestContent = '';
  /** 備考 */
  public notes = '';
  /** 依頼元会社名 */
  public requesterCompany = '';
  /** 依頼元所属部課または工場 */
  public requesterDepartment = '';
  /** 依頼元担当者 */
  public requesterStaff = '';
  /** 担当営業 */
  public salesStaff = '';

  /** 取得した案件一覧 */
  public dataSource = new MatTableDataSource();
  /** 選択した案件情報 */
  public selected: any;
  /** マウスオーバーした案件情報 */
  public hovered: any;
  /** 取得した試作区分一覧 */
  public prototypeKindList: any;
  /** 取得した試作ステータス一覧 */
  public prototypeStatusList = [];
  /** 取得した費用一覧 */
  public costList: any;
  /** 取得した営業担当者一覧 */
  public employeeList: any = [];

  /** 修正・追加ボタンの状態 */
  public isDisabledModify = true;
  /** コピーボタンの状態 */
  public isDisabledCopy = true;
  /** 添付管理ボタンの状態 */
  public isDisabledAttachment = true;
  /** 修正・追加ボタンのボタン名称 */
  public modifyButtonName = '修正・追加';

  /** 画面表示項目 */
  public displayedColumns: string[] = [
    'order_no',               // 案件番号
    'order_name',             // 案件名
    'order_status',           // 案件ステータス
    'prototype_status_names', // 試作ステータス
    'request_dates',          // 依頼日
    'deadlines',              // 納期
    'requester_company',      // 依頼元
    'sales_staff_name',       // 担当営業
    'updated_employee_name',  // 更新者
    'update_datetime',        // 更新日時
    'attachment_file_names'   // 添付ファイル名
  ];
  /** アコーディオン */
  @ViewChild('accordion') accordion: MatExpansionPanel;
  /** ソート */
  @ViewChild(MatSort) sort: MatSort;
  /** ページネーター */
  @ViewChild(MatPaginator) paginator: MatPaginator;
  /** 添付ファイルダウンロード用のデコレーター */
  @ViewChild('downloadLink') downloadLink: ElementRef;

  // #endregion

  // #region コンストラクター

  /**
   * 案件リストコンポーネントのコンストラクター
   * @constructor
   */
  constructor(private title: Title,
              private headerTitle: HeaderTitleService,
              private transitionService: NavigationService,
              private router: Router,
              private service: OrderManagementService,
              private empService: MaintenanceEmployeeService,
              private matDialog: MatDialog,
              private matSnackBar: MatSnackBar) {
    // ページタイトルの設定
    this.title.setTitle('案件リスト | ' + AppConfig.SYSTEM_NANE);
    // ヘッダータイトルの設定
    this.headerTitle.setTitle('案件リスト');
  }

  // #endregion

  // #region ngOnInit, ngOnDestroy

  /**
   * 初期表示時の処理
   */
  async ngOnInit(): Promise<void> {
    // 試作区分の一覧取得
    await this.getPrototypeKindList();
    // 試作ステータスの一覧取得
    await this.getPrototypeStatusList();
    // 費用の一覧取得
    await this.getCostList();
    // 営業担当者の一覧取得
    await this.getSalesEmployeeList();

    // 遷移先の画面から location.back() で画面遷移した場合
    if (this.service.isFromLocationBack) {
      // サービスに保存した絞り込み条件を取得する
      await this.getSearchConditions();
    }

    // 案件管理の一覧取得
    await this.getOrderList();
  }

  /**
   * 終了時の処理
   */
  private async ngOnDestroy() {
    // サービスのプロパティを初期化する
    this.service.isFromLocationBack = false;
  }

  // #endregion

  // #region データ取得時の処理

  /**
   * 試作区分の一覧を取得する。
   */
  private async getPrototypeKindList() {
    // 試作区分の一覧取得
    const rec: any = await this.service.getCodeNameList('prototype_kind');
    // 先頭に空行を追加する
    rec.unshift({ code: '', name: '' });
    // 取得した結果をセットする
    this.prototypeKindList = rec;
  }

  /**
   * 試作ステータスの一覧を取得する。
   */
  private async getPrototypeStatusList() {
    // 試作ステータスの一覧取得
    const rec: any = await this.service.getCodeNameList('prototype_status');
    // 先頭に空行を追加する
    rec.unshift({ code: '', name: '' });
    // 取得した結果をセットする
    this.prototypeStatusList = rec;
  }

  /**
   * 費用の一覧を取得する。
   */
  private async getCostList() {
    // 費用の一覧取得
    const rec: any = await this.service.getCodeNameList('cost');
    // 先頭に空行を追加する
    rec.unshift({ code: '', name: '' });
    // 取得した結果をセットする
    this.costList = rec;
  }

  /**
   * 営業担当者の一覧を取得する。
   */
  private async getSalesEmployeeList() {
    // 営業担当者の一覧取得
    const rec: any = await this.empService.getSalesEmployeeList();
    // 先頭に空行を追加する
    rec.unshift({ employee_no: '', employee_name: '' });
    // 取得した結果をセットする
    this.employeeList = rec;
  }

  /**
   * 案件管理の一覧を取得する。
   */
  private async getOrderList() {
    const bodyData = {
      free_word: this.freeWord,
      target_year: this.targetYear,
      is_done: this.isDone,
      is_cancel: this.isCancel,
      order_no: this.orderNo,
      order_name: this.orderName,
      prototype_no: this.prototypeNo,
      prototype_kind: this.prototypeKind,
      prototype_status: this.prototypeStatus,
      cost: this.cost,
      size: this.size,
      weight: this.weight,
      request_content: this.requestContent,
      notes: this.notes,
      requester_company_name: this.requesterCompany,
      requester_department_or_factory_name: this.requesterDepartment,
      requester_staff: this.requesterStaff,
      sales_staff: this.salesStaff
    };

    // 案件一覧を取得する
    const select = await this.service.getOrderList(bodyData);
    // 取得に失敗した場合、サーバから返ってきたメッセージを表示する
    if (!select.success) {
      alert(select.message);
      return;
    }
    // 取得した結果をテーブルにセットする
    setTimeout(() => {
      this.dataSource.data = select.data;
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    });
  }

  // #endregion

  // #region 項目選択時の処理

  /**
   * 対象年選択時の処理
   * @param event 選択時のイベント
   * @param datepicker Datepicker
   */
  async selectedYear(event, datepicker: MatDatepicker<Moment>): Promise<void> {
    // 対象年を取得
    const datetime = event._d;
    this.targetYear = String(datetime.getFullYear());

    datepicker.close();
  }

  /**
   * 行選択時の処理
   * @param order 行選択した案件情報
   */
  public selectedRow(order) {
    // 選択した案件情報をセットする
    this.selected = order;

    // 案件ステータス毎に設定
    if (order.order_status == OrderStatus.Done || order.order_status == OrderStatus.Cancel) {
      // 案件ステータスが「採用」「不採用」の場合
      this.modifyButtonName = '参照';
    } else {
      this.modifyButtonName = '修正・追加';
    }
    // 修正・追加ボタン、コピーボタンを活性
    this.isDisabledModify = false;
    this.isDisabledCopy = false;
    // 添付管理ボタンを活性
    this.isDisabledAttachment = false;
  }

  // #endregion

  // #region ボタン押下時の処理

  /**
   * 検索ボタン押下時の処理
   */
  async onSearch(): Promise<void> {
    // 修正・修正ボタン、コピーボタンを非活性
    this.isDisabledModify = true;
    this.isDisabledCopy = true;
    // 添付管理ボタンを非活性
    this.isDisabledAttachment = true;

    // 選択した案件情報をクリア
    this.selected = null;

    // アコーディオンを閉じる
    this.accordion.close();

    // 案件管理の一覧取得
    await this.getOrderList();
  }

  /**
   * クリアボタン押下時の処理
   */
  public onClear() {
    // 絞り込み条件を初期値にする。
    this.freeWord = '';
    this.targetYear = '';
    this.isCancel = false;
    this.isDone = false;
    this.orderNo = '';
    this.prototypeNo = '';
    this.orderName = '';
    this.prototypeKind = '';
    this.prototypeStatus = '';
    this.cost = '';
    this.size = '';
    this.weight = '';
    this.requestContent = '';
    this.notes = '';
    this.requesterCompany = '';
    this.requesterDepartment = '';
    this.requesterStaff = '';
    this.salesStaff = '';
  }

  /**
   * 新規ボタン押下時の処理
   */
  public onEntry() {
    // サービスに絞り込み条件を保存する
    this.setSearchConditions();
    // 画面遷移の許可
    this.transitionService.canTransition = true;
    // 案件登録画面に遷移
    this.router.navigate(['/order/management/entry']);
  }

  /**
   * 修正・追加ボタン押下時の処理
   */
  public onModify() {
    // サービスに案件番号、画面モードを保存する
    this.service.sendParameterSave(this.selected.order_no);
    // サービスに絞り込み条件を保存する
    this.setSearchConditions();
    // 画面遷移の許可
    this.transitionService.canTransition = true;

    if (this.selected.order_status == OrderStatus.Done || this.selected.order_status == OrderStatus.Cancel) {
      // 案件参照画面に遷移
      this.router.navigate(['/order/management/reference']);
    } else {
      // 案件修正画面に遷移
      this.router.navigate(['/order/management/modify']);
    }
  }

  /**
   * コピーボタン押下時の処理
   */
  public onCopy() {
    // サービスに案件番号、画面モードを保存する
    this.service.sendParameterSave(this.selected.order_no);
    // サービスに絞り込み条件を保存する
    this.setSearchConditions();
    // 画面遷移の許可
    this.transitionService.canTransition = true;
    // 案件登録画面に遷移
    this.router.navigate(['/order/management/copy']);
  }

  /**
   * 添付管理ボタン押下時の処理
   *
   * 添付管理ダイアログを表示する。
   */
  public onAttached() {
    console.log('@@@@ 添付管理ボタン押下');

    const dialogRef =  this.matDialog.open(AttachmentManagementDialogComponent, {
      width: '80%',
      data: {
        title: '添付管理',
        order_no: this.selected.order_no,
        order_name: this.selected.order_name
      },
      // 画面外のクリックを禁止する設定を追加
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(async result => {
      // 案件管理の一覧取得
      await this.onSearch();
    });
  }

  // #endregion

  // #region 添付ファイルのダウンロード

  /**
   * 添付ファイル名のリンク押下時の処理
   *
   * 選択した添付ファイルをダウンロードします。
   * @param order 選択行のデータ
   * @param attachment_file_name 添付ファイル名
   */
  public async attachmentFileDownload(order, attachment_file_name) {
    const index = order.attachment_file_names.indexOf(attachment_file_name);
    const order_no_seq = order.order_no_seqs[index];

    // サーバから添付ファイルの情報を取得
    const file = await this.service.getAttachmentFile(order.order_no, order_no_seq);
    // 取得に失敗した場合、サーバから返ってきたメッセージを表示する
    if (!file.success) {
      alert(file.message);
      return;
    }
    // 取得したbase64データをblobに変換
    const link = await this.service.makeFile(file.data.attachment_file_data, file.data.mime_type);
    // 変換したデータからファイルをダウンロード
    this.downloadLink.nativeElement.href = link;
    this.downloadLink.nativeElement.download = attachment_file_name;
    this.downloadLink.nativeElement.click();
    this.downloadLink.nativeElement.removeAttribute('href');
  }

  // #endregion

  // #region 共通処理

  /**
   * 画面遷移時の処理
   * サービスに絞り込み検索の検索条件を保存する。
   */
  private setSearchConditions() {
    // 絞り込み検索の条件をサービスに保存する
    this.service.setSearchConditions(
      this.freeWord,
      this.targetYear,
      this.isDone,
      this.isCancel,
      this.orderNo,
      this.prototypeNo,
      this.orderName,
      this.prototypeKind,
      this.prototypeStatus,
      this.cost,
      this.size,
      this.weight,
      this.requestContent,
      this.notes,
      this.requesterCompany,
      this.requesterDepartment,
      this.requesterStaff,
      this.salesStaff
    );
  }

  /**
   * サービスに保存した絞り込み条件を取得する
   */
  private async getSearchConditions() {
    // フリーワード
    this.service.freeWord.subscribe(async value => this.freeWord = value);
    // 対象年
    this.service.targetYear.subscribe(async value => this.targetYear = value);
    // 採用チェックボックス
    this.service.isDone.subscribe(async value => this.isDone = value);
    // 不採用チェックボックス
    this.service.isCancel.subscribe(async value => this.isCancel = value);
    // 案件番号
    this.service.searchOrderNo.subscribe(async value => this.orderNo = value);
    // 試作番号
    this.service.prototypeNo.subscribe(async value => this.prototypeNo = value);
    // 案件名
    this.service.orderName.subscribe(async value => this.orderName = value);
    // 試作区分
    this.service.prototypeKind.subscribe(async value => this.prototypeKind = value);
    // 試作ステータス
    this.service.prototypeStatus.subscribe(async value => this.prototypeStatus = value);
    // 費用
    this.service.cost.subscribe(async value => this.cost = value);
    // サイズ
    this.service.size.subscribe(async value => this.size = value);
    // 重量
    this.service.weight.subscribe(async value => this.weight = value);
    // 依頼内容
    this.service.requestContent.subscribe(async value => this.requestContent = value);
    // 備考
    this.service.notes.subscribe(async value => this.notes = value);
    // 依頼元会社名
    this.service.requesterCompany.subscribe(async value => this.requesterCompany = value);
    // 依頼元所属部課または工場
    this.service.requesterDepartment.subscribe(async value => this.requesterDepartment = value);
    // 依頼元担当者
    this.service.requesterStaff.subscribe(async value => this.requesterStaff = value);
    // 担当営業
    this.service.salesStaff.subscribe(async value => this.salesStaff = value);
  }

  // #endregion
}
