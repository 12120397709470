<!-- エラーメッセージ -->
<div class="err-message">{{errMessage}}</div>

<form class="login-container">
  <!-- 社員番号 -->
  <div>
    <mat-form-field class="login-employeeno" appearance="fill">
      <mat-label>社員番号</mat-label>
      <input
        matInput
        type="text"
        name="employeeNo"
        [(ngModel)]="employeeNo"
        [formControl]="employeeNoCheck"
        required
        autofocus
        (input)="messageClear()"
      >
        <div class="check" *ngIf="employeeNoCheck.invalid && (employeeNoCheck.dirty || employeeNoCheck.touched)">
          <span *ngIf="employeeNoCheck.hasError('required')">社員番号は必須です。</span>
        </div>
    </mat-form-field>
  </div>
  <!-- パスワード -->
  <div>
    <mat-form-field class="login-password" appearance="fill">
      <mat-label>パスワード</mat-label>
      <input
        matInput
        type="password"
        name="password"
        [(ngModel)]="password"
        [formControl]="passwordCheck" required
        (input)="messageClear()"
      >
      <div class="check" *ngIf="passwordCheck.invalid && (passwordCheck.dirty || passwordCheck.touched)">
        <span *ngIf="passwordCheck.hasError('required')">パスワードは必須です。</span>
      </div>
    </mat-form-field>
  </div>
  <!-- ログイン -->
  <div>
    <button
      mat-raised-button
      id="loginButton"
      class="btn-login"
      color="primary"
      value="ログイン"
      (click)="submit()">
      ログイン
    </button>
  </div>
</form>
